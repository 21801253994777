<template>
  <div class="routineOverlayAdmin" :id="'routineOverlayAdmin'">

    <div class="wrapper">
      <div class="titleHolder">
        <!--
        <div class="titleIcon"><i class="fas fa-stream"></i></div>
        -->
              
        <div class="titleHolder2">
          <div v-if="sequenceType == 'cooking'">
            Create cooking sequence
          </div>
          <div v-else-if="sequenceType == 'workout'">
            Create workout sequence
          </div>
          <div v-else-if="sequenceType == 'instructions'">
            Create instruction sequence
          </div>
          
        </div>
        <div>
          <div class="dotsHolder">
            <div v-for="(dot, index) in FLOWS[currentFlowManifest]" v-bind:key="'stepDots'+index" class="dots">
              <i
              :id="'stepDot_'+index"
              @click="onDotClick(index)" 
              class="fas fa-arrow-right greyArrow"
              v-if="(currentStep > (index+1)) && dot == 'INTRO'"
              ></i>
              <i
              :id="'stepDot_'+index" 
              class="fas fa-arrow-right currentDot"
              v-else-if="dot == 'INTRO'"
              ></i>
              <i
              :id="'stepDot_'+index" 
              @click="onDotClick(index)"
              class="fas fa-circle"
              v-else-if="currentStep > (index+1)"
              ></i>
              <i
              :id="'stepDot_'+index" 
              class="fas fa-circle currentDot"
              v-else-if="(currentStep == (index+1)) && (dot !='OUTRO') && (dot !='OUTRO_CESSATION') && (dot !='OUTRO_EVENT_BASED')"
              ></i>
              <i
              :id="'stepDot_'+index" 
              class="fas fa-check greenOutroDot"
              v-else-if="(FLOWS[currentFlowManifest].length === (currentStep)) && ((dot =='OUTRO') ||(dot =='OUTRO_CESSATION') ||(dot =='OUTRO_EVENT_BASED'))"
              ></i>
              <i
              :id="'stepDot_'+index" 
              class="fas fa-check greyOutroDot"
              v-else-if="(dot =='OUTRO') ||(dot =='OUTRO_CESSATION') ||(dot =='OUTRO_EVENT_BASED')"
              ></i>
              <i
              :id="'stepDot_'+index" 
              class="far fa-circle"
              v-else
              ></i>

            </div>
          </div>
        </div>
        
      </div>
      
      

      <!-- FLOW STEP TEMPLATE BLOCKS -->
      <!-- FLOW STEP TEMPLATE BLOCKS -->

      <!-- ADD_STEPS_TO_SEQUENCE -->
      <div class="stepHolder" v-if="ADD_STEPS_TO_SEQUENCE_STEP_ACTIVE">
        <div class="stepCard">
          <div class="stimulusQuestion lessMinHeight" :key="'ADD_STEPS_TO_SEQUENCE_stimulusQuestion'">
            <h1>{{ this.ADD_STEPS_TO_SEQUENCE_STEP_CONTENT.thoughtStimulusQuestion }}</h1>
          </div>
          <div class="stepTextField lessMarginTop">

            <div class="planSequence_stepHolder" v-for="(step, index) in this.ANSWERS[this.currentFlowAnswers].sequence.sequenceSteps" v-bind:key="`sequenceStep`+index">

              <div v-if="step.stepActionAttributes.agendaItemTitle">
                <img :src="step.stepActionAttributes.actionIconBlack" width="80px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {{ step.stepActionAttributes.agendaItemTitle }}
                 <br>
                <div>
                  <table width="100%">
                    <tr>
                      <td width="100%" class="planSequence_tableData" colspan="2">
                        <v-text-field
                          v-bind:key="'STEP_TITLE'"
                          ref="STEP_TITLE_input"
                          id="STEP_TITLE"
                          autofocus
                          label="Title for your step"
                          :rules="textRules"
                          counter="45"
                          clearable
                          type="text"
                          v-model="ANSWERS[currentFlowAnswers].sequence.sequenceSteps[index].stepTitle">
                        </v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td width="100%" class="planSequence_tableData" colspan="2">
                        <v-text-field
                          v-bind:key="'STEP_INSTRUCTIONS'"
                          ref="STEP_INSTRUCTIONS_input"
                          id="STEP_INSTRUCTIONS"
                          autofocus
                          label="Step instructions;"
                          :rules="textRules"
                          counter="45"
                          clearable
                          type="text"
                          v-model="ANSWERS[currentFlowAnswers].sequence.sequenceSteps[index].stepInstructions">
                        </v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td width="50%" class="planSequence_tableData">
                        <v-text-field
                          v-bind:key="'STEP_DURATION'"
                          ref="STEP_DURATION_input"
                          id="STEP_DURATION"
                          autofocus
                          label="Step duration"
                          :rules="textRules"
                          counter="45"
                          clearable
                          type="text"
                          v-model="ANSWERS[currentFlowAnswers].sequence.sequenceSteps[index].stepDuration">
                        </v-text-field>
                      </td>
                        
                      
                      <td width="50%" class="planSequence_tableData">
                        <v-select
                          v-bind:key="'ADD_STEPS_TO_SEQUENCE_stepType'+index"
                          :rules="[(v) => !!v || 'Selection is required']"
                          :ref="'ADD_STEPS_TO_SEQUENCE_stepType'+index"  
                          :items="['action', 'rest']"
                          label="Change step type"
                          v-model="ANSWERS[currentFlowAnswers].sequence.sequenceSteps[index].stepType">
                        </v-select>
                      </td>
                      
                    </tr>

                  </table>
                </div>
                <br>
                <div>
                  <table width="100%">
                    <tr>
                      <td width="50%" class="planSequence_tableData">
                        <v-text-field
                          v-bind:key="'STEP_VALUE'"
                          ref="STEP_VALUE_input"
                          id="STEP_VALUE"
                          autofocus
                          label="Value for your step"
                          :rules="textRules"
                          counter="45"
                          clearable
                          type="text"
                          v-model="ANSWERS[currentFlowAnswers].sequence.sequenceSteps[index].stepValue">
                        </v-text-field>
                        
                        
                      </td>
                      <td width="50%" class="planSequence_tableData">
                        <v-text-field
                          v-bind:key="'STEP_VALUE_UOM'"
                          ref="STEP_VALUE_UOM_input"
                          id="STEP_VALUE_UOM"
                          autofocus
                          label="Value unit of measure"
                          :rules="textRules"
                          counter="45"
                          clearable
                          type="text"
                          v-model="ANSWERS[currentFlowAnswers].sequence.sequenceSteps[index].stepValueUom">
                        </v-text-field>
                        
                        
                      </td>
                      
                    </tr>
                  </table>
                </div>
                <br>
                <div>
                  <table width="100%">
                    <tr>
                      <td width="50%" class="planSequence_tableData">
                        
                        <v-select
                          v-bind:key="'ADD_STEPS_TO_SEQUENCE_volumeType'+index"
                          :rules="[(v) => !!v || 'Selection is required']"
                          :ref="'ADD_STEPS_TO_SEQUENCE_volumeType'+index"  
                          :items="['reps', 'duration', 'distance', 'tabata', 'none', 'items']"
                          label="Change volume type"
                          v-model="ANSWERS[currentFlowAnswers].sequence.sequenceSteps[index].stepActionAttributes.volumeType">
                        </v-select>
                        
                      </td>
                      <td width="50%" class="planSequence_tableData">
                        
                        <v-select
                          v-bind:key="'ADD_STEPS_TO_SEQUENCE_intensityType'+index"
                          :rules="[(v) => !!v || 'Selection is required']"
                          :ref="'ADD_STEPS_TO_SEQUENCE_intensityType'+index"  
                          :items="['none', '%1rm', 'load', 'tabata', 'pace', '%effort', '%mhr', 'completion rate']"
                          label="Change intensity type"
                          v-model="ANSWERS[currentFlowAnswers].sequence.sequenceSteps[index].stepActionAttributes.intensityType">
                        </v-select>
                        
                      </td>
                      
                    </tr>
                   

                  </table>
                </div>
              </div>
              <div v-else>
                <SearchHumanActions 
                  :this-index="index"
                  v-on:action-selected="actionSelected"
                  :number="1" />
              </div>
             

            </div>

            <div class="addSequences_addSequenceIcon" v-on:click="addStepToSequence">
              <i class="fas fa-plus-circle"></i>
            </div>

          </div>  
        </div>
        <div class="stepButtonHolder">
          <v-btn @click="overlayOff" dark>Cancel</v-btn>
          <v-btn @click="backAStep" v-if="currentStep > 1" dark>Back</v-btn>
          <v-btn @click="handleAnswer('ADD_STEPS_TO_SEQUENCE')">Continue</v-btn>
        </div>
      </div>

      <!-- ADD_RECIPE_STEPS_TO_SEQUENCE -->
      <div class="stepHolder" v-if="ADD_RECIPE_STEPS_TO_SEQUENCE_STEP_ACTIVE">
        <div class="stepCard">
          <div class="stimulusQuestion lessMinHeight" :key="'ADD_RECIPE_STEPS_TO_SEQUENCE_stimulusQuestion'">
            <h1>{{ this.ADD_RECIPE_STEPS_TO_SEQUENCE_STEP_CONTENT.thoughtStimulusQuestion }}</h1>
          </div>
          <div class="stepTextField lessMarginTop">

            <div class="planSequence_stepHolder" v-for="(step, index) in ANSWERS[currentFlowAnswers].sequence.sequenceSteps" v-bind:key="`sequenceStep`+index">

              <div>
                <!-- Add ingredients -->
                <div v-if="selectedIngredient">

                  {{ selectedIngredient.n }}

                </div>
                <div v-else>
                  <SearchIngredients
                    :this-index="index"
                    v-on:ingredient-selected="ingredientSelected"
                    :number="2" />
                </div>
                <div v-if="selectedIngredient">
                  <v-text-field
                    :ref="`ingredientAmount`+index"
                    label="Ingredient amount"
                    v-model="ingredientAmount"
                    required
                    v-bind:key="`ingredientAmount`"
                    ></v-text-field>
                  <v-text-field 
                    label="Ingredient unit-of-measure"
                    v-model="ingredientUom"
                    required
                    v-bind:key="`ingredientUom`"
                    ></v-text-field>
                  <v-btn
                    @click="addIngredientToStep(index)">
                    Add Ingredient
                  </v-btn>
                </div>
                <br />
                <ul>
                  <li v-for="(ingredient, key) in ANSWERS[currentFlowAnswers].sequence.sequenceSteps[index].stepIngredients" v-bind:key="`ingredient`+key">
                    {{ ingredient.name }}&nbsp;-&nbsp; {{ ingredient.amount }} {{ ingredient.uom }}
                  </li>
                </ul>
              </div> 
              <br>




              <div>
                <img :src="step.stepActionAttributes.actionIconBlack" width="80px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {{ step.stepActionAttributes.agendaItemTitle }}
                 <br>
                <div>
                  <table width="100%">
                    
                    <tr>
                      <td width="100%" class="planSequence_tableData" colspan="2">
                        <v-text-field
                          v-bind:key="'ADD_RECIPE_STEPS_TO_SEQUENCE_INSTRUCTIONS'"
                          ref="ADD_RECIPE_STEPS_TO_SEQUENCE_INSTRUCTIONS_input"
                          id="ADD_RECIPE_STEPS_TO_SEQUENCE_INSTRUCTIONS"
                          autofocus
                          label="Step instructions;"
                          :rules="textRules"
                          counter="45"
                          clearable
                          type="text"
                          v-model="ANSWERS[currentFlowAnswers].sequence.sequenceSteps[index].stepInstructions">
                        </v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td width="100%" class="planSequence_tableData">
                        <v-text-field
                          v-bind:key="'ADD_RECIPE_STEPS_TO_SEQUENCE_DURATION'"
                          ref="ADD_RECIPE_STEPS_TO_SEQUENCE_DURATION_input"
                          id="ADD_RECIPE_STEPS_TO_SEQUENCE_DURATION"
                          autofocus
                          label="Step duration"
                          :rules="textRules"
                          counter="45"
                          clearable
                          type="text"
                          v-model="ANSWERS[currentFlowAnswers].sequence.sequenceSteps[index].stepDuration">
                        </v-text-field>
                      </td>
                    </tr>
                  </table>
                </div>
                <br>
                
                
              </div>
              
             

            </div>

            <div class="addSequences_addSequenceIcon" v-on:click="addStepToSequence">
              <i class="fas fa-plus-circle"></i>
            </div>

          </div>  
        </div>
        <div class="stepButtonHolder">
          <v-btn @click="overlayOff" dark>Cancel</v-btn>
          <v-btn @click="backAStep" v-if="currentStep > 1" dark>Back</v-btn>
          <v-btn @click="handleAnswer('ADD_RECIPE_STEPS_TO_SEQUENCE')">Continue</v-btn>
        </div>
      </div>


      <!-- COLLECT_SEQUENCE_METADATA -->
      <div class="stepHolder" v-if="COLLECT_SEQUENCE_METADATA_STEP_ACTIVE">
        <div class="stepCard">
          <div class="stimulusQuestion lessMinHeight" :key="'COLLECT_SEQUENCE_METADATA_stimulusQuestion'">
            <h1>{{ this.COLLECT_SEQUENCE_METADATA_STEP_CONTENT.thoughtStimulusQuestion }}</h1>
          </div>
          <div class="stepTextField lessMarginTop">

            <div>
              <v-text-field
                v-bind:key="'SEQUENCE_TITLE'"
                ref="SEQUENCE_TITLE_input"
                id="SEQUENCE_TITLE"
                autofocus
                label="Title for your sequence"
                :rules="textRules"
                counter="45"
                clearable
                type="text"
                v-model="ANSWERS[currentFlowAnswers].sequence.sequenceTitle">
              </v-text-field>
            </div>
            <br />


          </div>  
        </div>
        <div class="stepButtonHolder">
          <v-btn @click="overlayOff" dark>Cancel</v-btn>
          <v-btn @click="backAStep" v-if="currentStep > 1" dark>Back</v-btn>
          <v-btn @click="handleAnswer('COLLECT_SEQUENCE_METADATA')">Submit</v-btn>
        </div>
      </div>


      






     


      

      <br />
      <br />


    </div> <!-- END OF WRAPPER -->
  </div>
</template>

<script>
  //Some example imports you could have:
    //import Worker from "worker-loader!@/worker";
    //import SearchSequences from '@/components/SearchSequences'
    import SearchHumanActions from '@/components/SearchHumanActions'
    //import firebase from '@/firebase'
  
    import SearchIngredients from '@/components/SearchIngredients'
    import sequenceModule from '@/store/sequence'
    import signinModule from '@/store/signin'
    import searchModule from '@/store/search'
    import { mapGetters, mapActions } from 'vuex'
    import '@/plugins/vuetify'
    import moment from 'moment-timezone'
    //import Vue from 'vue'
    //import { v4 as uuidv4 } from 'uuid'

    

  export default {
    name: "SequenceOverlayAdmin",
    components: {
      SearchIngredients,
      SearchHumanActions
      //CountdownTimer
    },
    props: {


      parentView: {
        type: String
      },
      sequenceType: {
        type: String
      },
      selectedItem: {
        type: Object
      }
    },

    data () {
      return {
        flowInitialized: false,

        currentView: 'INSERT_VIEW_HERE',


        currentStep: 1,
        currentStepId: null,
        currentStepContent: null,
        currentFlowManifest: null,
        currentFlowAnswers: null,



        randomNumber: null,

        textRules: [
          (v) => !!v || 'Input is required',
          (v) => (v && v.length <= 45) || 'Max 45 characters'
        ],

        selectRules: [
          (v) => !!v || 'Input is required',
        ],

        
        selectedIngredient: null,
        ingredientAmount: null,
        ingredientUom: null,

        
        menu: [false],

        //INSTRUCTIONS FOR ADDING FUTURE FLOWS:
        //1 - Create the step template above
        //1 - CREATE A MANIFEST OF STEPS FOR THE FLOW, OR ADD STEP TO EXISTING MANIFEST
        //2 - CREATE A FLOW ANSWER OBJECT, OR ADD TO AN EXISTING ONE
        //3 - CREATE STEP OBJECTS FOR ANY UNIQUE FLOW STEPS NOT ALREADY DEFINED



        ANSWERS: {

          SEQUENCE_FLOW_ANSWERS: {

            /* NEW SEQUENCE VARS */
            sequence: {
              sequenceTitle: null,
              sequenceSteps: [{
                stepActionAttributes: {
                  actionIconBlack: null,
                  actionIconGrey: null,
                  actionId: null,
                  actionLoadType: null,
                  actionUom: null,
                  agendaItemTitle: null,
                  intensityType: null,
                  isAerobic: false,
                  isAnaerobic: false,
                  isIsometric: false,
                  isLoadable: false,
                  outcomeInputType: null,
                  volumeType: null,
                },
                stepDuration: "60000",
                stepId: null,
                stepImage: null,
                stepIngredients: {},
                stepInstructions: null,
                stepSequenceNumber: 1,
                stepSubtitle: null,
                stepStartTimeOffset: 0,
                stepTitle: null,
                stepType: "action",
                stepValue: null,
                stepValueUom: null,
              }],
              sequenceTags: [],
            },


            
          },
          
        },
        
        FLOWS: {

          WORKOUT_SEQUENCE_FLOW_MANIFEST: [
            'ADD_STEPS_TO_SEQUENCE',
            'COLLECT_SEQUENCE_METADATA',
            //'OUTRO_SEQUENCE'
          ],

          RECIPE_SEQUENCE_FLOW_MANIFEST: [
            'ADD_RECIPE_STEPS_TO_SEQUENCE',
            'COLLECT_SEQUENCE_METADATA',
            //'OUTRO_SEQUENCE'
          ],

          INSTRUCTIONS_SEQUENCE_FLOW_MANIFEST: [
            'ADD_INSTRUCTION_STEPS_TO_SEQUENCE',
            'COLLECT_SEQUENCE_METADATA',
            //'OUTRO_SEQUENCE'
          ],



          
        
        },





        //STEP VARIABLES
        COLLECT_SEQUENCE_METADATA_STEP_ACTIVE: false,
        COLLECT_SEQUENCE_METADATA_STEP_CONTENT: {
          thoughtStimulusQuestion: 'What is the title of this sequence?',
          stepInstructions: '',
          stepHint: '',

        },

        ADD_STEPS_TO_SEQUENCE_STEP_ACTIVE: false,
        ADD_STEPS_TO_SEQUENCE_STEP_CONTENT: {
          thoughtStimulusQuestion: 'Add action steps to sequence',
          stepInstructions: '',
          stepHint: '',
        },

        ADD_RECIPE_STEPS_TO_SEQUENCE_STEP_ACTIVE: false,
        ADD_RECIPE_STEPS_TO_SEQUENCE_STEP_CONTENT: {
          thoughtStimulusQuestion: 'Add recipe steps to sequence',
          stepInstructions: '',
          stepHint: '',
        },

        ADD_INSTRUCTION_STEPS_TO_SEQUENCE_STEP_ACTIVE: false,
        ADD_INSTRUCTION_STEPS_TO_SEQUENCE_STEP_CONTENT: {
          thoughtStimulusQuestion: 'Add instruction steps to sequence',
          stepInstructions: '',
          stepHint: '',
        },

        OUTRO_SEQUENCE_STEP_ACTIVE: false,
        OUTRO_SEQUENCE_STEP_CONTENT: {
          thoughtStimulusQuestion: 'Submit',
          stepInstructions: '',
          stepHint: '',
        },


        


      }
    },

    computed: {

      ...mapGetters('auth', {
        profile: 'profile',
        loggedIn: 'loggedIn',
      }),




      todayDayStamp () {
        var now = moment()
        var dayStamp = now.format("YYYY") + "-" + now.format("MM") + "-" + now.format("DD")
        return dayStamp
      },






    },
    watch: {
      //MAIN ENTRY POINT
      sequenceType: function(newValue) {
        if(newValue) {
          this.initializeFlow()
          this.currentStep = 1
          this.goToStep(this.currentStep)
        }
        
      },
      
      
    },
    methods: { 

      ...mapActions('signin', [
        'googleLogin',
        'anonymousLogin',
        'autoSignIn'
      ]),


      ...mapActions('search', [
        'setShowSearch'
      ]),

      ...mapActions('sequence', [
        'createSequence'
      ]),

      resetSteps() {

        this.COLLECT_SEQUENCE_METADATA_STEP_ACTIVE = false
        this.ADD_STEPS_TO_SEQUENCE_STEP_ACTIVE = false
        this.ADD_RECIPE_STEPS_TO_SEQUENCE_STEP_ACTIVE = false
        this.ADD_INSTRUCTION_STEPS_TO_SEQUENCE_STEP_ACTIVE = false
        
      },
      
      initializeFlow () {

        this.resetSteps()



        this.currentFlowAnswers = 'SEQUENCE_FLOW_ANSWERS'


        if(this.sequenceType == "workout") {
          this.currentFlowManifest= 'WORKOUT_SEQUENCE_FLOW_MANIFEST'

        }else if(this.sequenceType == "cooking") {
          this.currentFlowManifest = 'RECIPE_SEQUENCE_FLOW_MANIFEST'

        }else if(this.sequenceType == "instructions") {
          this.currentFlowManifest = 'INSTRUCTIONs_SEQUENCE_FLOW_MANIFEST'

        }else {
          this.currentFlowManifest = 'WORKOUT_SEQUENCE_FLOW_MANIFEST'

        }
       
        
        
      },

      actionSelected (payload) {
        this.ANSWERS[this.currentFlowAnswers].sequence.sequenceSteps[payload.index].stepActionAttributes.actionId = (payload.action.id) ? payload.action.id : null
        this.ANSWERS[this.currentFlowAnswers].sequence.sequenceSteps[payload.index].stepActionAttributes.agendaItemTitle = (payload.action.at) ? payload.action.at : null
        this.ANSWERS[this.currentFlowAnswers].sequence.sequenceSteps[payload.index].stepActionAttributes.actionLoadType = (payload.action.alt) ? payload.action.alt : null
        this.ANSWERS[this.currentFlowAnswers].sequence.sequenceSteps[payload.index].stepActionAttributes.actionIconBlack = (payload.action.i) ? payload.action.i : null
        this.ANSWERS[this.currentFlowAnswers].sequence.sequenceSteps[payload.index].stepActionAttributes.actionIconGrey = (payload.action.ig) ? payload.action.ig : null
        this.ANSWERS[this.currentFlowAnswers].sequence.sequenceSteps[payload.index].stepActionAttributes.actionUom = (payload.action.au) ? payload.action.au : null
        this.ANSWERS[this.currentFlowAnswers].sequence.sequenceSteps[payload.index].stepActionAttributes.intensityType = (payload.action.it) ? payload.action.it : null
        this.ANSWERS[this.currentFlowAnswers].sequence.sequenceSteps[payload.index].stepActionAttributes.volumeType = (payload.action.vt) ? payload.action.vt : null
        var vt = this.ANSWERS[this.currentFlowAnswers].sequence.sequenceSteps[payload.index].stepActionAttributes.volumeType
        var it = this.ANSWERS[this.currentFlowAnswers].sequence.sequenceSteps[payload.index].stepActionAttributes.intensityType
        this.ANSWERS[this.currentFlowAnswers].sequence.sequenceSteps[payload.index].stepActionAttributes.outcomeInputType = vt+"-"+it
        
        
        this.ANSWERS[this.currentFlowAnswers].sequence.sequenceSteps[payload.index].stepActionAttributes.isLoadable = (payload.action.il) ? payload.action.il : false
        this.ANSWERS[this.currentFlowAnswers].sequence.sequenceSteps[payload.index].stepActionAttributes.isAerobic = (payload.action.ia) ? payload.action.ia : false
        this.ANSWERS[this.currentFlowAnswers].sequence.sequenceSteps[payload.index].stepActionAttributes.isAnaerobic = (payload.action.ian) ? payload.action.ian : false
        this.ANSWERS[this.currentFlowAnswers].sequence.sequenceSteps[payload.index].stepActionAttributes.isIsometric = (payload.action.ii) ? payload.action.ii : false
        
        
        if(payload.action.t) {
          payload.action.t.forEach(tag => {
            if(!this.ANSWERS[this.currentFlowAnswers].sequence.sequenceTags.includes(tag)) {
              this.ANSWERS[this.currentFlowAnswers].sequence.sequenceTags.push(tag)
            }
          })
        }
        var img = this.ANSWERS[this.currentFlowAnswers].sequence.sequenceSteps[payload.index].stepActionAttributes.actionIconBlack
        this.ANSWERS[this.currentFlowAnswers].sequence.sequenceSteps[payload.index].stepImage = img
        

        var title = this.ANSWERS[this.currentFlowAnswers].sequence.sequenceSteps[payload.index].stepActionAttributes.agendaItemTitle
        this.ANSWERS[this.currentFlowAnswers].sequence.sequenceSteps[payload.index].stepTitle = title
        
        var uom = this.ANSWERS[this.currentFlowAnswers].sequence.sequenceSteps[payload.index].stepActionAttributes.actionUom
        this.ANSWERS[this.currentFlowAnswers].sequence.sequenceSteps[payload.index].stepValueUom = uom
      },

      ingredientSelected (payload) {
        this.selectedIngredient = payload.ingredient
        
      },

      addIngredientToStep (index) {
        var ingredientObj = {
          'amount': this.ingredientAmount,
          'icon': this.selectedIngredient.ic,
          'ingredientId': this.selectedIngredient.iid,
          'name': this.selectedIngredient.n,
          'uom': this.ingredientUom
        }
      
        this.$set(this.ANSWERS[this.currentFlowAnswers].sequence.sequenceSteps[index].stepIngredients, this.selectedIngredient.iid, ingredientObj)
        this.selectedIngredient = null
        this.ingredientAmount = null
        this.ingredientUom = null
      },
      
      backAStep () {
        window.scrollTo(0,0)
        //var fromStep = this.currentStep
        var fromStepId = this.currentStepId
        var toStep = this.currentStep-1
        var toStepId = this.FLOWS[this.currentFlowManifest][(toStep-1)]

        this[fromStepId+'_STEP_ACTIVE'] = false
        this[toStepId+'_STEP_ACTIVE'] = true
        this.currentStep = toStep
        this.currentStepId = toStepId
      },
      onDotClick (dotIndex) {
        this.goToStep(dotIndex+1)
      },


      addStepToSequence () {
        var len = this.ANSWERS[this.currentFlowAnswers].sequence.sequenceSteps.length
        var newStepNum = len + 1
        var obj = {
                stepActionAttributes: {
                  actionIconBlack: null,
                  actionIconGrey: null,
                  actionId: null,
                  actionLoadType: null,
                  actionUom: null,
                  agendaItemTitle: null,
                  intensityType: null,
                  isAerobic: false,
                  isAnaerobic: false,
                  isIsometric: false,
                  isLoadable: false,
                  outcomeInputType: null,
                  volumeType: null,
                },
                stepDuration: "90000",   //input required
                stepId: null,
                stepImage: null,
                stepIngredients: {},
                stepInstructions: null,
                stepSequenceNumber: newStepNum,
                stepSubtitle: null,
                stepStartTimeOffset: 0,
                stepTitle: null,       //input - prefill with agendaItemTitle
                stepType: "action",        //input
                stepValue: null,       //input
                stepValueUom: null,    //input
              }
        this.ANSWERS[this.currentFlowAnswers].sequence.sequenceSteps.push(obj)

      },

      addRecipeStepToSequence () {

      },
      
      goToStep (goToStep) {
        window.scrollTo(0,0)
        var fromStepId;
        var fromStep
        if((goToStep == 1) && (this.currentStep < 2)) {
          fromStep = 0
          fromStepId = null
        } else {
          fromStep = this.currentStep
          fromStepId = this.FLOWS[this.currentFlowManifest][(fromStep-1)]
        }
        
        var goToStepId = this.FLOWS[this.currentFlowManifest][(goToStep-1)]
        this[goToStepId+'_STEP_ACTIVE'] = true
        //console.log('goToStepId',goToStepId)
        if(fromStepId) {
          this[fromStepId+'_STEP_ACTIVE'] = false
        }
        //console.log('fromStepId',fromStepId)

      
        this.currentStep = goToStep
        this.currentStepId = goToStepId
      },
      overlayOff () {

        this.ANSWERS.SEQUENCE_FLOW_ANSWERS = {
          sequence: {
            sequenceTitle: null,
            sequenceTags: [],
            sequenceSteps: [{
              stepActionAttributes: {
                actionIconBlack: null,
                actionIconGrey: null,
                actionId: null,
                actionLoadType: null,
                actionUom: null,
                agendaItemTitle: null,
                intensityType: null,
                isAerobic: false,
                isAnaerobic: false,
                isIsometric: false,
                isLoadable: false,
                outcomeInputType: null,
                volumeType: null,
              },
              stepDuration: "90000",
              stepId: null,
              stepIngredients: {},
              stepInstructions: null,
              stepImage: null,
              stepSequenceNumber: 1,
              stepSubtitle: null,
              stepStartTimeOffset: 0,
              stepTitle: null,
              stepType: 'action',
              stepValue: null,
              stepValueUom: null,
            }]
          },    
        }
          

        this.$emit('overlay-off')
        
      },


      async handleAnswer(stepId) {

        if(stepId == 'INTRO') {
          this.goToStep(this.currentStep+1)
          return


        }else if(stepId == 'ADD_STEPS_TO_SEQUENCE') {
          
          this.goToStep(this.currentStep+1)
          
        }else if(stepId == 'ADD_RECIPE_STEPS_TO_SEQUENCE') {
          
          this.goToStep(this.currentStep+1)

        }else if(stepId == 'ADD_INSTRUCTION_STEPS_TO_SEQUENCE') {
          
          this.goToStep(this.currentStep+1)
        
        }else if( 
                  (stepId == 'COLLECT_SEQUENCE_METADATA')
                  
                ) {

          var intentMeanCategory = null

          //For each object in sequenceSteps
            //process stepStartTimeOffset
            var totalDuration = 0
          var theSteps = this.ANSWERS.SEQUENCE_FLOW_ANSWERS.sequence.sequenceSteps
          theSteps.forEach((step,idx) => {

            this.ANSWERS.SEQUENCE_FLOW_ANSWERS.sequence.sequenceSteps[idx].stepStartTimeOffset = totalDuration
            totalDuration += parseInt(step.stepDuration)


          })

          var newSequenceArray = []
          this.ANSWERS.SEQUENCE_FLOW_ANSWERS.sequence.sequenceSteps.forEach(step => {
            newSequenceArray.push(step)
          })



          var sequenceObj = {
            'dateCreated': new Date(),
            'sequenceIconImageGrey': this.ANSWERS.SEQUENCE_FLOW_ANSWERS.sequence.sequenceSteps[0].stepActionAttributes.actionIconGrey,
            'sequenceIconImageBlack': this.ANSWERS.SEQUENCE_FLOW_ANSWERS.sequence.sequenceSteps[0].stepActionAttributes.actionIconBlack,
            'intentMeanCategory': (intentMeanCategory) ? intentMeanCategory : null,
            'sequenceStatus': 'active',
            'sequenceSteps': newSequenceArray,     
            'sequenceTitle': (this.ANSWERS.SEQUENCE_FLOW_ANSWERS.sequence.sequenceTitle) ? this.ANSWERS.SEQUENCE_FLOW_ANSWERS.sequence.sequenceTitle : null,
            'sequenceType': this.sequenceType,
            'sequenceTags': this.ANSWERS.SEQUENCE_FLOW_ANSWERS.sequence.sequenceTags,
          }




          await this.createSequence({'sequenceObj': sequenceObj})
          this.$router.push('/admin/sequences/')



        }
      },


      

    },
    beforeCreate () {
      
      if(!this.$store.state.signin) this.$store.registerModule('signin', signinModule)
      if(!this.$store.state.search) this.$store.registerModule('search', searchModule) 
      if(!this.$store.state.sequence) this.$store.registerModule('sequence', sequenceModule) 

    },
    created () {
      this.flowInitialized = false;
      if(this.initView) {
        this.currentView = this.initView
      }


    
    },
    mounted () {
    },
    updated () {
      this.setShowSearch(false)
    }
  }
  
</script>

<style scoped>
.wrapper {
  /*max-width: 1300px;*/
  margin: 0 auto;
  padding: 0;
}
a {
  color: #777;
}

.routineOverlayAdmin {
  position: absolute; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.97); /* Black background with opacity */
  z-index: 1100; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  vertical-align: top;
  font-size: 1.4em;
  text-align: left;
  overflow-y: hidden;
}
.overlayTitle {
  display: inline-block;
  color: white;
  font-weight: 300;
  font-size: .8em;
}
#logo {
  position: absolute;
  z-index: 1001;
  left: 8px;
  bottom: 28px;
}
#logo > img {
  height: 64px;
}
.category-img {
  display: none;
  background: rgba(34, 34, 34, 1);
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 30px left;
  object-position: 30px left;
  width: 100%;
  max-width: 450px;
  max-height: 250px;
  margin: 0 auto;
  
}

.stepHolder {
  font-size: .65em;
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 50px;
  width: 95%;
}

.titleHolder {
  position: relative;
  color: rgb(230, 230, 230);
  font-weight: 400;
  background-color: #222;
  text-align: center;
  overflow: hidden;
  height: 70px;
}
.titleHolder2 {
  width: 100%;
  position: absolute;
  font-size: .85em;
  top: 13px;
  left: 50%;
  transform: translateX(-50%);
}
.titleIcon {
  position: absolute;
  color: rgb(77, 77, 77);
  font-size: 1.4em;
  top: 7px;
  left: 15px;
}

/*Styles for Step Cards and elements*/
.stepCard {
  position: relative;
  background-color: white;
  padding: 10px;
  min-height: 200px;
  margin-top: 10px;
}
.intro {
  background-color: transparent;
  color: lightgray;
  font-size: 1.4em;
  padding-top: 5px;
}

.stimulusQuestion {
  font-size: .9em;
}
.column {
  vertical-align: top;
  display: inline-block;
  width: 100%;
}
.switchColumn {
  width: 15%;
  min-width: 40px;
  background-color: transparent;
  display:inline-block;
  text-align: right;
}
.switchLabel {
  width: 80%;
  background-color: transparent;
  display: inline-block;
  font-size: 1.3em;
  padding-left: 16px;
}
.stepButtonHolder {
  text-align: right;
  padding: 4px;
}
.stepTextField {
  margin: 0 auto;
  margin-top: 20px;
}
.lessMarginTop {
  margin-top: 20px;
}
.lessMinHeight {
  min-height: 20px;
}
.redFont {
  color: red;
}
.v-input--selection-controls {
  margin-top: 0px;
}
.notificationSwitchHolder {
  margin: 0 auto;
  width: 90%;
  background-color: transparent;
}

.planSequence_stepHolder {
  background-color: rgb(231, 231, 231);
  margin-top: 7px;
  padding: 30px;
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 7px;
}

.planSequence_tableData {
  background-color: transparent;
  padding: 10px;
}

.addSequences_addSequenceIcon {
  font-size: 2em;
  color: rgb(129, 129, 129);
  background-color: transparent;
  display: inline-block;
  cursor: pointer;
  padding: 15px;
}

/* ADD_EVENTS */
.addEvents_PhaseTitle {
  width: 100%;
  background-color: transparent;
  color: rgb(156, 156, 156);
  font-size: 1.5em;
  padding-bottom: 2px;
  border-bottom: 1px solid gray;
}


.addEvents_detailsHolder {
  width: 100%;
  background-color: rgb(235, 235, 235);
  font-size: 1.4em;
  border: solid 1px lightgrey;
  border-radius: 6px;
  position: relative;
  margin-bottom: 30px;
}



.addEventQuestionIcon {
  position: absolute;
  bottom: 4px;
  right: 20px;
  height: 30px;
  width: 150px;
  background-color: transparent;
  text-align: center;
  padding-top: 5px;
  font-size: .7em;
}
.addTaskQuestionIcon {
  position: absolute;
  bottom: 4px;
  right: 190px;
  height: 30px;
  width: 190px;
  background-color: transparent;
  text-align: center;
  padding-top: 5px;
  font-size: .7em;
}
.addEvents_controlsHolder {
  width: 100%;
  background-color: transparent;
  margin: 0 auto;
  max-width: 400px;
}
.addEvents_inputEvent {
  background-color: transparent;
}
.addEvents_selectDate {
  background-color: transparent;
  padding: 10px;
}
.addEvents_addEventIcon {
  font-size: 2em;
  color: rgb(129, 129, 129);
  background-color: transparent;
  display: inline-block;
  cursor: pointer;
  padding: 15px;
}
.questionObjHolder {
  padding: 15px;
  margin-bottom: 30px;
  background-color: rgb(253, 249, 235);
  border-radius: 7px;
}


/* PLAN_ROUTINE */
.planRoutine_dayOfWeekHolder {
  width: 100%;
  background-color: transparent;
  margin-bottom: 85px;
  position: relative;
}
.reviewRoutine_dayOfWeekHolder {
  
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 45px;
}
.planRoutine_dayOfWeekTitle {
  width: 100%;
  background-color: transparent;
  color: rgb(156, 156, 156);
  font-size: 1.2em;
  padding-bottom: 2px;
  border-bottom: 1px solid gray;
}
.planRoutine_momentHolder {
  background-color: rgb(231, 231, 231);
  margin-top: 7px;
  padding: 30px;
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 7px;
}
.planRoutine_momentTitle {
  font-size: 1.6em;
  font-weight: 700;
}
.reviewRoutine_momentTitle {
  font-size: 1.6em;
  font-weight: 500;
}
.planRoutine_addMomentIcon {
  padding: 20px;
  font-size: 2em;
  color: rgb(175, 175, 175);
}
.planRoutine_detailsHolder {
  width: 100%;
  background-color: transparent;
  font-size: 1.5em;
  padding: 7px;
}
.planRoutine_controlsHolder {
  width: 100%;
  background-color: transparent;
  margin: 0 auto;
  padding: 3px;
  max-width: 400px;
}
.planRoutine_selectTimeOfDay {
  background-color: transparent;
  padding: 10px;
}
.planRoutine_selectSetCount {
  background-color: transparent;
  padding: 10px;
}
.planRoutine_intensityType {
  background-color: transparent;
  padding: 10px;
}
.planRoutine_volumeType {
  background-color: transparent;
  padding: 10px;
}

.contentContainer {
  position: relative;
  max-width: 900px;
  margin: 0 auto;
  min-height: 200px;
  padding-bottom: 100px;
}



/* PLAN_CESSATION_SCHEDULE*/
.planCessationRoutine_selectStartDay {
  background-color: transparent;
  padding: 10px;
}
.planCessationRoutine_selectTimesPerDay {
  background-color: transparent;
  padding: 10px;
}
.planCessationRoutine_addPhaseIcon {
  font-size: 2em;
  color: rgb(175, 175, 175);
}
.planCessationRoutine_PhaseTitle {
  width: 100%;
  background-color: transparent;
  color: rgb(156, 156, 156);
  font-size: 1.5em;
  padding-bottom: 2px;
  border-bottom: 1px solid gray;
}
.error {
  color: white;
  display: none;
}
.resetSchedule {
  position: absolute;
  bottom: 0;
  right: 0;
}



/*  Dot Holder   */
.dotsHolder {
  position: absolute;
  left: 50%;
  bottom: 3px;
  transform: translateX(-50%);
  display: block;
  width: 100%;
  background-color: transparent;
  text-align: center;
}
.dots {
  display: inline-block;
  color: lightgrey;
  padding: 9px;
  font-size: .5em;
  
  vertical-align: top;
}
.currentDot {
  color: rgb(63, 106, 170);
}
.greyOutroDot {
  color: lightgrey;
}
.greenOutroDot {
  color: green;
  font-size: 1.5em;
}
.greyArrow {
  color: lightgrey;
}

@media screen and (min-width: 360px) {
  .stepCard {
    padding: 18px;
    min-height: 210px;
  }
  .dotsHolder {
    bottom: -4px;
  }
  .titleHolder {
    font-size: .95em;
  }
  .titleIcon {
    font-size: 1.5em;
  }
  .stepTextField {
    
    margin-top: 20px;
  }
  .intro {
    font-size: 1.6em
  }
}

@media screen and (min-width: 400px) {
  .titleHolder {
    font-size: .95em;
    padding-right: 50px;
    padding-left: 50px;
  }
  .titleIcon {
    font-size: 1.7em;
  }
  .notificationSwitchHolder {
    width: 70%;
  }
  .intro {
    font-size: 1.75em
  }
  .stepCard {
    padding: 20px;
    min-height: 250px;
  }
  
}

@media screen and (min-width: 500px) {
  .titleHolder {
    font-size: 1em;
    padding-right: 50px;
    padding-left: 50px;
  }
  .titleIcon {
    font-size: 1.5em;
  }
  .notificationSwitchHolder {
    width: 60%;
  }
  
}

@media screen and (min-width: 750px) {
  .titleHolder {
    font-size: 1em;
  }
  .titleIcon {
    font-size: 1.5em;
  }
 .intro {
    font-size: 1.85em
  }
  .stepHolder {
    max-width: 800px;
  }
  .reviewRoutine_dayOfWeekHolder {
    max-width: 700px;
  }
  .addEvents_controlsHolder {
    max-width: 700px;
  }
  .column {
    width: 80%;
  }
}
</style>