// Auth
export const SET_SOMETHING = "SET_SOMETHING"
export const PUSH_INTENT_MEAN_CATEGORY = "PUSH_INTENT_MEAN_CATEGORY"
export const SET_INTENT_MEAN_CATEGORY_LIST_SELECT = "SET_INTENT_MEAN_CATEGORY_LIST_SELECT"
export const RESET_INTENT_MEAN_CATEGORY_LIST = "RESET_INTENT_MEAN_CATEGORY_LIST"
export const RESET_INTENT_MEAN_CATEGORY_LIST_SELECT = "RESET_INTENT_MEAN_CATEGORY_LIST_SELECT"
export const SET_INTENT_MEAN_CATEGORY_LIST_SELECT_FOR_ARTICLE = "SET_INTENT_MEAN_CATEGORY_LIST_SELECT_FOR_ARTICLE"
export const SET_INTENT_MEAN_CATEGORY_LIST_SELECT_FOR_ROUTINE = "SET_INTENT_MEAN_CATEGORY_LIST_SELECT_FOR_ROUTINE"
export const RESET_INTENT_MEAN_CATEGORY_LIST_FOR_ROUTINE = "RESET_INTENT_MEAN_CATEGORY_LIST_FOR_ROUTINE"
export const RESET_INTENT_MEAN_CATEGORY_LIST_SELECT_FOR_ARTICLE = "RESET_INTENT_MEAN_CATEGORY_LIST_SELECT_FOR_ARTICLE"
export const RESET_INTENT_MEAN_CATEGORY_LIST_SELECT_FOR_ROUTINE = "RESET_INTENT_MEAN_CATEGORY_LIST_SELECT_FOR_ROUTINE"
export const RESET_HUMAN_ACTION_LIST = "RESET_HUMAN_ACTION_LIST"
export const PUSH_HUMAN_ACTION_DOC = "PUSH_HUMAN_ACTION_DOC"
export const RESET_ROUTINE_LIST = "RESET_ROUTINE_LIST"
export const RESET_SEQUENCE_LIST = "RESET_SEQUENCE_LIST"
export const RESET_INGREDIENT_LIST = "RESET_INGREDIENT_LIST"
export const RESET_MEAL_LIST = "RESET_MEAL_LIST"
export const RESET_ARTICLE_LIST = "RESET_ARTICLE_LIST"
export const PUSH_ROUTINE_DOC = "PUSH_ROUTINE_DOC"
export const PUSH_SEQUENCE_DOC = "PUSH_SEQUENCE_DOC"
export const PUSH_MEAL_DOC = "PUSH_MEAL_DOC"
export const PUSH_ARTICLE_DOC = "PUSH_ARTICLE_DOC"
export const PUSH_INGREDIENT_DOC = "PUSH_INGREDIENT_DOC"
export const SET_LANG = "SET_LANG"