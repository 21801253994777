import db from '@/db'
//import firebase from '@/firebase'

import {
    SET_SOMETHING,
    PUSH_INTENT_MEAN_CATEGORY,
    SET_INTENT_MEAN_CATEGORY_LIST_SELECT,
    SET_INTENT_MEAN_CATEGORY_LIST_SELECT_FOR_ROUTINE,
    SET_INTENT_MEAN_CATEGORY_LIST_SELECT_FOR_ARTICLE,
    RESET_INTENT_MEAN_CATEGORY_LIST,
    RESET_INTENT_MEAN_CATEGORY_LIST_SELECT,
    RESET_INTENT_MEAN_CATEGORY_LIST_SELECT_FOR_ROUTINE,
    RESET_INTENT_MEAN_CATEGORY_LIST_SELECT_FOR_ARTICLE,
    RESET_HUMAN_ACTION_LIST,
    PUSH_HUMAN_ACTION_DOC,
    RESET_ROUTINE_LIST,
    PUSH_ROUTINE_DOC,
    RESET_SEQUENCE_LIST,
    PUSH_SEQUENCE_DOC,
    SET_LANG,
    RESET_INGREDIENT_LIST,
    PUSH_INGREDIENT_DOC,
    RESET_MEAL_LIST,
    PUSH_MEAL_DOC,
    RESET_ARTICLE_LIST,
    PUSH_ARTICLE_DOC,

  } from './mutations'

  
  const state = {
    intentMeanCategoryList: [],
    humanActionList: [],
    routineList: [],
    sequenceList: [],
    intentMeanCategoryListSelect: [],
    intentMeanCategoryListSelectForRoutine: [],
    intentMeanCategoryListSelectForArticle: [],
    ingredientList: [],
    mealList: [],
    articleList: [],
    
  }
  
  const getters = {
    intentMeanCategoryList: ({intentMeanCategoryList}) => intentMeanCategoryList,
    intentMeanCategoryListSelect: ({intentMeanCategoryListSelect}) => intentMeanCategoryListSelect,
    intentMeanCategoryListSelectForArticle: ({intentMeanCategoryListSelectForArticle}) => intentMeanCategoryListSelectForArticle,
    intentMeanCategoryListSelectForRoutine: ({intentMeanCategoryListSelectForRoutine}) => intentMeanCategoryListSelectForRoutine,
    humanActionList: ({humanActionList}) => humanActionList,
    routineList: ({routineList}) => routineList,
    sequenceList: ({sequenceList}) => sequenceList,
    ingredientList: ({ingredientList}) => ingredientList,
    mealList: ({mealList}) => mealList,
    articleList: ({articleList}) => articleList,

    
  }
  
  const mutations = {
    [SET_SOMETHING] (state, bool) {
      state.hasMinders = bool,
      localStorage.setItem('hasMinders', JSON.stringify(bool))
    },

    [PUSH_INTENT_MEAN_CATEGORY] (state, cat) {
      state.intentMeanCategoryList.push(cat.name)
    },
    [PUSH_HUMAN_ACTION_DOC] (state, doc) {
      state.humanActionList.push(doc)
    },
    [PUSH_ROUTINE_DOC] (state, doc) {
      state.routineList.push(doc)
    },
    [PUSH_SEQUENCE_DOC] (state, doc) {
      state.sequenceList.push(doc)
    },
    [PUSH_MEAL_DOC] (state, doc) {
      state.mealList.push(doc)
    },
    [PUSH_ARTICLE_DOC] (state, doc) {
      state.articleList.push(doc)
    },
    [PUSH_INGREDIENT_DOC] (state, doc) {
      state.ingredientList.push(doc)
    },

    
    [SET_INTENT_MEAN_CATEGORY_LIST_SELECT] (state, payload) {
      state.intentMeanCategoryListSelect = []
      state.intentMeanCategoryListSelect.push(payload)
    },
    [RESET_INTENT_MEAN_CATEGORY_LIST_SELECT] (state) {
      state.intentMeanCategoryListSelect = []
    },
    [SET_INTENT_MEAN_CATEGORY_LIST_SELECT_FOR_ROUTINE] (state, payload) {
      state.intentMeanCategoryListSelectForRoutine = []
      if(payload) {
        state.intentMeanCategoryListSelectForRoutine.push(payload)
      }
      
    },
    [RESET_INTENT_MEAN_CATEGORY_LIST_SELECT_FOR_ROUTINE] (state) {
      state.intentMeanCategoryListSelectForRoutine = []
    },

    [SET_INTENT_MEAN_CATEGORY_LIST_SELECT_FOR_ARTICLE] (state, payload) {
      state.intentMeanCategoryListSelectForArticle = []
      if(payload) {
        state.intentMeanCategoryListSelectForArticle.push(payload)
      }
      
    },
    [RESET_INTENT_MEAN_CATEGORY_LIST_SELECT_FOR_ARTICLE] (state) {
      state.intentMeanCategoryListSelectForArticle = []
    },


    [RESET_INTENT_MEAN_CATEGORY_LIST] (state) {
      state.intentMeanCategoryList = []
    },
    [RESET_HUMAN_ACTION_LIST] (state) {
      state.humanActionList = []
    },
    [RESET_ROUTINE_LIST] (state) {
      state.routineList = []
    },
    [RESET_SEQUENCE_LIST] (state) {
      state.sequenceList = []
    },
    [RESET_INGREDIENT_LIST] (state) {
      state.ingredientList = []
    },
    [RESET_MEAL_LIST] (state) {
      state.mealList = []
    },
    [RESET_ARTICLE_LIST] (state) {
      state.articleList = []
    }
    
  }
  
  const actions = {
    async setLang ({commit}, lang) {
      return new Promise((resolve) => {
        commit(SET_LANG, lang)
        resolve();
      })
    },

    setIntentMeanCategoryListSelect ({commit}, payload) {
      commit(SET_INTENT_MEAN_CATEGORY_LIST_SELECT, payload)
    },
    setIntentMeanCategoryListSelectForRoutine ({commit}, payload) {
      commit(SET_INTENT_MEAN_CATEGORY_LIST_SELECT_FOR_ROUTINE, payload)
    },
    setIntentMeanCategoryListSelectForArticle ({commit}, payload) {
      commit(SET_INTENT_MEAN_CATEGORY_LIST_SELECT_FOR_ARTICLE, payload)
    },

    async addHumanAction ({commit}, payload) {

      var ref = await db.collection('human_actions').doc()
      ref.set({
        ...payload,
        'actionId': ref.id,
      }, {merge: true})
      commit(PUSH_HUMAN_ACTION_DOC, {
        ...payload,
        'actionId': ref.id,
      })
    },
    async addIngredient ({commit}, payload) {
      
      var ref = await db.collection('ingredients').doc()
      ref.set({
        ...payload,
        'ingredientId': ref.id,
      }, {merge: true})
      commit(PUSH_INGREDIENT_DOC, {
        ...payload,
        'ingredientId': ref.id,
      })
    },
    async addMeal (_, payload) {
      
      var ref = await db.collection('meals').doc()
      ref.set({
        ...payload,
        'mealId': ref.id,
      }, {merge: true})
      
    },
    async addArticle (_, payload) {
      
      var ref = await db.collection('articles').doc()
      ref.set({
        ...payload,
        'articleId': ref.id,
        'dateCreated': new Date(),
      }, {merge: true})
      
    },
    async addRoutine ({commit}, payload) {

      commit(PUSH_ROUTINE_DOC, {
        ...payload
      })

      var ref = await db.collection('routines').doc()
      ref.set({
        'routineTemplate': {...payload},
        'routineId': ref.id,
      }, {merge: true})
    },
    async editHumanAction (_, payload) {
      var ref = await db.collection('human_actions').doc(payload.actionId)
      ref.set({
        ...payload,
        'dateUpdated': new Date(),
      }, {merge: true})
    },
    async editIngredient (_, payload) {
      var ref = await db.collection('ingredients').doc(payload.ingredientId)
      ref.set({
        ...payload,
        'dateUpdated': new Date(),
      }, {merge: true})
    },
    async editMeal (_, payload) {
      var ref = await db.collection('meals').doc(payload.mealId)
      ref.set({
        ...payload,
        'dateUpdated': new Date(),
      }, {merge: true})
    },
    async editArticle (_, payload) {
      var ref = await db.collection('articles').doc(payload.articleId)
      ref.set({
        ...payload,
        'dateUpdated': new Date(),
      }, {merge: true})
    },
    async editRoutine (_, payload) {
      var ref = await db.collection('routines').doc(payload.routineId)
      ref.set({
        ...payload.update,
      }, {merge: true})
    },
    async updateCredit (_, payload) {
      var ref = await db.collection('routines').doc(payload.routineId)
      ref.set({
        'marketingContent':{
          'images':{
            'detailPage': {
              'calloutImage': {
                'credit': payload.credit
              }
            }
          }
        }
      }, {merge: true})
    },
    async updateArticleCredit (_, payload) {
      var ref = await db.collection('articles').doc(payload.articleId)
      ref.set({
        'articleCalloutImageCredit': payload.credit
      }, {merge: true})
    },

    async saveTagsToAction (_, payload) {
      var ref = await db.collection('human_actions').doc(payload.item.actionId)
      ref.set({
        'tags': payload.tags,
      }, {merge: true})
    },
    async saveTagsToIngredient (_, payload) {
      var ref = await db.collection('ingredients').doc(payload.item.ingredientId)
      ref.set({
        'tags': payload.tags,
      }, {merge: true})
    },
    async saveTagsToMeal (_, payload) {
      var ref = await db.collection('meals').doc(payload.item.mealId)
      ref.set({
        'tags': payload.tags,
      }, {merge: true})
    },
    async saveTagsToRoutine (_, payload) {
      var ref = await db.collection('routines').doc(payload.item.routineId)
      ref.set({
        'routineTemplate': {
          'tags': payload.tags,
        }
      }, {merge: true})
    },
    async saveTagsToArticle (_, payload) {
      var ref = await db.collection('articles').doc(payload.item.articleId)
      ref.set({
        'tags': payload.tags,
      }, {merge: true})
    },
    async getRoutineData (_, resourceId) {
      return new Promise(function(resolve) {
        (async () => {
          var response = null
          await db.collection('routines')
                  .doc(resourceId)
                  .get()
                  .then((doc) => {
                    if(doc.exists) {
                      response = doc.data()
                      //console.log("response from getValidCategory()", response)
                      resolve(response)
                    }
                  })
          
        })()
      })
    },
    async getArticleData (_, articleSlug) {
      return new Promise(function(resolve) {
        ( async () => { 
          var response = null
          await db.collection('articles').where('articleSlug', '==', articleSlug).limit(1).onSnapshot((snapQuery) => {
            /* eslint-disable no-console */
            //console.log("snapQuery.empty", snapQuery.empty)
            snapQuery.forEach((doc) => {
              response = doc.data()
            })
            //console.log("response", response)
            resolve(response)

          })
          
        })()
      })
    },


    resetIntentMeanCategoryListSelect ({commit}) {
      commit(RESET_INTENT_MEAN_CATEGORY_LIST_SELECT)
    },

    async getIntentMeanCategoryList ({commit}) {
      await db.collection('taxonomies').doc('v2_2021_taxonomy').collection('intent_mean_categories').onSnapshot(snap => {
        commit(RESET_INTENT_MEAN_CATEGORY_LIST)
        snap.forEach((doc) => {
          commit(PUSH_INTENT_MEAN_CATEGORY, {
            ...doc.data()
          })
        })
      })
    },
    async getHumanActionList ({commit}) {
      /*
      var ref = await db.collection('human_actions').get()         //Admin was running slow, so disabling the onSnapshot while I content load
      commit(RESET_HUMAN_ACTION_LIST)
      ref.forEach(doc => {         
        var data = doc.data()
        commit(PUSH_HUMAN_ACTION_DOC, {
          ...data
        })
      })
      */

     if(state.humanActionList.length == 0) {
        await db.collection('human_actions').onSnapshot(snap => {
          commit(RESET_HUMAN_ACTION_LIST)
          snap.forEach((doc) => {
            commit(PUSH_HUMAN_ACTION_DOC, {
              ...doc.data()
            })
          })
        })
     }
    },

    async getSequenceList({commit}) {
      var ref = await db.collection('sequences').get()
      commit(RESET_SEQUENCE_LIST)
      ref.forEach(doc => {
        var data = doc.data()
        commit(PUSH_SEQUENCE_DOC, {
          ...data
        })
      })
    },

    async getIngredientList({commit}) {
      if(state.ingredientList.length == 0) {
        await db.collection('ingredients').onSnapshot(snap => {
          commit(RESET_INGREDIENT_LIST)
          snap.forEach((doc) => {
            commit(PUSH_INGREDIENT_DOC, {
              ...doc.data()
            })
          })
        })
      }
    },
    async getMealList({commit}) {
      if(state.ingredientList.length == 0) {
        await db.collection('meals').onSnapshot(snap => {
          commit(RESET_MEAL_LIST)
          snap.forEach((doc) => {
            commit(PUSH_MEAL_DOC, {
              ...doc.data()
            })
          })
        })
      }
    },

    async getArticleList({commit}) {
      if(state.articleList.length == 0) {
        await db.collection('articles').onSnapshot(snap => {
          commit(RESET_ARTICLE_LIST)
          snap.forEach((doc) => {
            commit(PUSH_ARTICLE_DOC, {
              ...doc.data()
            })
          })
        })
      }
    },

    async getRoutineList ({state, commit}) {
      /*
      var ref = await db.collection('routines').get()         //Admin was running slow, so disabling the onSnapshot while I content load
      
      commit(RESET_ROUTINE_LIST)
      ref.forEach(doc => {         
        var data = doc.data()
        commit(PUSH_ROUTINE_DOC, {
          ...data
        })
      })
      */
                
      if(state.routineList.length == 0) {
        await db.collection('routines').onSnapshot(snap => {
          commit(RESET_ROUTINE_LIST)
          snap.forEach((doc) => {
            var data = doc.data()
            commit(PUSH_ROUTINE_DOC, {
              ...data
            })
          })
        })
      }
    },
    
    async deleteActionItem(_,payload) {
      return new Promise(function(resolve) {
        db.collection('human_actions').doc(payload.actionId).delete()
        resolve()
      })
    },
    async deleteIngredientItem(_,payload) {
      return new Promise(function(resolve) {
        db.collection('ingredients').doc(payload.ingredientId).delete()
        resolve()
      })
    },
    async deleteMealItem(_,payload) {
      return new Promise(function(resolve) {
        db.collection('meals').doc(payload.mealId).delete()
        resolve()
      })
    },
    async deleteArticleItem(_,payload) {
      return new Promise(function(resolve) {
        db.collection('articles').doc(payload.articleId).delete()
        resolve()
      })
    },
    async deleteRoutineItem(_,payload) {
      return new Promise(function(resolve) {
        db.collection('routines').doc(payload.routineId).delete()
        resolve()
      })
    },
    async changeCategoryListValue({state, commit}, payload) {
      return new Promise((resolve) => {
        //console.log("payload", payload.event)


        /* eslint-disable no-console */
        //console.log("payload", payload.event)

        if(payload.type === 'intentMean') {
          commit(SET_INTENT_MEAN_CATEGORY_LIST_SELECT, payload.event)
        } else if(payload.type === 'intentMeanForRoutine'){
          commit(SET_INTENT_MEAN_CATEGORY_LIST_SELECT_FOR_ROUTINE, payload.event)
        } else if(payload.type === 'intentMeanForArticle'){
          commit(SET_INTENT_MEAN_CATEGORY_LIST_SELECT_FOR_ARTICLE, payload.event)
        }
        


        //payload.event.forEach(function(tag) {
        //  var tag2 = tag.replace(/[:;,]/g, '')
        //  tag2 = tag2.trim()
          if(!state['intentMeanCategoryList'].includes(payload.event)) {
              db.collection('taxonomies').doc('v2_2021_taxonomy').collection('intent_mean_categories').doc(payload.event).set({'name': payload.event})
          }
        //})
            
          
        resolve()
      })
     
    },
    
      
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }