<template>
  <div class="wrapper">
    <v-card>
      <v-card-title>
        <span class="headline">Edit Tags</span>
      </v-card-title>
      <v-card-text>
        <div class="formHolder" v-if="selectedTags">

          
            <v-select
              v-bind:key="'KEY'"
              :ref="'KEY'"
              :id="'KEY'"
              chips
              multiple
              :value="selectedTags"
              :items="combinedTagList"
              label="Select action"
              type="text">
            </v-select>

            <br />
            <h2> General Tags </h2>
            <div v-for="(tag, index) in tagList" v-bind:key="index" class="chip">
              <div v-if="selectedTags.includes(tag)">
                <v-chip 
                  color="blue"
                  text-color="white"
                  @click="removeTag(tag)"
                >
                {{tag}}</v-chip>
              </div>
              <div v-else>
                <v-chip 
                  color="lightgrey"
                  @click="addTag(tag)"
                >
                {{tag}}</v-chip>
              </div>
            </div>
            <h2>Intent Tags</h2>
            <div v-for="(tag2, index) in intent_tagList" v-bind:key="`intentTag`+index" class="chip">
              <div v-if="selectedTags.includes(tag2)">
                <v-chip 
                  color="blue"
                  text-color="white"
                  @click="removeTag(tag2)"
                >
                {{tag2}}</v-chip>
              </div>
              <div v-else>
                <v-chip 
                  color="lightgrey"
                  @click="addTag(tag2)"
                >
                {{tag2}}</v-chip>
              </div>
            </div>

            <h2>Days of Week Tags</h2>
            <div v-for="(tag3, index) in daysOfWeek_tagList" v-bind:key="`dowTag`+index" class="chip">
              <div v-if="selectedTags.includes(tag3)">
                <v-chip 
                  color="blue"
                  text-color="white"
                  @click="removeTag(tag3)"
                >
                {{tag3}}</v-chip>
              </div>
              <div v-else>
                <v-chip 
                  color="lightgrey"
                  @click="addTag(tag3)"
                >
                {{tag3}}</v-chip>
              </div>
            </div>

            <h2>Nutrition Tags</h2>
            <div v-for="(tag4, index) in nutrition_tagList" v-bind:key="`nutritionTag`+index" class="chip">
              <div v-if="selectedTags.includes(tag4)">
                <v-chip 
                  color="blue"
                  text-color="white"
                  @click="removeTag(tag4)"
                >
                {{tag4}}</v-chip>
              </div>
              <div v-else>
                <v-chip 
                  color="lightgrey"
                  @click="addTag(tag4)"
                >
                {{tag4}}</v-chip>
              </div>
            </div>


          
          
          <br />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="close">Cancel</v-btn>
        <v-btn color="blue darken-1" text @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  //Some example imports you could have:
    //import Worker from "worker-loader!@/worker";
    //import feelingModule from '@/store/feeling'
    //import { mapGetters, mapActions } from 'vuex'
    //import '@/plugins/vuetify'
    //import MevChains from '@/components/MevChains'
    //import Worker from "worker-loader!../worker";
    import { mapActions } from 'vuex'
    

  export default {
    name: "SearchHumanActions",
    components: {
      
    },
    props: {
      parentView: {
        type: String
      },
      selectedItem: {
        type: Object
      },
      tagType: {
        type: String
      }
      
    },
    data () {
      return {

        routineTagList: [
          'reduce',
          'weekly',
          'daily',
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'saturday',
          'sunday',

        ],

        nutrition_tagList: [
          'antioxidants',
          'lycopene',
          'vitamin C',
          'vitamin D',
          'vitamin B6',

        ],

        daysOfWeek_tagList: [
          '2 days: MON, THU',
          '2 days: TUE, FRI',
          '2 days: WED, SAT',
          '2 days: THU, SUN',
          '3 days: MON, WED, FRI',
          '3 days: TUE, THU, SAT',
          '3 days: WED, FRI, SUN',
          '4 days: MON, TUE, THU, FRI',
          '4 days: TUE, WED, FRI, SAT',
          '4 days: WED, THU, SAT, SUN',
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'saturday',
          'sunday',
        ],

        intent_tagList: [
          'apply to graduate school, go back to school',
          'be assertive',
          'be kind, be nice, have empathy',
          'be healthier, be more fit, better self care, take better care of myself',
          'be more organized',
          'be more patient, have patience',
          'be more positive, complain less, have good energy, optimistic, be happier',
          'be more productive, have more energy',
          'be more social, outgoing, join group',
          'be less stressed, lower stress',
          'become independent',
          'build ankle strength',
          'build arm strength',
          'build back strength',
          'build better relationships',
          'build chest strength',
          'build core strength, build full-body strength',
          'build endurance, increase endurance',
          'build knee strength',
          'build lower chest',
          'build leg strength',
          'build lowerbody strength',
          'build muscle endurance, increase muscle endurance',
          'build neck strength',
          'build project, work on project, complete project tasks, finish project',
          'build shoulder strength', 
          'build strength, increase strength',
          'build upperbody strength',
          'build upper chest',
          'clean house, clean home, clean more',
          'clean toilet',
          'complain less, judge less, empathy',
          'cook more, cook healthy food',
          'do dance',
          'do meditation, meditate daily, practice meditation',
          'do yoga',
          'do tasks, achieve goal',
          'go to gym, exercise more',
          'go to school, complete degree, complete school, get a degree',
          'complete race, run marathon',
          'drink less alchohol, quit drinking',
          'drink less coffee',
          'drink less soda',
          'drink more water, drink water, improve hydration, hydrate',
          'eat healthier, eat better, eat right, eat smaller portion, reduce intake, change diet',
          'eat less meat, eat more vegtables, eat more fresh vegtables, eat fruit',
          'eat less sweets candy dessert',
          'eat less wheat, avoid gluten, eat less bread, no bread',
          'exercise more, workout more, start working out, get more exercise, lift weights, regularly, exercise everyday',
          'flatten stomach',
          'floss teach, brush teeth better',
          'go to bed earlier',
          'get better job, find job',
          'get out of house, get out more, leave house more',
          'grow food, grow a garden',
          'increase muscle size, increase muscle hypertrophy, increase muscle mass',
          'improve cardio',
          'improve confidence, increase confidence',
          'improve heart health, healthy heart',
          'improve joint health, improve joints, reduce joint pain',
          'improve lung health, healthy lungs',
          'improve mood, mental health, clear mind',
          'improve posture',
          'learn art, do handcraft',
          'learn new language, learn spanish, french',
          'learn new skill, learn something new',
          'learn to code, study programming',
          'learn to play guitar, music',
          'lose weight, healthy weight, tone body',
          'make bed, clean change sheets',
          'make money, make more money',
          'pay debt, pay credit cards, pay towards debt, reduce debt',
          'play piano, take piano lessons',
          'read more, reading, read book',
          'reduce carbs, eat more protein, eat less carbs carbohydrates',
          'reduce smoking, smoke less, reduce smoking, quit smoking, smoke fewer cigarettes, stop smoking',
          'repair home, fix home',
          'research ebay listings',
          'research sportscards, sports cards investing,',
          'ride my bike, cycling, bike riding',
          'run more, running, go jogging, improve cardio, jog more',
          'save more money, save money',
          'save for car, buy a car',
          'save for home, buy a house',
          'save for retirement, retire early',
          'sell home',
          'sleep better, sleep more, get better sleep, sleep more consistently, keep sleeping schedule',
          'start business, make a business plan',
          'start new hobby',
          'start sewing',
          'stop procrastinating',
          'strong stronger bones, incease bone strength, build strong bones, bone strength',
          'study more',
          'take breath, breathe deeply, improve breathing',
          'volunteer more, help people, help poor person',
          'walk, walk more, walking everyday',
          'wake up earlier, feel better',
          'write more, write a book, story',


        

        ],


        tagList: [
          'aerobic',
          'anaerobic',
          'anterior deltoid',
          'abs',
          'arms',
          'back',
          'balance',
          'biceps',
          'bodyweight',
          'build muscles',
          'calisthenics',
          'calves',
          'cardio',
          'chest',
          'blood circulation',
          'compound',
          'coordination',
          'core',
          'daily',
          'deltoids',
          'dumbbell',
          'endorphin release',
          'endurance',
          'fat loss',
          'featured',
          'flexability',
          'forearms',
          'friday',
          'fri',
          'full-body workout',
          'good fat',
          'glutes',
          'grip',
          'growth hormone secretion',
          'hamstring',
          'heart health',
          'hinge',
          'hips',
          'hip-dominant',
          'home gym',
          'horizontal push',
          'hortizontal pull',
          'hydration',
          'isometric',
          'kettlebell',
          'knee',
          'knee-dominant',
          'lateral deltoid',
          'lats',
          'legs',
          'less',
          'lower back',
          'lower body',
          'lower blood pressure',
          'lunge',
          'mobility',
          'monday',
          'mon',
          'more',
          'needs equipment',
          'obliques',
          'omega-3',
          'pecs',
          'pectoralis',
          'power',
          'press',
          'pull',
          'push',
          'quadriceps',
          'rotator cup',
          'rhomboids',
          'reduce',
          'reduce inflammation',
          'reduce triglycerides',
          'rotate',
          'saturday',
          'sat',
          'shoulders',
          'squat',
          'speed',
          'stability',
          'start',
          'stop',
          'strength',
          'sunday',
          'sun',
          'supplement',
          'tabata',
          'thursday',
          'thu',
          'traps',
          'triceps',
          'tuesday',
          'tue',
          'upper body',
          'vertical pull',
          'vertical push',
          'wednesday',
          'wed',
          'weekly',
        ],
        selectedTags: null,

        
        
      }
    },
    computed: {
      combinedTagList () {
        var alist = this.tagList.concat(this.intent_tagList)
        return alist.concat(this.daysOfWeek_tagList)
      }
    },
    watch: {
      selectedItem: {
        deep: true,
        immediate: true,
        handler: async function (newVal) {

          

          if(this.tagType && newVal) {
            if(this.tagType === 'humanAction') {
              this.selectedTags = newVal.tags
            }else if(this.tagType === 'ingredient') {
              this.selectedTags = newVal.tags
            }else if(this.tagType === 'meal') {
              this.selectedTags = newVal.tags
            }else if(this.tagType === 'article') {
              this.selectedTags = newVal.tags
            }else{
              this.selectedTags = newVal.routineTemplate.tags
            }
          }
          
            
          
        }
      },
    },
    methods: {
      ...mapActions('admin', [
        'saveTagsToAction',
        'saveTagsToRoutine',
        'saveTagsToIngredient',
        'saveTagsToMeal',
      ]),
      
      
      
      
      addTag(tag) {
        this.selectedTags.push(tag)
      },
      removeTag(tag) {
        var i = this.selectedTags.findIndex(t => t == tag)
        this.selectedTags.splice(i,1)
      },
      close() {
        //this.selectedTags = null
        this.$emit("close")
      },
      save() {
        var obj = {
          'tags': this.selectedTags,
          'item': this.selectedItem,
        }
        if(this.tagType == 'humanAction') {
          this.saveTagsToAction(obj)
        }else if(this.tagType == 'routine') {
          this.saveTagsToRoutine(obj)
        }else if(this.tagType == 'meal') {
          this.saveTagsToMeal(obj)
        }else if(this.tagType == 'article') {
          this.saveTagsToArticle(obj)
        }else if(this.tagType == 'ingredient') {
          this.saveTagsToIngredient(obj)
        }
        
        this.close()
      }
      
      
      
      
      

    },
    beforeCreate () {
      
      
    },
    async created () {
      


    
    },
    mounted () {
      
        
      
        
      
      
    },
    updated () {
      
    }
  }
</script>

<style scoped>
.wrapper {
  width: 100%;
}
a {
  color: inherit;
  text-decoration: none;
}
.card {
  padding: 20px;
  min-height: 500px;
  color: black;
}

.chip {
  display: inline-block;
  padding: 2px;
}



</style>