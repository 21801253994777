<template>
  <div class="adminConsole" id="adminConsole">
    <v-app id="vApp">

      <div class="adminHeader">
        <div class="headerNavItem">
          <br />
          <router-link to="/admin"><b>Admin Home</b></router-link>
          <br>
        </div>
        <div class="headerNavItem">
          <br />
          <router-link to="/admin/ingredients/">Ingredients</router-link>
          <br>
        </div>
        <div class="headerNavItem">
          <br />
          <router-link to="/admin/meals/">Meals</router-link>
          <br>
        </div>
        <div class="headerNavItem">
          <br />
          <router-link to="/admin/actions/">Human Actions</router-link>
          <br>
        </div>
        <div class="headerNavItem">
          <br />
          <router-link to="/admin/sequences/">Sequences</router-link>
          <br>
        </div>
        <div class="headerNavItem">
          <br />
          <router-link to="/admin/routines/">Routines</router-link>
          <br>
        </div>
      </div>

      
      <v-card flat>
        <div class="contentContainer" >
          <!-- ADD ARTICLE DIALOG -->
          <v-dialog v-model="addArticleDialog" persistent max-width="700px" scrollable>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                @click="addArticleClick"
              >
                Create Article
              </v-btn>
            </template>
            
            <v-card v-if="newArticle">
              <v-card-title>
                <span class="headline">Add Article</span>
              </v-card-title>
              <v-card-text>
                <div class="formHolder">
                  <div>
                    <v-text-field 
                      label="Headline"
                      v-model="newArticle.articleHeadline"
                      required
                      v-bind:key="`articleHeadline`"
                      ></v-text-field>
                  </div>
                  <div>
                    <v-text-field 
                      label="Subheadline"
                      v-model="newArticle.articleSubheadline"
                      required
                      v-bind:key="`articleSubheadline`"
                      ></v-text-field>
                  </div>
                  
                  <div>
                    <v-select
                      :items="articleTypeItems"
                      label="Article type*"
                      v-model="newArticle.articleType"
                      @change="e => changeArticleTypeSelect(e)"
                      required
                      v-bind:key="'articleType'"
                    ></v-select>
                  </div>
                  <div>
                    <v-select
                      :items="articleOrderNumbers"
                      label="Article order number*"
                      v-model="newArticle.articleListOrderNumber"
                      @change="e => changeArticleOrderNumberSelect(e)"
                      required
                      v-bind:key="'articleType'"
                    ></v-select>
                  </div>
                  <div>
                    <v-combobox
                      :value="intentMeanCategoryListSelectForArticle"               
                      :items="intentMeanCategoryList"
                      v-model="newArticle.articleIntentMeanCategory"
                      @change="changeTheCategoryListValue('intentMeanForArticle', $event)"
                      label="Select an intent mean category, or add a new one"
                      v-bind:key="'intentMeanCategoryListForArticles'"
                    ></v-combobox>
                  </div>
                  <div>
                    isFeatured?
                    <v-switch v-model="newArticle.isFeatured" v-bind:key="'isFeatured'"></v-switch> 
                  </div>
                  <div>
                    isPublished?
                    <v-switch v-model="newArticle.isPublished" v-bind:key="'isPublished'"></v-switch> 
                  </div>
                  <div>
                    <v-text-field
                      v-if="newArticle.articleType != `cta`" 
                      label="Summary"
                      v-model="newArticle.articleSummary"
                      required
                      v-bind:key="`articleSummary`"
                      ></v-text-field>
                  </div>
                  <div>
                    <v-textarea
                      v-if="newArticle.articleType != `cta`"
                      label="Body"
                      v-model="newArticle.articleBody"
                      required
                      auto-grow
                      ></v-textarea>
                  </div>
                  <div>
                    <v-text-field 
                      v-if="newArticle.articleType === `cta`"
                      label="CTA route"
                      v-model="newArticle.articleRoute"
                      required
                      v-bind:key="`articleRoute`"
                      ></v-text-field>
                  </div>
                  <div>
                    <v-text-field
                      v-if="newArticle.articleType != `cta`"
                      label="Article CTA Label"
                      v-model="newArticle.articleCtaLabel"
                      required
                      v-bind:key="`articleCTALabel`"
                      ></v-text-field>
                  </div>
                  <div>
                    <v-text-field 
                      v-if="newArticle.articleType != `cta`"
                      label="Article CTA Url"
                      v-model="newArticle.articleCtaUrl"
                      required
                      v-bind:key="`articleCtaUrl`"
                      ></v-text-field>
                  </div>

                    
                </div>
                
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="addArticleDialog = false;">Close</v-btn>
                <v-btn color="blue darken-1" text @click="handleAddArticle">Add</v-btn>
              </v-card-actions>
            </v-card>


          </v-dialog>

          <!-- EDIT ARTICLE DIALOG -->
          <v-dialog v-model="editArticleDialog" persistent max-width="700px" scrollable>

            
            <v-card v-if="selectedArticleItem">
              <v-card-title>
                <span class="headline">Edit Article</span>
              </v-card-title>
              <v-card-text>
                <div class="formHolder">
                  <div>
                    <v-text-field 
                      label="Headline"
                      v-model="selectedArticleItem.articleHeadline"
                      required
                      v-bind:key="`articleHeadline`"
                      ></v-text-field>
                  </div>
                  <div>
                    <v-text-field 
                      label="Subheadline"
                      v-model="selectedArticleItem.articleSubheadline"
                      required
                      v-bind:key="`articleSubheadline`"
                      ></v-text-field>
                  </div>
                  
                  <div>
                    <v-select
                      :items="articleTypeItems"
                      label="Article type*"
                      v-model="selectedArticleItem.articleType"
                      @change="e => changeArticleTypeSelectForEdits(e)"
                      required
                      v-bind:key="'articleType'"
                    ></v-select>
                  </div>
                  <div>
                    <v-select
                      :items="articleOrderNumbers"
                      label="Article order number*"
                      v-model="selectedArticleItem.articleListOrderNumber"
                      @change="e => changeArticleOrderNumberSelectForEdits(e)"
                      required
                      v-bind:key="'articleType'"
                    ></v-select>
                  </div>
                  <div>
                    <v-combobox
                      :value="intentMeanCategoryListSelectForArticle"               
                      :items="intentMeanCategoryList"
                      v-model="selectedArticleItem.articleIntentMeanCategory"
                      @change="changeTheCategoryListValueForEdits('intentMeanForArticle', $event)"
                      label="Select an intent mean category, or add a new one"
                      v-bind:key="'intentMeanCategoryListForArticles'"
                    ></v-combobox>
                  </div>
                  <div>
                    isFeatured?
                    <v-switch v-model="selectedArticleItem.isFeatured" v-bind:key="'isFeatured'"></v-switch> 
                  </div>
                  <div>
                    isPublished?
                    <v-switch v-model="selectedArticleItem.isPublished" v-bind:key="'isPublished'"></v-switch> 
                  </div>
                  <div>
                    <v-text-field 
                      v-if="selectedArticleItem.articleType != `cta`"
                      label="Summary"
                      v-model="selectedArticleItem.articleSummary"
                      required
                      v-bind:key="`articleSummary`"
                      ></v-text-field>
                  </div>
                  <div>
                    <v-textarea
                      v-if="selectedArticleItem.articleType != `cta`"
                      label="Body"
                      v-model="selectedArticleItem.articleBody"
                      required
                      auto-grow
                      ></v-textarea>
                  </div>
                  <div>
                    <v-text-field 
                      v-if="selectedArticleItem.articleType === `cta`"
                      label="CTA route"
                      v-model="selectedArticleItem.articleRoute"
                      required
                      v-bind:key="`articleRoute`"
                      ></v-text-field>
                  </div>
                  <div>
                    <v-text-field 
                      v-if="selectedArticleItem.articleType != `cta`"
                      label="Article CTA Label"
                      v-model="selectedArticleItem.articleCtaLabel"
                      required
                      v-bind:key="`articleCTALabel`"
                      ></v-text-field>
                  </div>
                  <div>
                    <v-text-field 
                      v-if="selectedArticleItem.articleType != `cta`"
                      label="Article CTA Url"
                      v-model="selectedArticleItem.articleCtaUrl"
                      required
                      v-bind:key="`articleCtaUrl`"
                      ></v-text-field>
                  </div>


                  
                    
                </div>
                <small>*indicates required field</small>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="editArticleDialog = false;">Close</v-btn>
                <v-btn color="blue darken-1" text @click="handleEditArticle">Save</v-btn>
              </v-card-actions>
            </v-card>


          </v-dialog>

          <!-- EDIT IMAGE DIALOG -->
          <v-dialog v-model="editArticleImageDialog" persistent max-width="700px">
            <v-card>
              <v-card-title>
                <span class="headline">Edit Image</span>
              </v-card-title>
              <v-card-text>
                
                <v-list
                  subheader
                  two-line
                >

                  <v-subheader>Article Images</v-subheader>

                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>Callout Image</v-list-tile-title>
                      <v-list-tile-sub-title>(add callout image spec requirements here)</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <div>
                    <div class="picIconHolder" v-if="selectedArticleItem">
                      
                      <div class="image-upload">
                        <label @click="$refs.inputUpload2_callout.click()" id="label2_callout">
                          <div v-if="selectedArticleItem.articleCalloutImageUrl">
                            <img :src="selectedArticleItem.articleCalloutImageUrl" width="50px">
                          </div>
                          <div v-else>
                            Callout Image<br /><i class="fas fa-camera"></i>
                          </div>
                        </label>
                        <input v-show="false" id="file-input2-callout" ref="inputUpload2_callout" type="file" @change="uploadImg({'type': 'article-callout'})" />
                      </div>
                    
                    </div>
                    <div v-if="selectedArticleItem">
                      <div v-if="selectedArticleItem.articleCalloutImageUrl">
                          <v-text-field 
                            label="Photo Credit"
                            v-model.lazy="selectedArticleItem.articleCalloutImageCredit"
                            required
                            @change="updateTheCredit(selectedArticleItem.articleId)"
                            ></v-text-field>
                        </div>
                    </div>
                  </div>
                  <br>

                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>Content Images</v-list-tile-title>
                      <v-list-tile-sub-title>(add hero image spec requirements here)</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <div>
                    <div class="picIconHolder" v-if="selectedArticleItem">
                      
                      <div class="image-upload">
                        <label @click="$refs.inputUpload1_content1.click()" id="label1_content1">
                          <div v-if="selectedArticleItem.articleContentImage1">
                            <img :src="selectedArticleItem.articleContentImage1" width="50px">
                          </div>
                          <div v-else>
                            Content 1<br /><i class="fas fa-camera"></i>
                          </div>
                        </label>
                        <input v-show="false" id="file-input1-content1" ref="inputUpload1_content1" type="file" @change="uploadImg({'type': 'article-content1'})" />
                      </div>
                    </div>
                    <div class="picIconHolder" v-if="selectedArticleItem">
                      
                      <div class="image-upload">
                        <label @click="$refs.inputUpload2_content2.click()" id="label2_content2">
                          <div v-if="selectedArticleItem.articleContentImage2">
                            <img :src="selectedArticleItem.articleContentImage2" width="50px">
                          </div>
                          <div v-else>
                            Content 2<br /><i class="fas fa-camera"></i>
                          </div>
                        </label>
                        <input v-show="false" id="file-input2-content2" ref="inputUpload2_content2" type="file" @change="uploadImg({'type': 'article-content2'})" />
                      </div>
                    </div>
                  </div>
                  <br>
                </v-list>

              
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="editArticleImageDialog = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>


          <!-- EDIT TAGS DIALOG -->
          <v-dialog v-model="editTagsDialog" persistent max-width="1000px" scrollable>
        
                  
                    <TagManager 
                      :selected-item="selectedArticleItem" 
                      v-on:close="closeTagsDialog"
                      :tag-type="`article`"
                      />

              
                
                
          </v-dialog>


          <br />
          <div class="searchRoutinesHolder">
            <div class="searchRoutines">
              <v-text-field
                v-model="searchArticles"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </div>
          </div>
          <br />
          <v-data-table
            :headers="dataHeaders"
            :search="searchArticles"
            :items="articleList"
            item-key="articleHeadline"
            class="elevation-1"
            :pagination.sync="pagination"
          >
            
            
            <template v-slot:items="props">
              <tr @click="props.expanded = !props.expanded">
                
                <td style="padding:15px;text-align: center;"><img :src="props.item.articleCalloutImageUrl" class="icon" v-if="props.item.articleCalloutImageUrl"></td>
                <td> {{ props.item.articleHeadline }}</td>
                <td class="text-xs-right">{{ props.item.articleType }}</td>
                <td class="text-xs-right">{{ props.item.articleListOrderNumber }}</td>
                <td class="text-xs-right">{{ props.item.isFeatured }}</td>
                <td class="text-xs-right">{{ props.item.isPublished }}</td>
                <td class="text-xs-right">
                  &nbsp;&nbsp;&nbsp;
                  
                  <i class="fas fa-hashtag" @click="editTags(props.item)"></i>
                  &nbsp;&nbsp;&nbsp;
                  <i class="fas fa-camera" @click="editImage(props.item)"></i>
                  &nbsp;&nbsp;&nbsp;
                  <i class="fas fa-pencil-alt" @click="editThisArticleItem(props.item)"></i>
                  &nbsp;&nbsp;&nbsp;
                  <i class="fas fa-trash-alt" @click="deleteThisArticleItem(props.item)"></i>
                  
                </td>
              </tr>
            </template>
            
          </v-data-table>



        </div>
      </v-card>
    </v-app>
  </div>
</template>

<script>
    import '@/plugins/vuetify'
    import firebase from '@/firebase'
    import cmsModule from '@/store/cms'
    import signinModule from '@/store/signin'
    import userModule from '@/store/user'
    import adminModule from '@/store/admin'
    import experienceModule from '@/store/experience'
    //import RoutineOverlayAdmin from '@/components/RoutineOverlayAdmin'
    import TagManager from '@/components/TagManager'
    import settingsModule from '@/store/settings'
    import db from '@/db'
    
    //import NextStepper from '@/components/NextStepper'
    
    import { mapGetters, mapActions } from 'vuex'
    //import * as use from '@tensorflow-models/universal-sentence-encoder';

  export default {
    name: "admin",
    components: {
      //RoutineOverlayAdmin,
      TagManager
    },
    data () {
      return {

        addArticleDialog: false,
        editTagsDialog: false,
        editArticleImageDialog: false,
        editArticleDialog: false,

        newArticle: {
          articleBody: null,
          articleCtaUrl: null,
          articleCtaLabel: null,
          articleDate: null,
          articleHeadline: null,
          articleId: null,
          articleCalloutImageUrl: null,
          articleCalloutImageCredit: null,
          articleContentImage1: null,
          articleContentImage2: null,
          articleIntentMeanCategory: null,
          articleListOrderNumber: 1,
          articleRoute: null,
          articleSlug: null,
          articleSubheadline: null,
          articleSummary: null,
          articleType: null,
          isFeatured: false,
          isPublished: false,
          tags: [],
        },

        articleTypeItems: [
          'news',
          'post',
          'video',
          'testimonial',
          'cta',
        ],


        articleOrderNumbers: [
          1,
          2,
          3,
          4,
          5,
          6
        ],

        selectedArticleItem: null,

        dataHeaders: [
          { text: 'Image', align: 'start', value: 'articleImage' },
          {
            text: 'Article headline',
            align: 'left',
            sortable: false,
            value: 'articleHeadline',
          },
          { text: 'Type', align: 'right', value: 'articleType' },
          { text: 'List Order #', align: 'right', value: 'articleListOrderNumber' },
          { text: 'Featured?', align: 'right', value: 'isFeatured' },
          { text: 'Published?', align: 'right', value: 'isPublished' },
          { text: 'Actions', align: 'right', value: 'actions', sortable: false },
          
        ],

        searchArticles: '',
        pagination: {
          rowsPerPage: 10
        },




      }
    },
    computed: {
      ...mapGetters('cms', [
        'checkInFeelingContent',
        'checkInThoughtContent'
      ]),
      ...mapGetters('auth', {
        profile: 'profile',
        loggedIn: 'loggedIn',
      }),
      ...mapGetters('admin', [
        'intentMeanCategoryList',
        'intentMeanCategoryListSelect',
        'intentMeanCategoryListSelectForArticle',
        'articleList',
        
      ]),


      




    },
    methods: {
      
      ...mapActions('user', [
        'getUserState',
        'updateNotificationHistory'
      ]),

      ...mapActions('admin', [
        'addArticle',
        'deleteArticleItem',
        'editArticle',
        'getArticleList',
        'updateArticleCredit',
        'getIntentMeanCategoryList',
        'changeCategoryListValue',
      ]),


      /*
      ...mapActions('admin', [
        'getIntentMeanCategoryList',
        'setIntentMeanCategoryListSelect',
        'setIntentMeanCategoryListSelectForRoutine',
        'resetIntentMeanCategoryListSelect',
        'resetIntentMeanCategoryListSelectForRoutine',
        'addHumanAction',
        'addRoutine',
        'getHumanActionList',
        'getRoutineData',
        'getRoutineList',
        'editHumanAction',
        'editRoutine',
        'deleteActionItem',
        'deleteRoutineItem',
        'changeCategoryListValue',
        'updateCredit'
      ]),

      ...mapActions('signin', [
        'googleLogin',
        'anonymousLogin',
        'autoSignIn'
      ]),
      ...mapActions('auth', [
        'setAutoSignInFlag',
        'setProfile',
        'setAnonymousProfile'
      ]),

      */


      addArticleClick () {
        this.addArticleDialog = true
        this.resetArticle()
      },
      resetArticle () {
        this.newArticle = {
          articleBody: null,
          articleCtaUrl: null,
          articleCtaLabel: null,
          articleDate: null,
          articleHeadline: null,
          articleId: null,
          articleCalloutImageUrl: null,
          articleCalloutImageCredit: null,
          articleContentImage1: null,
          articleContentImage2: null,
          articleIntentMeanCategory: null,
          articleListOrderNumber: 1,
          articleRoute: null,
          articleSlug: null,
          articleSubheadline: null,
          articleSummary: null,
          articleType: null,
          isFeatured: false,
          isPublished: false,
          tags: [],
        }
      },


      changeArticleTypeSelect(event) {
        this.newArticle.articleType = event
      },
      changeArticleTypeSelectForEdits(event) {
        this.selectedArticleItem.articleType = event
      },
      changeArticleOrderNumberSelect(event) {
        this.newArticle.articleListOrderNumber = parseInt(event)
      },
      changeArticleOrderNumberSelectForEdits(event) {
        this.selectedArticleItem.articleListOrderNumber = parseInt(event)
      },

      changeTheCategoryListValue(type, event) {
        var obj = {
          'type': type,
          'event': event
        }
        this.newArticle.articleIntentMeanCategory = event
        this.changeCategoryListValue(obj)
      },
      changeTheCategoryListValueForEdits(type, event) {
        var obj = {
          'type': type,
          'event': event
        }
        this.selectedArticleItem.articleIntentMeanCategory = event
        this.changeCategoryListValue(obj)
      },
      handleAddArticle() {
        this.addArticleDialog = false
        //var newArticle = this.createNewArticle()

        //make article slug from articleHeadline
        var articleSlug = null
        articleSlug = this.newArticle.articleHeadline.replace(/[^\w\s]|_/g, "").replace(/\s+/g, " ");
        articleSlug = articleSlug.replace(/\s+/g, '-').toLowerCase();
        this.newArticle.articleSlug = articleSlug

        //build route
        if(this.newArticle.articleType != 'cta') {
          this.newArticle.articleRoute = `/muse/` + articleSlug
        }

        this.addArticle(this.newArticle)
        this.resetArticle()
      },
      
      editTags(item) {
        this.selectedArticleItem = item
        this.$nextTick(() => {
          this.editTagsDialog = true
        })
        
      },
      editImage(item) {
        this.selectedArticleItem = item
        this.$nextTick(()=>{
          this.editArticleImageDialog = true
        })
      },
      editThisArticleItem(item) {
        this.selectedArticleItem =  {
          ...item
        } 
        this.editArticleDialog = true
      },
      deleteThisArticleItem(item) {
        this.deleteArticleItem(item)
      },
      closeTagsDialog() {
        this.editTagsDialog = false
      },
      handleEditArticle() {
        //this.addActionDialog = false
        this.editArticleDialog = false
        //var newArticle = this.createEditArticle()
        this.editArticle(this.selectedArticleItem)
      },

      updateTheCredit (id) {
        var obj = {
          'articleId': id,
          'credit': this.selectedArticleItem.articleCalloutImageCredit,
        }
        this.updateArticleCredit(obj)

      },
      




      async uploadImg(payload) {

        var type = payload.type
        //var iconColor = (payload.iconColor) ? payload.iconColor : null;

        if(event.target.files[0] == "") {return}
        var storageRef = firebase.storage().ref();
        //var imageName = null
        //var folderName = null
        var uploadTask = null
        var metadata = null
        
        this.imageData = event.target.files[0]
        
        //compress
        const width = 500
        const reader = new FileReader()
        
        
        reader.readAsDataURL(this.imageData)
        reader.onload = async (event) => {
          const img = new Image()
          img.src = event.target.result
          img.onload = async () => {
            const scaleFactor = width / img.width
            const elem = document.createElement('canvas')
            elem.width = width
            elem.height = scaleFactor * img.height
            const ctx = elem.getContext('2d')
            ctx.drawImage(img, 0, 0, width, scaleFactor*img.height)
            ctx.canvas.toBlob(async(blob) => {
              // imageName = (type == 'humanAction') ? 'actionIconImg' : 'routineIconImg'
              //folderName = (iconColor == 'black') ? '_black' : '_grey'
              metadata = { 'contentType': 'image/jpg'};

              if(type === 'article-callout') {
                uploadTask = storageRef.child(`heediArticleCalloutImages/${this.selectedArticleItem.articleSlug}`)
                                      //.put(this.imageData, metadata)
                                      .put(blob, metadata)
              }else if(type === 'article-content1') {
                uploadTask = storageRef.child(`heediArticleContentImages/${this.selectedArticleItem.articleSlug}-1`)
                                      //.put(this.imageData, metadata)
                                      .put(blob, metadata)
              }else if(type === 'article-content2') {
                uploadTask = storageRef.child(`heediArticleContentImages/${this.selectedArticleItem.articleSlug}-2`)
                                      //.put(this.imageData, metadata)
                                      .put(blob, metadata)
              }
              

              uploadTask.on(`state_changed`, async (snapshot) => {
                //this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                    //console.log('Upload is paused');
                    break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                    //console.log('Upload is running');
                    break;
                }
              },async (error) => {
                /* eslint-disable no-console */
                console.log(error.message)
              },async () => {
                  //this.uploadValue=100;
                  uploadTask.snapshot.ref.getDownloadURL().then(async(url) => {
                    var ref = await db.collection('articles').doc(this.selectedArticleItem.articleId)
                    if(type == `article-callout`) {
                      
                
                      
                        document.getElementById("file-input2-callout").value = "";
                        await ref.set({
                            'articleCalloutImageUrl': url,

                        }, {merge: true})
                      

                      /* eslint-disable no-console */
                      console.log('File available at', url);
                      this.editArticleImageDialog = false


                    }else if(type == `article-content1`) {
                      //var ref = await db.collection('articles').doc(this.selectedArticleItem.articleId)
                
                      
                        document.getElementById("file-input1-content1").value = "";
                        await ref.set({
                            'articleContentImage1': url,

                        }, {merge: true})
                      

                      /* eslint-disable no-console */
                      console.log('File available at', url);
                      this.editArticleImageDialog = false


                    }else if(type == `article-content2`) {
                      //var ref = await db.collection('articles').doc(this.selectedArticleItem.articleId)
                
                      
                        document.getElementById("file-input2-content2").value = "";
                        await ref.set({
                            'articleContentImage2': url,

                        }, {merge: true})
                      

                      /* eslint-disable no-console */
                      console.log('File available at', url);
                      this.editArticleImageDialog = false


                    }

                  })
                },
              );
            }, this.imageData.type, 1)

          }
        }
        reader.onerror = (error) => {
          /* eslint-disable no-console */
          console.log(error)
        }
      },
      

    },
    beforeCreate () {
      if(!this.$store.state.cms) this.$store.registerModule('cms', cmsModule)
      if(!this.$store.state.user) this.$store.registerModule('user', userModule)
      if(!this.$store.state.signin) this.$store.registerModule('signin', signinModule)
      if(!this.$store.state.admin) this.$store.registerModule('admin', adminModule)
      if(!this.$store.state.experience) this.$store.registerModule('experience', experienceModule)
      if(!this.$store.state.settings) this.$store.registerModule('settings', settingsModule)
    },
    created () {
      //this.resetCheckIn()
      //gtag('config', 'G-H9RD8W4MLR', {'page_path': '/admin'});
      //this.autoSignIn() //Not having autoSignIn is what keeps people out of the admin area
      this.getArticleList()
      this.getIntentMeanCategoryList()
      

      


      
    },
    async mounted () {
      
      
    },
    updated () {

    },
    watch: {

    }
  }
</script>

<style scoped>

.searchRoutinesHolder {
  text-align: right;
  width: 100%;
  padding-right: 10px;
  background-color: transparent;
}
.searchRoutines {
  display: inline-block;
  width: 300px;
  background-color: transparent;
}

.adminConsole {
  margin: 0 auto;  /* this centers the div on the page */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

#vApp {
 background-color: transparent;
}

.icon {
  max-width: 50px;
  max-height: 40px;
}


.contentContainer {
  max-width: 1000px;
  margin: 0 auto;
  min-height: 500px;
  padding-bottom: 100px;
}
.routineType {
  border: 1px solid grey;
  border-radius: 8px;
  margin: 10px;
  padding: 20px;
}
.formHolder {
  padding-right: 30px;
  padding-left: 30px;
}

.picIconHolder {
  display: inline-block;

  font-size: 1.3em;
  color: rgb(73, 73, 73);
  width: 100px;
  background-color: white;
  margin: 0 auto;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  padding: 5px;
  border: solid 1px grey;

}

.adminHeader {
  background-color: white;
  text-align: center;
  padding-bottom: 20px;
}
.headerNavItem {
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
  padding: 7px;
}
</style>
