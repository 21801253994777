<template>
  <div class="search-wrapper">
    
      <v-form ref="form">
        <v-text-field
          v-bind:key="'KEY'+thisIndex"
          :ref="'KEY'+thisIndex"
          :id="'mySearch'+thisIndex"
          label="Select ingredient"
          clearable
          type="text"
          v-on:keyup="myFunction" 
          v-on:keydown="initSearchPanel">
        </v-text-field>
      </v-form>
    
    
    <div class="listHolder"  v-if="showSearchPanel">
      <ul id="search_ingredientList"  v-if="showSearchPanel">
        <li v-for="(ingredient, index) in ingredientList" v-bind:key="'ingredientSearchResult'+index" :id="'ingredientSearchResult'+index">
          <div class="itemWrapper">
            <div class="ingredientSearchItem" @click="addIngredient(ingredient)">

                <div class="searchIcon" :id="`searchIcon`+index">
                  <img :src="ingredient.ic" width="40px">
                </div>
                <div class="searchTitle" :id="`searchTitle`+index">
                  <span>{{ (ingredient.n) ? ingredient.n : "null"}} </span>
                </div>
                
                <span class="noSee">
                  {{ (ingredient.t) ? ingredient.t : ""}} 
                  {{ (ingredient.typ) ? ingredient.typ : ""}}
                </span>
            </div>
          </div>
          <!-- <router-link :to="'/category/' + item.mc">   uncomment to enable production version
          <router-link :to="'/evolve/'+item.mc">
            <b>{{ item.mc }}</b>&nbsp;&nbsp;<i class="fas fa-arrow-right fa-xs"></i>&nbsp;&nbsp;&nbsp;<span class="ends" v-for="(i, k) in item.re" v-bind:key="k"> {{ i }}&nbsp;&nbsp;&nbsp;</span>
          </router-link>
          -->
        </li>
      </ul>
    </div>


  </div>
</template>

<script>
  //Some example imports you could have:
    //import Worker from "worker-loader!@/worker";
    //import feelingModule from '@/store/feeling'
    //import { mapGetters, mapActions } from 'vuex'
    //import '@/plugins/vuetify'
    //import MevChains from '@/components/MevChains'
    import Worker from "worker-loader!../worker";
    

  export default {
    name: "SearchIngredients",
    components: {
      
    },
    props: {
      parentView: {
        type: String
      },
      thisIndex: {
        type: Number
      },
      number: {
        type: Number
      },
      periodKey: {
        type: String
      }
    },
    data () {
      return {
        showSearchPanel: false,
        ingredientList: [],
        searchString: null,
        searchIngredientItemStatusArray: [],

        routineSchedules: {},
        daysOfWeekArray: ['M', 'T', 'W', 'T', 'F', 'S', 'S']
        
      }
    },
    computed: {
      
    },
    watch: {

    },
    methods: {
      
      
      
      initSearchPanel () {
        this.showSearchPanel=true;
        return;
      },
      async initializeStatuses () {
        return new Promise((resolve) => {
          if(this.ingredientList.length > 0) {
            var searchIngredientItemStatusArray = []
            this.ingredientList.forEach(async () => {   
              searchIngredientItemStatusArray.push('false')
            })
            this.searchIngredientItemStatusArray = searchIngredientItemStatusArray
            resolve()
          }
        })
      },
      addIngredient(ingredient) {
        var obj = {
          'ingredient': ingredient,
          'index': this.thisIndex,
        }

        this.showSearchPanel = false
        
        this.$emit("ingredient-selected", obj)
        this.$refs.form.reset()

      },
      myFunction2 () {



      },
      myFunction () {
        var input, filter, ul, li, i
        input = document.getElementById("mySearch"+this.thisIndex);
        if(input.value.length <= 0) { this.showSearchPanel=false; return;}
        

        filter = input.value.toUpperCase();
        ul = document.getElementById("search_ingredientList");
        li = ul.getElementsByTagName("li");


        for (i = 0; i < li.length; i++) {

          var spans = li[i].getElementsByTagName("span")
          if ((spans[0].innerHTML.toUpperCase().indexOf(filter) > -1) || (spans[1].innerHTML.toUpperCase().indexOf(filter) > -1)) {
            li[i].style.display = "";
          } else {
            li[i].style.display = "none";
          }
        
        }
      },
      
      async fetchTheData () {
        return new Promise((resolve) => {
          //first try to fetch from cache

          //use a web worker
          const workerA = new Worker()
          workerA.postMessage({cmd: "getIngredients"})
          workerA.onmessage = e => {
            var ingredientList = JSON.parse(e.data.payload)
            for (var key  in ingredientList) {
              this.ingredientList.push(ingredientList[key])
              this.searchIngredientItemStatusArray.push(false)
            } 
          }

          resolve()
        })
      },
      
      

    },
    beforeCreate () {
      
      
    },
    async created () {
      await this.fetchTheData();
      this.initializeStatuses()


    
    },
    mounted () {
      
    },
    updated () {
      
    }
  }
</script>

<style scoped>
.wrapper {
  width: 100%;
}
a {
  color: inherit;
  text-decoration: none;
}
.card {
  padding: 20px;
  min-height: 500px;
  color: black;
}
.noSee {
  display: none;
}
.fade-enter-active, .fade-leave-active {
  transition: all .1s ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-10%);
}

.search_wrapper {
  width: 100%;
  background-color: white;
  

}
/* Style the search box */
#mySearch {
  /* display: block; */
  right: 2%;
  width: 96%;
  max-width: 400px;
  font-size: 18px;
  padding: 11px;
  border: 1px solid #ddd;
  background-color: rgb(210, 235, 255);
}

#searchLabel {
  /* margin-left: 7px; */
  font-size: 1.5em;
  padding-top: 35px;
  padding-bottom: 9px;
  padding-right: 30px;
  padding-left: 30px;
}

.searchFieldWrapper {
  margin: 0 auto;
  padding: 20px;
  
}
.listHolder {
  height: 200px;
  overflow-y: scroll;
}
#search_ingredientList  {
  list-style: none;
  padding-left: 0;
  max-width: 900px;
  margin: 0 auto;
}
#search_ingredientList > li {
  width: 100%;
  border-top: 1px solid rgb(195, 195, 195);
  background-color: #fdfdfd;
}
.ingredientSearchItem {
  font-size: 1em;
  font-weight: 600;
  text-align: left;
  background-color: transparent;
  position: relative;
}

.itemWrapper {
  padding: 20px;
}



/* Search Listings */
.searchTitle {
  font-size: .7em;
  width: 100px;
  cursor: pointer;
  display: inline-block;
}

/* Search Listings */
.searchIcon {
  padding: 10px;
  width: 100px;
  cursor: pointer;
  display: inline-block;
}


.addToRoutineButton {
  border: none;
  color: white;
  font-weight: 600;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
}
.button1 { 
  background-color: orange;
}
.button2 { 
  background-color: rgb(228, 228, 228);
  color: rgb(129, 129, 129);
}



@media screen and (min-width: 375px) {

  .searchTitle {
    font-size: .75em;
    width: 150px;
    cursor: pointer;
  }

}

@media screen and (min-width: 400px) {

  .searchTitle {
    font-size: .85em;
    width: 190px;
    cursor: pointer;
  }
}



@media screen and (min-width: 520px) {

  .searchTitle {
    width: 300px;
    font-size: .95em;
  }

  

}


@media screen and (min-width: 900px) {
  #search_ingredientList  {
    padding-bottom: 120px;
  }


}


</style>