<template>
  <div class="adminConsole" id="adminConsole">
    <v-app id="vApp">

      

      <div class="adminHeader">
        <div class="headerNavItem">
          <br />
          <router-link to="/admin">Admin Home</router-link>
          <br>
        </div>
        <div class="headerNavItem">
          <br />
          <router-link to="/admin/ingredients/">Ingredients</router-link>
          <br>
        </div>
        <div class="headerNavItem">
          <br />
          <router-link to="/admin/meals/">Meals</router-link>
          <br>
        </div>
        <div class="headerNavItem">
          <br />
          <router-link to="/admin/actions/"><b>Human Actions</b></router-link>
          <br>
        </div>
        <div class="headerNavItem">
          <br />
          <router-link to="/admin/sequences/">Sequences</router-link>
          <br>
        </div>
        <div class="headerNavItem">
          <br />
          <router-link to="/admin/routines/">Routines</router-link>
          <br>
        </div>
      </div>
      <v-card flat>
        <div class="contentContainer" >



          <!-- ADD ACTION DIALOG -->
          <v-dialog v-model="addActionDialog" persistent max-width="700px" scrollable>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                @click="addHumanActionClick"
              >
                Create human action
              </v-btn>
            </template>
            
              <v-card v-if="newHumanAction">
                <v-card-title>
                  <span class="headline">Add Ingredient</span>
                </v-card-title>
                <v-card-text>
                  <div class="formHolder">
                    <div>
                      <v-text-field 
                        label="Agenda item title*"
                        hint="a short 2-3 word, {verb} -> {object} phrase"
                        v-model="newHumanAction.agendaItemTitle"
                        required
                        v-bind:key="`agendaItemTitle`"
                        ></v-text-field>
                    </div>
                    <div>
                      <v-select
                        :items="actionTypeItems"
                        label="Action type*"
                        v-model="newHumanAction.actionType"
                        @change="e => changeActionTypeSelect(e)"
                        required
                        v-bind:key="'actionType'"
                      ></v-select>
                    </div>
                    <div>
                      <v-combobox
                        v-model="newHumanAction.intentMeanCategory"               
                        :items="intentMeanCategoryList"
                        @change="e => changeCategoryListValue('intentMean', e)"
                        label="Select an intent mean category, or add a new one"
                        v-bind:key="'intentMeanCategoryListHumanAction'"
                        required
                      ></v-combobox>
                    </div>
                    <div>
                      <v-select
                        :items="[ 'completed items', 'cycles', 'kgs', 'kilometers', 'lbs', 'laps', 'miles', 'meters', 'minutes', 'repetitions', 'seconds', '% of mhr', '% of effort']"
                        label="Action unit of measure"
                        v-model="newHumanAction.actionUom"
                        required
                        v-bind:key="'actionLevel'"
                      ></v-select>
                    </div>
                    <div>
                      isLoadable?
                      <v-switch v-model="newHumanAction.isLoadable" v-bind:key="'isLoadable'"></v-switch>
                    </div>
                    <div>
                      isIsometric?
                      <v-switch v-model="newHumanAction.isIsometric" v-bind:key="'isIsometric'"></v-switch> 
                    </div>
                    <div>
                      isAerobic?
                      <v-switch v-model="newHumanAction.isAerobic" v-bind:key="'isAerobic'"></v-switch> 
                    </div>
                    <div>
                      isAnaerobic?
                      <v-switch v-model="newHumanAction.isAnaerobic" v-bind:key="'isAnaerobic'"></v-switch> 
                    </div>
                    <div>
                      <v-select
                        :items="volumeTypeSelectItemsForAdds"
                        label="Volume Type*"
                        v-model="newHumanAction.volumeType"
                        @change="e => changeVolumeTypeSelect(e)"
                        required
                        v-bind:key="'volumeType'"
                      ></v-select>
                    </div>
                    <div>
                      <v-select
                        :items="intensityTypeSelectItemsForAdds"
                        label="Intensity Type*"
                        v-model="newHumanAction.intensityType"
                        @change="e => changeIntensityTypeSelect(e)"
                        required
                        v-bind:key="'intensityType'"
                      ></v-select>
                    </div>
                    <div>
                      <v-select
                        v-if="newHumanAction.isLoadable"
                        :items="['barbell', 'dumbbell', 'kettlebell', 'vest', 'resistance band', 'machine', 'plate']"
                        label="Action load type"
                        v-model="newHumanAction.actionLoadType"
                        required
                        v-bind:key="'actionLoadType'"
                      ></v-select>
                    </div>
                    <div>
                      <v-select
                        :items="['novice', 'beginner', 'intermediate', 'advanced']"
                        label="Level*"
                        v-model="newHumanAction.actionLevel"
                        @change="e => changeActionLevelSelect(e)"
                        required
                        v-bind:key="'actionLevel'"
                      ></v-select>
                    </div>

                    <div>
                      <v-text-field 
                        label="Past tense verb associated with action"
                        hint="short phrase how action is used in past"
                        v-model="newHumanAction.vocabulary.pastUsageVerb"
                        required
                        v-bind:key="'vocabulary_pastUsageVerb'"
                        ></v-text-field>
                    </div>
                    <div>
                      <v-text-field 
                        label="Present tense verb associated with action"
                        hint="short phrase how action is used in present"
                        v-model="newHumanAction.vocabulary.presentUsageVerb"
                        required
                        v-bind:key="'vocabulary_presentUsageVerb'"
                        ></v-text-field>
                    </div>
                    <div>
                      <v-text-field 
                        label="Vocabulary ->UOM Singular"
                        hint="Unit of measure in singular form"
                        v-model="newHumanAction.vocabulary.uomSingular"
                        required
                        v-bind:key="'vocabulary_uomSingular'"
                        ></v-text-field>
                    </div>
                    <div>
                      <v-text-field 
                        label="Vocabulary ->UOM Plural"
                        hint="Unit of measure in plural form"
                        v-model="newHumanAction.vocabulary.uomPlural"
                        required
                        v-bind:key="'vocabulary_uomPlural'"
                        ></v-text-field>
                    </div>

                    
                    
                      
                  </div>
                  <small>*indicates required field</small>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="addActionDialog = false;">Close</v-btn>
                  <v-btn color="blue darken-1" text @click="handleAddAction">Add</v-btn>
                </v-card-actions>
              </v-card>
            
          </v-dialog>



          <!-- EDIT ACTION DIALOG -->
          <v-dialog v-model="editActionDialog" persistent max-width="700px" scrollable>
            
            
              <v-card v-if="selectedHumanActionItem">
                <v-card-title>
                  <span class="headline">{{ (editActionDialog) ? 'Edit human action' : 'Add human action'}}</span>
                </v-card-title>
                <v-card-text>
                  <div class="formHolder">
                    <div>
                      <v-text-field 
                        label="Agenda item title*"
                        hint="a short 2-3 word, {verb} -> {object} phrase"
                        v-model="selectedHumanActionItem.agendaItemTitle"
                        required
                        v-bind:key="`agendaItemTitle`+selectedHumanActionItem.actionId"
                        ></v-text-field>
                    </div>
                    <div>
                      <v-select
                        :items="actionTypeItems"
                        label="Action type*"
                        v-model="selectedHumanActionItem.actionType"
                        @change="e => changeActionTypeSelect(e)"
                        required
                        v-bind:key="'actionType'+selectedHumanActionItem.actionId"
                      ></v-select>
                    </div>
                    <div>
                      <v-combobox
                        v-model="selectedHumanActionItem.intentMeanCategory"               
                        :items="intentMeanCategoryList"
                        @change="e => changeCategoryListValue('intentMean', e)"
                        label="Select an intent mean category, or add a new one"
                        v-bind:key="'intentMeanCategoryListHumanAction'+selectedHumanActionItem.actionId"
                        required
                      ></v-combobox>
                    </div>
  
                    <div>
                      <v-select
                        :items="['completed items', 'cycles', 'kgs', 'kilometers', 'lbs', 'laps', 'miles', 'meters', 'minutes', 'repetitions', 'seconds', '% of mhr', '% of effort']"
                        label="Action unit of measure"
                        v-model="selectedHumanActionItem.actionUom"
                        required
                        v-bind:key="'actionLevel'+selectedHumanActionItem.actionId"
                      ></v-select>
                    </div>
                    <div>
                      isLoadable?
                      <v-switch v-model="selectedHumanActionItem.isLoadable" v-bind:key="'isLoadable'+newHumanAction.actionId"></v-switch>
                    </div>
                    <div>
                      isIsometric?
                      <v-switch v-model="selectedHumanActionItem.isIsometric" v-bind:key="'isIsometric'+newHumanAction.actionId"></v-switch> 
                    </div>
                    <div>
                      isAerobic?
                      <v-switch v-model="selectedHumanActionItem.isAerobic" v-bind:key="'isAerobic'+newHumanAction.actionId"></v-switch> 
                    </div>
                    <div>
                      isAnaerobic?
                      <v-switch v-model="selectedHumanActionItem.isAnaerobic" v-bind:key="'isAnaerobic'+newHumanAction.actionId"></v-switch> 
                    </div>
                    <div>
                      <v-select
                        :items="volumeTypeSelectItemsForEdits"
                        label="Volume Type*"
                        v-model="selectedHumanActionItem.volumeType"
                        @change="e => changeVolumeTypeSelect(e)"
                        required
                        v-bind:key="'volumeType'+selectedHumanActionItem.actionId"
                      ></v-select>
                    </div>
                    <div>
                      <v-select
                        :items="intensityTypeSelectItemsForEdits"
                        label="Intensity Type*"
                        v-model="selectedHumanActionItem.intensityType"
                        @change="e => changeIntensityTypeSelect(e)"
                        required
                        v-bind:key="'intensityType'+selectedHumanActionItem.actionId"
                      ></v-select>
                    </div>
                    <div>
                      <v-select
                        v-if="selectedHumanActionItem.isLoadable"
                        :items="['barbell', 'dumbbell', 'kettlebell', 'vest', 'resistance band', 'machine', 'plate']"
                        label="Action load type"
                        v-model="selectedHumanActionItem.actionLoadType"
                        required
                        v-bind:key="'actionLoadType'+selectedHumanActionItem.actionId"
                      ></v-select>
                    </div>
                    <div>
                      <v-select
                        :items="['novice', 'beginner', 'intermediate', 'advanced']"
                        label="Level*"
                        v-model="selectedHumanActionItem.actionLevel"
                        @change="e => changeActionLevelSelect(e)"
                        required
                        v-bind:key="'actionLevel'+selectedHumanActionItem.actionId"
                      ></v-select>
                    </div>

                    <div>
                      <v-text-field 
                        label="Past tense verb associated with action"
                        hint="short phrase how action is used in past"
                        v-model="selectedHumanActionItem.vocabulary.pastUsageVerb"
                        required
                        v-bind:key="'vocabulary_pastUsageVerb'+selectedHumanActionItem.actionId"
                        ></v-text-field>
                    </div>
                    <div>
                      <v-text-field 
                        label="Present tense verb associated with action"
                        hint="short phrase how action is used in present"
                        v-model="selectedHumanActionItem.vocabulary.presentUsageVerb"
                        required
                        v-bind:key="'vocabulary_presentUsageVerb'+selectedHumanActionItem.actionId"
                        ></v-text-field>
                    </div>
                    <div>
                      <v-text-field 
                        label="Vocabulary ->UOM Singular"
                        hint="Unit of measure in singular form"
                        v-model="selectedHumanActionItem.vocabulary.uomSingular"
                        required
                        v-bind:key="'vocabulary_uomSingular'+selectedHumanActionItem.actionId"
                        ></v-text-field>
                    </div>
                    <div>
                      <v-text-field 
                        label="Vocabulary ->UOM Plural"
                        hint="Unit of measure in plural form"
                        v-model="selectedHumanActionItem.vocabulary.uomPlural"
                        required
                        v-bind:key="'vocabulary_uomPlural'+selectedHumanActionItem.actionId"
                        ></v-text-field>
                    </div>

                    
                    
                      
                  </div>
                  <small>*indicates required field</small>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="editActionDialog = false;">Close</v-btn>
                  <v-btn color="blue darken-1" text @click="handleEditAction">Save</v-btn>
                  
                </v-card-actions>
              </v-card>
            
          </v-dialog>


          <!-- EDIT IMAGE DIALOG -->
          <v-dialog v-model="editHumanActionImageDialog" persistent max-width="700px">
            <v-card>
              <v-card-title>
                <span class="headline">Edit Image</span>
              </v-card-title>
              <v-card-text>
                <div class="formHolder">
                  <!-- Black icon -->
                  
                  <div class="picIconHolder" v-if="selectedHumanActionItem">
                    
                    <div class="image-upload">
                        <label @click="$refs.inputUpload1_black.click()" id="label1_black">
                          <div v-if="selectedHumanActionItem.iconImageBlack">
                            <img :src="selectedHumanActionItem.iconImageBlack" width="50px">
                          </div>
                          <div v-else>
                            Black Icon<br /><i class="fas fa-camera"></i>
                          </div>
                        </label>
                        <input v-show="false" id="file-input1-black" ref="inputUpload1_black" type="file" @change="uploadImg({'type': 'humanAction', 'iconColor': 'black'})" />
                    </div>
                  </div>
                
                  <!-- Grey Icon -->
                  <div class="picIconHolder" v-if="selectedHumanActionItem">
                    
                    <div class="image-upload">
                        <label @click="$refs.inputUpload1_grey.click()" id="label1_grey">
                          <div v-if="selectedHumanActionItem.iconImageGrey">
                            <img :src="selectedHumanActionItem.iconImageGrey" width="50px">
                          </div>
                          <div v-else>
                            Grey Icon<br /><i class="fas fa-camera"></i>
                          </div>
                        </label>
                        <input v-show="false" id="file-input1-grey" ref="inputUpload1_grey" type="file" @change="uploadImg({'type': 'humanAction', 'iconColor': 'grey'})" />
                    </div>
                  </div>
                    
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="editHumanActionImageDialog = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>


          <!-- EDIT TAGS DIALOG -->
          <v-dialog v-model="editTagsDialog" persistent max-width="1000px" scrollable>
        
                  
                    <TagManager 
                      :selected-item="selectedHumanActionItem" 
                      v-on:close="closeTagsDialog"
                      :tag-type="`humanAction`"
                      />

              
                
                
          </v-dialog>


          <br />
          <div class="searchRoutinesHolder">
            <div class="searchRoutines">
              <v-text-field
                v-model="searchActions"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </div>
          </div>
          <br />
          <v-data-table
            :headers="dataHeaders"
            :search="searchActions"
            :items="humanActionList"
            item-key="agendaItemTitle"
            class="elevation-1"
            :pagination.sync="pagination"
          >
            
            
            <template v-slot:items="props">
              <tr @click="props.expanded = !props.expanded">
                
                <td style="padding:15px;text-align: center;"><img :src="props.item.iconImageBlack" class="icon" v-if="props.item.iconImageBlack"></td>
                <td> {{ props.item.agendaItemTitle }}</td>
                <td class="text-xs-right">{{ props.item.actionType }}</td>
                <td class="text-xs-right">{{ props.item.intentMeanCategory }}</td>
                
                <td class="text-xs-right">
                  &nbsp;&nbsp;&nbsp;
                  <i class="fas fa-hashtag" @click="editTags(props.item)"></i>
                  &nbsp;&nbsp;&nbsp;
                  <i class="fas fa-camera" @click="editImage(props.item)"></i>
                  &nbsp;&nbsp;&nbsp;
                  <i class="fas fa-pencil-alt" @click="editThisActionItem(props.item)"></i>
                  &nbsp;&nbsp;&nbsp;
                  <i class="fas fa-trash-alt" @click="deleteThisActionItem(props.item)"></i>

                </td>
              </tr>
              
              
              
            </template>
            
            <!--
            <template v-slot:expand="{ props  }">
              <v-card flat>
                <v-card-text>Peek-a-boo!</v-card-text>
              </v-card>
            </template>
            -->
            
            
          </v-data-table>
        </div>
      </v-card>

    </v-app>
  </div>
</template>

<script>
    import '@/plugins/vuetify'
    import firebase from '@/firebase'
    import cmsModule from '@/store/cms'
    import signinModule from '@/store/signin'
    import userModule from '@/store/user'
    import adminModule from '@/store/admin'
    import experienceModule from '@/store/experience'
    //import RoutineOverlayAdmin from '@/components/RoutineOverlayAdmin'
    import TagManager from '@/components/TagManager'
    import settingsModule from '@/store/settings'
    import db from '@/db'
    
    //import NextStepper from '@/components/NextStepper'
    
    import { mapGetters, mapActions } from 'vuex'
    //import * as use from '@tensorflow-models/universal-sentence-encoder';

  export default {
    name: "admin",
    components: {
      //RoutineOverlayAdmin,
      TagManager
    },
    data () {
      return {
        
        selectedItem: null,
        selectedHumanActionItem: null,
        searchActions: '',
        pagination: {
          rowsPerPage: 10
        },

        addActionDialog: false,
        actionType: null,



        rules: [
          (v) => !!v || 'Statement is required',
          (v) => v.length <= 60 || 'Max 60 characters'
        ],
        
        imageData: null,
        compressedImageData: null,

        actionTypeItems: [
          'analysis',
          'exercise',
          'hygiene',
          'personal project',
          'wellness',
          'work project'
          ],
        

        dataHeaders: [
          { text: 'Icon', align: 'start', value: 'iconImage' },
          {
            text: 'Agenda Item Title',
            align: 'left',
            sortable: false,
            value: 'agendaItemTitle',
          },
          { text: 'Type', align: 'right', value: 'actionType' },
          { text: 'Intent Mean Category', align: 'right', value: 'intentMeanCategory' },
          { text: 'Actions', align: 'right', value: 'actions', sortable: false },
          
        ],

        
        
        editHumanActionImageDialog: false,
        editActionDialog: false,
        
        editTagsDialog: false,
        
        newHumanAction: {
          
          actionId: null,
          actionLevel: null,
          actionLoadType: null,
          actionType: null,
          actionUom: null,
          agendaItemTitle: null,
          dateCreated: null,
          iconImageBlack: null,
          iconImageGrey: null,
          intensityType: null,
          intentMeanCategory: null,
          isAerobic: false,
          isAnaerobic: false,
          isIsometric: false,
          isLoadable: false,
          tags: [],
          vocabulary: {
            pastUsageVerb: null,
            presentUsageVerb: null,
            uomSingular: null,
            uomPlural: null,
          },
          volumeType: null,
          
        },

        
        

      }
    },
    computed: {
      ...mapGetters('cms', [
        'checkInFeelingContent',
        'checkInThoughtContent'
      ]),
      ...mapGetters('auth', {
        profile: 'profile',
        loggedIn: 'loggedIn',
      }),
      ...mapGetters('admin', [
        'intentMeanCategoryList',
        'intentMeanCategoryListSelect',
        'intentMeanCategoryListSelectForRoutine',
        'humanActionList',
        //'routineList',
        //'sequenceList',
      ]),


      
      volumeTypeSelectItemsForEdits () {
        if(this.selectedHumanActionItem.isIsometric) {
          return ['duration', 'none', 'secDuration']
        }else {
          return ['items','reps', 'distance', 'shortDistance', 'duration', 'secDuration', 'tabata', 'none']
        }
      },

      intensityTypeSelectItemsForEdits () {
        if(this.selectedHumanActionItem.isAnaerobic) {
          return ['pace','%1rm', '%effort', 'load', 'tabata', 'none']
        }else if(this.selectedHumanActionItem.isAerobic){
          return ['pace', '%effort', '%mhr', 'tabata', 'none']
        }else if(this.selectedHumanActionItem.isIsometric){
          return ['load', 'none']
        }else{
          return ['completion rate', 'none']
        }
      },

      volumeTypeSelectItemsForAdds () {
        if(this.newHumanAction.isIsometric) {
          return ['duration', 'none', 'secDuration']
        }else {
          return ['items','reps', 'distance', 'shortDistance', 'duration', 'secDuration', 'tabata', 'none']
        }
      },

      intensityTypeSelectItemsForAdds () {
        if(this.newHumanAction.isAnaerobic) {
          return ['pace','%1rm', '%effort', 'load', 'tabata', 'none']
        }else if(this.newHumanAction.isAerobic){
          return ['pace', '%effort', '%mhr', 'tabata', 'none']
        }else if(this.newHumanAction.isIsometric){
          return ['load', 'none']
        }else{
          return ['completion rate', 'none']
        }
      },
      


    },
    methods: {
      
      ...mapActions('user', [
        'getUserState',
        'updateNotificationHistory'
      ]),
      ...mapActions('admin', [
        'getIntentMeanCategoryList',
        'setIntentMeanCategoryListSelect',
        'setIntentMeanCategoryListSelectForRoutine',
        'resetIntentMeanCategoryListSelect',
        'resetIntentMeanCategoryListSelectForRoutine',
        'addHumanAction',
        //'addRoutine',
        'getHumanActionList',
        //'getRoutineData',
        //'getRoutineList',
        //'getSequenceList',
        'editHumanAction',
        //'editRoutine',
        'deleteActionItem',
        //'deleteRoutineItem',
        'changeCategoryListValue',
      ]),

      ...mapActions('signin', [
        'googleLogin',
        'anonymousLogin',
        'autoSignIn'
      ]),
      ...mapActions('auth', [
        'setAutoSignInFlag',
        'setProfile',
        'setAnonymousProfile'
      ]),

      addHumanActionClick () {
        //reset
        this.addActionDialog = true
        this.resetNewHumanAction()
      },
      handleAddAction() {
        this.addActionDialog = false
        var newAction = this.createNewHumanAction()
        this.addHumanAction(newAction)
        this.resetNewHumanAction()
      },
      editThisActionItem(item) {

        this.selectedHumanActionItem =  {
          ...item
        } 

        this.editActionDialog = true

      },
      deleteThisActionItem(item) {
        this.deleteActionItem(item)
      },
      
      handleEditAction () {
        //this.addActionDialog = false
        this.editActionDialog = false
        var newAction = this.createEditHumanAction()
        this.editHumanAction(newAction)
      },
      createNewHumanAction () {
        var obj = {
          'actionId': this.newHumanAction.actionId,
          'actionLevel': this.newHumanAction.actionLevel,
          'actionLoadType': this.newHumanAction.actionLoadType,
          'actionType': this.newHumanAction.actionType,
          'actionUom': this.newHumanAction.actionUom,
          'agendaItemTitle': this.newHumanAction.agendaItemTitle,
          'iconImageBlack': this.newHumanAction.iconImageBlack,  //these get added manually later
          'iconImageGrey': this.newHumanAction.iconImageGrey,   //this gets added manually later
          'intensityType': this.newHumanAction.intensityType,
          'intentMeanCategory': this.newHumanAction.intentMeanCategory,
          'isAerobic': this.newHumanAction.isAerobic,
          'isAnaerobic': this.newHumanAction.isAnaerobic,
          'isIsometric': this.newHumanAction.isIsometric,
          'isLoadable': this.newHumanAction.isLoadable,
          'tags': this.newHumanAction.tags,
          'vocabulary': {
            'pastUsageVerb': this.newHumanAction.vocabulary.pastUsageVerb,
            'presentUsageVerb': this.newHumanAction.vocabulary.presentUsageVerb,
            'uomSingular': this.newHumanAction.vocabulary.uomSingular,
            'uomPlural': this.newHumanAction.vocabulary.uomPlural,
          },
          'volumeType': this.newHumanAction.volumeType,
        }
        return obj
      },
      createEditHumanAction () {
        var obj = {
          'actionId': this.selectedHumanActionItem.actionId,
          'actionLevel': this.selectedHumanActionItem.actionLevel,
          'actionLoadType': this.selectedHumanActionItem.actionLoadType,
          'actionType': this.selectedHumanActionItem.actionType,
          'actionUom': (this.selectedHumanActionItem.actionUom) ? this.selectedHumanActionItem.actionUom : null,
          'agendaItemTitle': this.selectedHumanActionItem.agendaItemTitle,
          'iconImageBlack': (this.selectedHumanActionItem) ? this.selectedHumanActionItem.iconImageBlack : null,  //these get added manually later
          'iconImageGrey': (this.selectedHumanActionItem) ? this.selectedHumanActionItem.iconImageGrey : null,   //this gets added manually later
          'intensityType': this.selectedHumanActionItem.intensityType,
          'intentMeanCategory': this.selectedHumanActionItem.intentMeanCategory,
          'isAerobic': this.selectedHumanActionItem.isAerobic,
          'isAnaerobic': this.selectedHumanActionItem.isAnaerobic,
          'isIsometric': this.selectedHumanActionItem.isIsometric,
          'isLoadable': this.selectedHumanActionItem.isLoadable,
          'tags': (this.selectedHumanActionItem) ? this.selectedHumanActionItem.tags : [],
          'vocabulary': {
            'pastUsageVerb': this.selectedHumanActionItem.vocabulary.pastUsageVerb,
            'presentUsageVerb': this.selectedHumanActionItem.vocabulary.presentUsageVerb,
            'uomSingular': this.selectedHumanActionItem.vocabulary.uomSingular,
            'uomPlural': this.selectedHumanActionItem.vocabulary.uomPlural,
          },
          'volumeType': this.selectedHumanActionItem.volumeType,
          
        }
        return obj
      },
      resetNewHumanAction () {
        this.resetIntentMeanCategoryListSelect()
        this.newHumanAction = {
          actionId: null,
          actionLevel: null,
          actionLoadType: null,
          actionType: null,
          actionUom: null,
          agendaItemTitle: null,
          iconImageBlack: null,
          iconImageGrey: null,
          intensityType: null,
          intentMeanCategory: null,
          isLoadable: false,
          isIsometric: false,
          isAerobic: false,
          isAnaerobic: false,
          tags: [],
          vocabulary: {
            pastUsageVerb: null,
            presentUsageVerb: null,
            uomSingular: null,
            uomPlural: null,
          },
          volumeType: null,
          
        }
      },
      changeCategoryListValue(type, event) {
        this.$store.dispatch("admin/changeCategoryListValue", { type, event }); 
        this.newHumanAction.intentMeanCategory = event
      },
      changeActionTypeSelect(event) {
        this.newHumanAction.actionType = event
      },
      changeActionLevelSelect(event) {
        this.newHumanAction.actionLevel = event
      },
      changeVolumeTypeSelect(event) {
        this.newHumanAction.volumeType = event
      },
      changeIntensityTypeSelect(event) {
        this.newHumanAction.intensityType = event
      },
      editImage(item) {
        this.selectedHumanActionItem = item
        this.$nextTick(()=>{
          this.editHumanActionImageDialog = true
        })
        
        
      },
      editTags(item) {
        this.selectedHumanActionItem = item
        this.$nextTick(() => {
          this.editTagsDialog = true
        })
        
      },
      closeTagsDialog() {
        this.editTagsDialog = false
      },

      
      
      

      


      async uploadImg(payload) {

        var type = payload.type
        var iconColor = (payload.iconColor) ? payload.iconColor : null;

        if(event.target.files[0] == "") {return}
        var storageRef = firebase.storage().ref();
        //var imageName = null
        var folderName = null
        var uploadTask = null
        var metadata = null
        
        this.imageData = event.target.files[0]
        
        //compress
        const width = 500
        const reader = new FileReader()
        
        
        reader.readAsDataURL(this.imageData)
        reader.onload = async (event) => {
          const img = new Image()
          img.src = event.target.result
          img.onload = async () => {
            const scaleFactor = width / img.width
            const elem = document.createElement('canvas')
            elem.width = width
            elem.height = scaleFactor * img.height
            const ctx = elem.getContext('2d')
            ctx.drawImage(img, 0, 0, width, scaleFactor*img.height)
            ctx.canvas.toBlob(async(blob) => {
              // imageName = (type == 'humanAction') ? 'actionIconImg' : 'routineIconImg'
              folderName = (iconColor == 'black') ? '_black' : '_grey'
              metadata = { 'contentType': 'image/jpg'};
              if(type === 'humanAction') {
                uploadTask = storageRef.child(`heediHumanActionIcons${folderName}/${this.selectedHumanActionItem.agendaItemTitle}`)
                                      //.put(this.imageData, metadata)
                                      .put(blob, metadata)
              }else if(type === 'routine') {
                uploadTask = storageRef.child(`heediRoutineIcons${folderName}/${this.selectedItem.routineId}`)
                                      //.put(this.imageData, metadata)
                                      .put(blob, metadata)
              }else if(type === 'routine-callout') {
                uploadTask = storageRef.child(`heediRoutineCalloutImages/${this.selectedItem.routineId}`)
                                      //.put(this.imageData, metadata)
                                      .put(blob, metadata)
              }else if(type === 'routine-content1') {
                uploadTask = storageRef.child(`heediRoutineContentImages/${this.selectedItem.routineId}-1`)
                                      //.put(this.imageData, metadata)
                                      .put(blob, metadata)
              }else if(type === 'routine-content2') {
                uploadTask = storageRef.child(`heediRoutineContentImages/${this.selectedItem.routineId}-2`)
                                      //.put(this.imageData, metadata)
                                      .put(blob, metadata)
              }
              

              uploadTask.on(`state_changed`, async (snapshot) => {
                //this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                    //console.log('Upload is paused');
                    break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                    //console.log('Upload is running');
                    break;
                }
              },async (error) => {
                /* eslint-disable no-console */
                console.log(error.message)
              },async () => {
                  //this.uploadValue=100;
                  uploadTask.snapshot.ref.getDownloadURL().then(async(url) => {

                    if(type == `humanAction`) {
                      var ref = await db.collection('human_actions').doc(this.selectedHumanActionItem.actionId)
                
                      if(iconColor == 'black') {
                        document.getElementById("file-input1-black").value = "";
                        await ref.set({
                            'iconImageBlack': url,

                        }, {merge: true})
                      }else if(iconColor == 'grey') {
                        document.getElementById("file-input1-grey").value = "";
                        await ref.set({
                            'iconImageGrey': url,

                        }, {merge: true})
                      }

                      /* eslint-disable no-console */
                      console.log('File available at', url);
                      this.editHumanActionImageDialog = false


                    }else if(type == `routine`) {
                      var ref2 = await db.collection('routines').doc(this.selectedItem.routineId)
                      
                      if(iconColor == 'black') {
                        document.getElementById("file-input2-mainBlack").value = "";
                        await ref2.set({
                          'routineTemplate': {
                            'iconImageBlack': url,
                          }

                        }, {merge: true})
                      }else if(iconColor == 'grey') {
                        document.getElementById("file-input2-mainGrey").value = "";
                        await ref2.set({
                          'routineTemplate': {
                            'iconImageGrey': url,
                          }

                        }, {merge: true})
                      }
                      

                      /* eslint-disable no-console */
                      console.log('File available at', url);
                      this.editRoutineImageDialog = false
                      
                    }else if(type == `routine-callout`) {
                      var ref3 = await db.collection('routines').doc(this.selectedItem.routineId)
                      document.getElementById("file-input2-callout").value = "";
                      await ref3.set({
                        'marketingContent': {
                          'images': {
                            'detailPage': {
                              'calloutImage': {
                                'url': url,
                                'credit': null,
                              },
                            }
                          }
                        }

                      }, {merge: true})
                      /* eslint-disable no-console */
                      console.log('File available at', url);
                      this.editRoutineImageDialog = false
                      
                    }else if(type == `routine-content1`) {
                      var ref4 = await db.collection('routines').doc(this.selectedItem.routineId)
                      document.getElementById("file-input1-content1").value = "";
                      await ref4.set({
                        'marketingContent': {
                          'images': {
                            'detailPage': {
                              'contentImages': {
                                'contentImg1': url,
                                'credit': null,
                              }
                            }
                          }
                        }

                      }, {merge: true})
                      /* eslint-disable no-console */
                      console.log('File available at', url);
                      this.editRoutineImageDialog = false
                      
                    }else if(type == `routine-content2`) {
                      var ref5 = await db.collection('routines').doc(this.selectedItem.routineId)
                      document.getElementById("file-input2-content2").value = "";
                      await ref5.set({
                        'marketingContent': {
                          'images': {
                            'detailPage': {
                              'contentImages': {
                                'contentImg2': url,
                                'credit': null,
                              }
                            }
                          }
                        }

                      }, {merge: true})
                      /* eslint-disable no-console */
                      console.log('File available at', url);
                      this.editRoutineImageDialog = false
                      
                    }
                      



                  })
                },
              );
            }, this.imageData.type, 1)

          }
        }
        reader.onerror = (error) => {
          /* eslint-disable no-console */
          console.log(error)
        }
      },

      
    },
    beforeCreate () {
      if(!this.$store.state.cms) this.$store.registerModule('cms', cmsModule)
      if(!this.$store.state.user) this.$store.registerModule('user', userModule)
      if(!this.$store.state.signin) this.$store.registerModule('signin', signinModule)
      if(!this.$store.state.admin) this.$store.registerModule('admin', adminModule)
      if(!this.$store.state.experience) this.$store.registerModule('experience', experienceModule)
      if(!this.$store.state.settings) this.$store.registerModule('settings', settingsModule)
    },
    created () {
      //this.resetCheckIn()
      //gtag('config', 'G-H9RD8W4MLR', {'page_path': '/admin'});
      //this.autoSignIn()


      this.getIntentMeanCategoryList()
      this.getHumanActionList()
      //this.getRoutineList()

      //this.getSequenceList()


      


      
    },
    async mounted () {
      var command = (this.$route.params.command) ? this.$route.params.command : null
      var type = (this.$route.params.type) ? this.$route.params.type : null
      //var resourceId = (this.$route.params.resourceId) ? this.$route.params.resourceId : null

      //if(resourceId) {
      //  resourceId = resourceId.replace(/-/g, ' ');   //resource could be
      //}
      if(type) {
        type = type.replace(/_/g, ' ')
        //type = type.replace(/-/g, ' ')
      }
      if(command) {
        switch(command) {
          case "add":   //add an existing routine to the the user's routineTemplates sub-collection, resourceId could be a routineId

            /*await this.addRoutineToUser({
              'routineType': type,
              'routineId': resourceId
            })
            this.$router.push('/console')*/
            break;

          case "create":   //create a new routine template; add a copy in routines collection and in user's routineTemplates sub-collection

            //if no type param, then need to show select dialog
            if(type) {

              document.getElementById('adminConsole').style.height = "6000px"
              document.getElementById('routineOverlayAdmin').style.height = "6000px"
              this.showOverlay({
                'ctaType': 'create_routine',
                'routineType': type,
                'item': null,
              })
            }

            break;


          case "modify":   //add an existing routine to the the user's routineTemplates sub-collection, resourceId could be a routineId
            //console.log("case modify")
            //use resourceId to pull the data
            //var result = await this.getRoutineData(resourceId)

            //this.showOverlay({
            //  'category': result.routineTemplate.intentMeanCategory,
            //  'ctaType': 'routine_modify',
            //  'parentCategory': null,
            //  'routineType': result.routineTemplate.routineType,
            //  'item': result.routineTemplate,
            //})
          

            break;

          

          default:

            break;
        }
      }
      
    },
    updated () {

    },
    watch: {

    }
  }
</script>

<style scoped>

.searchRoutinesHolder {
  text-align: right;
  width: 100%;
  padding-right: 10px;
  background-color: transparent;
}
.searchRoutines {
  display: inline-block;
  width: 300px;
  background-color: transparent;
}

.adminConsole {
  margin: 0 auto;  /* this centers the div on the page */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

#vApp {
 background-color: transparent;
}

.icon {
  max-width: 50px;
  max-height: 40px;
}

.adminHeader {
  background-color: white;
  text-align: center;
  padding-bottom: 20px;
}
.headerNavItem {
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
  padding: 7px;
}


.contentContainer {
  max-width: 900px;
  margin: 0 auto;
  min-height: 500px;
  padding-bottom: 100px;
}
.routineType {
  border: 1px solid grey;
  border-radius: 8px;
  margin: 10px;
  padding: 20px;
}
.formHolder {
  padding-right: 30px;
  padding-left: 30px;
}

.picIconHolder {
  display: inline-block;

  font-size: 1.3em;
  color: rgb(73, 73, 73);
  width: 100px;
  background-color: white;
  margin: 0 auto;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  padding: 5px;
  border: solid 1px grey;

}
</style>
