<template>
  <div class="adminConsole" id="adminConsole">
    <v-app id="vApp">



      <div class="adminHeader">
        <div class="headerNavItem">
          <br />
          <router-link to="/admin">Admin Home</router-link>
          <br>
        </div>
        <div class="headerNavItem">
          <br />
          <router-link to="/admin/ingredients/"><strong>Ingredients</strong></router-link>
          <br>
        </div>
        <div class="headerNavItem">
          <br />
          <router-link to="/admin/meals/">Meals</router-link>
          <br>
        </div>
        <div class="headerNavItem">
          <br />
          <router-link to="/admin/actions/">Human Actions</router-link>
          <br>
        </div>
        <div class="headerNavItem">
          <br />
          <router-link to="/admin/sequences/">Sequences</router-link>
          <br>
        </div>
        <div class="headerNavItem">
          <br />
          <router-link to="/admin/routines/">Routines</router-link>
          <br>
        </div>
      </div>
      <v-card flat>
        <div class="contentContainer" >



          <!-- ADD INGREDIENT DIALOG -->
          <v-dialog v-model="addIngredientDialog" persistent max-width="700px" scrollable>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                @click="addIngredientClick"
              >
                Create Ingredient
              </v-btn>
            </template>
            
            <v-card v-if="newIngredient">
              <v-card-title>
                <span class="headline">Add Ingredient</span>
              </v-card-title>
              <v-card-text>
                <div class="formHolder">
                  <div>
                    <v-text-field 
                      label="Ingredient name"
                      v-model="newIngredient.ingredientName"
                      required
                      v-bind:key="`ingredientName`"
                      ></v-text-field>
                  </div>
                  <div>
                    <v-select
                      :items="ingredientTypeItems"
                      label="Action type*"
                      v-model="newIngredient.ingredientType"
                      @change="e => changeIngredientTypeSelect(e)"
                      required
                      v-bind:key="'ingredientType'"
                    ></v-select>
                  </div>


                  <div>
                    <v-text-field 
                      label="Ingredient benefit"
                      v-model="benefit"
                      required
                      v-bind:key="'ingredientBenefit'"
                      ></v-text-field>
                      <v-text-field 
                      label="Ingredient benefit credit"
                      v-model="benefitCreditUrl"
                      required
                      v-bind:key="'ingredientBenefitCreditUrl'"
                      ></v-text-field>
                      <v-text-field 
                      label="Ingredient benefit credit title"
                      v-model="benefitCreditTitle"
                      required
                      v-bind:key="'ingredientBenefitCreditTitle'"
                      ></v-text-field>
                      &nbsp;&nbsp;
                      <v-btn small @click="addIngredientBenefit">Add</v-btn>

                  </div>
                  <div>
                    <ul>
                      <li v-for="(benefit, index) in newIngredient.ingredientBenefits" v-bind:key="index">
                        {{ benefit }}
                      </li>
                    </ul>
                  </div>
                    
                </div>
                <small>*indicates required field</small>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="addIngredientDialog = false;">Close</v-btn>
                <v-btn color="blue darken-1" text @click="handleAddIngredient">Add</v-btn>
              </v-card-actions>
            </v-card>


          </v-dialog>


          <!-- EDIT INGREDIENT DIALOG -->
          <v-dialog v-model="editIngredientDialog" persistent max-width="700px" scrollable>

            
            <v-card v-if="selectedIngredientItem">
              <v-card-title>
                <span class="headline">Edit Ingredient</span>
              </v-card-title>
              <v-card-text>
                <div class="formHolder">
                  <div>
                    <v-text-field 
                      label="Ingredient name"
                      v-model="selectedIngredientItem.ingredientName"
                      required
                      v-bind:key="`ingredientName`"
                      ></v-text-field>
                  </div>
                  <div>
                    <v-select
                      :items="ingredientTypeItems"
                      label="Ingredient type*"
                      v-model="selectedIngredientItem.ingredientType"
                      @change="e => changeIngredientTypeSelectForEdits(e)"
                      required
                      v-bind:key="'ingredientType'"
                    ></v-select>
                  </div>


                  <div>
                    <v-text-field 
                      label="Ingredient benefit"
                      v-model="benefit"
                      required
                      v-bind:key="'ingredientBenefit'"
                      ></v-text-field>
                      &nbsp;&nbsp;
                      <v-btn small @click="addIngredientBenefitForEdits">Add</v-btn>

                  </div>
                  <div>
                    <ul>
                      <li v-for="(benefit, index) in selectedIngredientItem.ingredientBenefits" v-bind:key="index">
                        {{ benefit }}
                      </li>
                    </ul>
                  </div>
                    
                </div>
                <small>*indicates required field</small>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="editIngredientDialog = false;resetIngredient();">Close</v-btn>
                <v-btn color="blue darken-1" text @click="handleEditIngredient">Save</v-btn>
              </v-card-actions>
            </v-card>


          </v-dialog>

          <!-- EDIT IMAGE DIALOG -->
          <v-dialog v-model="editIngredientImageDialog" persistent max-width="700px">
            <v-card>
              <v-card-title>
                <span class="headline">Edit Image</span>
              </v-card-title>
              <v-card-text>
                <div class="formHolder">
                  <!-- Icon -->
                  
                  <div class="picIconHolder" v-if="selectedIngredientItem">
                    
                    <div class="image-upload">
                        <label @click="$refs.inputUpload1.click()" id="label1">
                          <div v-if="selectedIngredientItem.ingredientIcon">
                            <img :src="selectedIngredientItem.ingredientIcon" width="50px">
                          </div>
                          <div v-else>
                            Ingredient Icon<br /><i class="fas fa-camera"></i>
                          </div>
                        </label>
                        <input v-show="false" id="file-input1" ref="inputUpload1" type="file" @change="uploadImg({'type': 'ingredient', 'iconColor': 'color'})" />
                    </div>
                  </div>
                
                  
                    
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="editIngredientImageDialog = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- EDIT TAGS DIALOG -->
          <v-dialog v-model="editTagsDialog" persistent max-width="1000px" scrollable>
        
                  
                    <TagManager 
                      :selected-item="selectedIngredientItem" 
                      v-on:close="closeTagsDialog"
                      :tag-type="`ingredient`"
                      />

              
                
                
          </v-dialog>




          <br />
          <div class="searchRoutinesHolder">
            <div class="searchRoutines">
              <v-text-field
                v-model="searchIngredients"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </div>
          </div>
          <br />
          <v-data-table
            :headers="dataHeaders"
            :search="searchIngredients"
            :items="ingredientList"
            item-key="ingredientName"
            class="elevation-1"
            :pagination.sync="pagination"
          >
            
            
            <template v-slot:items="props">
              <tr @click="props.expanded = !props.expanded">
                
                <td style="padding:15px;text-align: center;"><img :src="props.item.ingredientIcon" class="icon" v-if="props.item.ingredientIcon"></td>
                <td> {{ props.item.ingredientName }}</td>
                <td class="text-xs-right">{{ props.item.ingredientType }}</td>
                
                <td class="text-xs-right">
                  &nbsp;&nbsp;&nbsp;
                  
                  <i class="fas fa-hashtag" @click="editTags(props.item)"></i>
                  &nbsp;&nbsp;&nbsp;
                  <i class="fas fa-camera" @click="editImage(props.item)"></i>
                  &nbsp;&nbsp;&nbsp;
                  <i class="fas fa-pencil-alt" @click="editThisIngredientItem(props.item)"></i>
                  &nbsp;&nbsp;&nbsp;
                  <i class="fas fa-trash-alt" @click="deleteThisIngredientItem(props.item)"></i>
                  
                </td>
              </tr>
            </template>
            
          </v-data-table>
        </div>
      </v-card>

    </v-app>
  </div>
</template>

<script>
    import '@/plugins/vuetify'
    import firebase from '@/firebase'
    import cmsModule from '@/store/cms'
    import signinModule from '@/store/signin'
    import userModule from '@/store/user'
    import adminModule from '@/store/admin'
    import experienceModule from '@/store/experience'
    //import SequenceOverlayAdmin from '@/components/SequenceOverlayAdmin'
    import TagManager from '@/components/TagManager'
    import settingsModule from '@/store/settings'
    import db from '@/db'
    
    //import NextStepper from '@/components/NextStepper'
    
    import { mapGetters, mapActions } from 'vuex'
    //import * as use from '@tensorflow-models/universal-sentence-encoder';

  export default {
    name: "AdminIngredients",
    components: {
      //SequenceOverlayAdmin,
      TagManager
    },
    data () {
      return {
        addIngredientDialog: false,
        editIngredientDialog: false,
        editTagsDialog: false,
        selectedIngredientItem: null,
        editIngredientImageDialog: false,
        searchIngredients: '',
        pagination: {
          rowsPerPage: 10
        },





        rules: [
          (v) => !!v || 'Statement is required',
          (v) => v.length <= 60 || 'Max 60 characters'
        ],
        
        imageData: null,
        compressedImageData: null,

        
        

        dataHeaders: [
          { text: 'Icon', align: 'start', value: 'ingredientIcon' },
          {
            text: 'Ingredient Name',
            align: 'left',
            sortable: false,
            value: 'ingredientName',
          },
          { text: 'Type', align: 'right', value: 'ingredientType' },
          { text: 'Actions', align: 'right', value: 'actions', sortable: false },
          
        ],

        newIngredient: {
          ingredientName: null,
          ingredientBenefits: [],
          ingredientType: null,
          ingredientIcon: null,
          tags: [],
        },
        ingredientTypeItems: [
          'protein',
          'fat',
          'vegetable',
          'fruit',
          'spice',
          'carb',
          'nut',
          'seed',
          'liquid'
          ],
        benefit: null,
        benefitCreditUrl: null,
        benefitCreditTitle: null,


        

      }
    },
    computed: {
      ...mapGetters('cms', [
        'checkInFeelingContent',
        'checkInThoughtContent'
      ]),
      ...mapGetters('auth', {
        profile: 'profile',
        loggedIn: 'loggedIn',
      }),
      ...mapGetters('admin', [

        'ingredientList',
      ]),


      


    },
    methods: {
      
      ...mapActions('user', [
        'getUserState',
        'updateNotificationHistory'
      ]),
      ...mapActions('admin', [
        'getIngredientList',
        'editIngredient',
        'addIngredient',
        'deleteIngredientItem'

      ]),

      ...mapActions('signin', [
        'googleLogin',
        'anonymousLogin',
        'autoSignIn'
      ]),
      ...mapActions('auth', [
        'setAutoSignInFlag',
        'setProfile',
        'setAnonymousProfile'
      ]),


      changeIngredientTypeSelect(event) {
        this.newIngredient.ingredientType = event
      },
      changeIngredientTypeSelectForEdits(event) {
        this.selectedIngredientItem.ingredientType = event
      },
      addIngredientBenefit () {
        var obj = {
          'benefit': this.benefit,
          'benefitCreditUrl': this.benefitCreditUrl,
          'benefitCreditTitle': this.benefitCreditTitle,
        }
        this.newIngredient.ingredientBenefits.push(obj)
        this.benefit = null
        this.benefitCreditUrl = null
        this.benefitCreditTitle = null
      },
      addIngredientBenefitForEdits () {
        var obj = {
          'benefit': this.benefit,
          'benefitCreditUrl': this.benefitCreditUrl,
          'benefitCreditTitle': this.benefitCreditTitle,
        }
        this.selectedIngredientItem.ingredientBenefits.push(obj)
        this.benefit = null
        this.benefitCreditUrl = null
        this.benefitCreditTitle = null
      },
      handleEditIngredient () {
        //this.addActionDialog = false
        this.editIngredientDialog = false
        var newIngredient = this.createEditIngredient()
        this.editIngredient(newIngredient)
      },
      addIngredientClick () {
        //reset
        this.addIngredientDialog = true
        this.resetIngredient()
      },
      handleAddIngredient() {
        this.addIngredientDialog = false
        var newIngredient = this.createNewIngredient()
        this.addIngredient(newIngredient)
        this.resetIngredient()
      },
      createEditIngredient () {
        var obj = {
          'ingredientId': this.selectedIngredientItem.ingredientId,
          'ingredientName': this.selectedIngredientItem.ingredientName,
          'ingredientBenefits': this.selectedIngredientItem.ingredientBenefits,
          'ingredientType': this.selectedIngredientItem.ingredientType,
          'ingredientIcon': this.selectedIngredientItem.ingredientIcon,
          'tags': (this.selectedIngredientItem) ? this.selectedIngredientItem.tags : [],
          
        }
        return obj
      },
      createNewIngredient () {
        var obj = {
          'ingredientName': this.newIngredient.ingredientName,
          'ingredientBenefits': this.newIngredient.ingredientBenefits,
          'ingredientType': this.newIngredient.ingredientType,
          'ingredientIcon': this.newIngredient.ingredientIcon,
          'tags': this.newIngredient.tags,
          
        }
        return obj
      },
      resetIngredient () {
        this.selectedIngredientItem = null
        this.newIngredient = {
          ingredientName: null,
          ingredientBenefits: [],
          ingredientType: null,
          ingredientIcon: null,
          tags: [],
        }
      },
      editThisIngredientItem(item) {

        this.selectedIngredientItem =  {
          ...item
        } 

        this.editIngredientDialog = true

      },
      deleteThisIngredientItem(item) {
        this.deleteIngredientItem(item)
      },
      closeTagsDialog() {
        this.editTagsDialog = false
      },
      editImage(item) {
        this.selectedIngredientItem = item
        this.$nextTick(()=>{
          this.editIngredientImageDialog = true
        })
        
        
      },
      editTags(item) {
        this.selectedIngredientItem = item
        this.$nextTick(() => {
          this.editTagsDialog = true
        })
        
      },

      
      
      

      


      async uploadImg(payload) {

        var type = payload.type
        //var iconColor = (payload.iconColor) ? payload.iconColor : null;

        if(event.target.files[0] == "") {return}
        var storageRef = firebase.storage().ref();
        //var imageName = null
        //var folderName = null
        var uploadTask = null
        var metadata = null
        
        this.imageData = event.target.files[0]
        
        //compress
        const width = 500
        const reader = new FileReader()
        
        
        reader.readAsDataURL(this.imageData)
        reader.onload = async (event) => {
          const img = new Image()
          img.src = event.target.result
          img.onload = async () => {
            const scaleFactor = width / img.width
            const elem = document.createElement('canvas')
            elem.width = width
            elem.height = scaleFactor * img.height
            const ctx = elem.getContext('2d')
            ctx.drawImage(img, 0, 0, width, scaleFactor*img.height)
            ctx.canvas.toBlob(async(blob) => {
              // imageName = (type == 'humanAction') ? 'actionIconImg' : 'routineIconImg'
              //folderName = '_grey'
              metadata = { 'contentType': 'image/jpg'};
              if(type === 'ingredient') {
                uploadTask = storageRef.child(`heediIngredientIcons/${this.selectedIngredientItem.ingredientName}`)
                                      //.put(this.imageData, metadata)
                                      .put(blob, metadata)
              }
              

              uploadTask.on(`state_changed`, async (snapshot) => {
                //this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                    //console.log('Upload is paused');
                    break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                    //console.log('Upload is running');
                    break;
                }
              },async (error) => {
                /* eslint-disable no-console */
                console.log(error.message)
              },async () => {
                  //this.uploadValue=100;
                  uploadTask.snapshot.ref.getDownloadURL().then(async(url) => {

                    if(type == `ingredient`) {
                      var ref = await db.collection('ingredients').doc(this.selectedIngredientItem.ingredientId)
                
                      
                        document.getElementById("file-input1").value = "";
                        await ref.set({
                            'ingredientIcon': url,

                        }, {merge: true})
                      

                      /* eslint-disable no-console */
                      console.log('File available at', url);
                      this.editIngredientImageDialog = false


                    }
                    

                  })
                },
              );
            }, this.imageData.type, 1)

          }
        }
        reader.onerror = (error) => {
          /* eslint-disable no-console */
          console.log(error)
        }
      },

      
    },
    beforeCreate () {
      if(!this.$store.state.cms) this.$store.registerModule('cms', cmsModule)
      if(!this.$store.state.user) this.$store.registerModule('user', userModule)
      if(!this.$store.state.signin) this.$store.registerModule('signin', signinModule)
      if(!this.$store.state.admin) this.$store.registerModule('admin', adminModule)
      if(!this.$store.state.experience) this.$store.registerModule('experience', experienceModule)
      if(!this.$store.state.settings) this.$store.registerModule('settings', settingsModule)
    },
    created () {
      //this.resetCheckIn()
      //gtag('config', 'G-H9RD8W4MLR', {'page_path': '/admin'});
      //this.autoSignIn()


      //this.getIntentMeanCategoryList()
      //this.getHumanActionList()
      //this.getRoutineList()

      this.getIngredientList()


      


      
    },
    async mounted () {
      var command = (this.$route.params.command) ? this.$route.params.command : null
      var type = (this.$route.params.type) ? this.$route.params.type : null
      //var resourceId = (this.$route.params.resourceId) ? this.$route.params.resourceId : null

      //if(resourceId) {
      //  resourceId = resourceId.replace(/-/g, ' ');   //resource could be
      //}
      if(type) {
        type = type.replace(/_/g, ' ')
        //type = type.replace(/-/g, ' ')
      }
      if(command) {
        switch(command) {
          case "add":   //add an existing routine to the the user's routineTemplates sub-collection, resourceId could be a routineId

            /*await this.addRoutineToUser({
              'routineType': type,
              'routineId': resourceId
            })
            this.$router.push('/console')*/
            break;

          case "create":   //create a new routine template; add a copy in routines collection and in user's routineTemplates sub-collection

            //if no type param, then need to show select dialog
            /*
            if(type) {

              document.getElementById('adminConsole').style.height = "6000px"
              document.getElementById('sequenceOverlayAdmin').style.height = "6000px"
              this.showOverlay({
                'ctaType': 'create_sequence',
                'sequenceType': type,
                'item': null,
              })
            }
            */

            break;


          case "modify":   //add an existing routine to the the user's routineTemplates sub-collection, resourceId could be a routineId
            //console.log("case modify")
            //use resourceId to pull the data
            //var result = await this.getRoutineData(resourceId)

            //this.showOverlay({
            //  'category': result.routineTemplate.intentMeanCategory,
            //  'ctaType': 'routine_modify',
            //  'parentCategory': null,
            //  'routineType': result.routineTemplate.routineType,
            //  'item': result.routineTemplate,
            //})
          

            break;

          

          default:

            break;
        }
      }
      
    },
    updated () {

    },
    watch: {

    }
  }
</script>

<style scoped>

.searchRoutinesHolder {
  text-align: right;
  width: 100%;
  padding-right: 10px;
  background-color: transparent;
}
.searchRoutines {
  display: inline-block;
  width: 300px;
  background-color: transparent;
}

.adminConsole {
  margin: 0 auto;  /* this centers the div on the page */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

#vApp {
 background-color: transparent;
}

.icon {
  max-width: 50px;
  max-height: 40px;
}

.adminHeader {
  background-color: white;
  text-align: center;
  padding-bottom: 20px;
}
.headerNavItem {
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
  padding: 7px;
}


.contentContainer {
  max-width: 900px;
  margin: 0 auto;
  min-height: 500px;
  padding-bottom: 100px;
}
.routineType {
  border: 1px solid grey;
  border-radius: 8px;
  margin: 10px;
  padding: 20px;
}
.formHolder {
  padding-right: 30px;
  padding-left: 30px;
}

.picIconHolder {
  display: inline-block;

  font-size: 1.3em;
  color: rgb(73, 73, 73);
  width: 100px;
  background-color: white;
  margin: 0 auto;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  padding: 5px;
  border: solid 1px grey;

}
</style>
