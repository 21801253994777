<template>
  <div class="adminConsole" id="adminConsole">
    <v-app id="vApp">

      <RoutineOverlayAdmin
        :routine-type="routineType"
        :parent-view="'Admin'"
        :selected-item="selectedItem"
        :id="`routineOverlayAdmin`"
        v-on:overlay-off="overlayOff"
        />

      <div class="adminHeader">
        <div class="headerNavItem">
          <br />
          <router-link to="/admin">Admin Home</router-link>
          <br>
        </div>
        <div class="headerNavItem">
          <br />
          <router-link to="/admin/ingredients/">Ingredients</router-link>
          <br>
        </div>
        <div class="headerNavItem">
          <br />
          <router-link to="/admin/meals/">Meals</router-link>
          <br>
        </div>
        <div class="headerNavItem">
          <br />
          <router-link to="/admin/actions/">Human Actions</router-link>
          <br>
        </div>
        <div class="headerNavItem">
          <br />
          <router-link to="/admin/sequences/">Sequences</router-link>
          <br>
        </div>
        <div class="headerNavItem">
          <br />
          <router-link to="/admin/routines/"><b>Routines</b></router-link>
          <br>
        </div>
      </div>
      <v-card flat>
        <div class="contentContainer" >



          <!-- SELECT ROUTINE TYPE DIALOG -->
          <v-dialog v-model="createRoutineDialog" persistent max-width="700px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                Create Routine
              </v-btn>
            </template>
            
            <v-card>
              <v-card-title>
                <span class="headline">Select routine type:</span>
              </v-card-title>
              <v-card-text>
                    <div>
                      <div class="routineType" @click="selectRoutineType(`work_sessions`)">
                        work sessions
                      </div>
                    </div>
                    <div>
                      <div class="routineType" @click="selectRoutineType(`exercise`)">
                        exercise
                      </div>
                    </div>
                    <div>
                      <div class="routineType" @click="selectRoutineType(`exercise2`)">
                        exercise w/periodization
                      </div>
                    </div>
                    <div>
                      <div class="routineType" @click="selectRoutineType(`everyday_habit`)">
                        everyday habit
                      </div>
                    </div>
                    <div>
                      <div class="routineType" @click="selectRoutineType(`event_follow-up`)">
                        event follow-up
                      </div>
                    </div>
                    <div>
                      <div class="routineType" @click="selectRoutineType(`cessation`)">
                        cessation
                      </div>
                    </div>
                    <div>
                      <div class="routineType" @click="selectRoutineType(`cooking`)">
                        cooking
                      </div>
                    </div>
                    
                    <div>
                      <div class="routineType" @click="selectRoutineType(`generic`)">
                        generic
                      </div>
                    </div>

                    <div>
                      <div class="routineType" @click="selectRoutineType(`list_draw`)">
                        list draw
                      </div>
                    </div>

                    <div>
                      <div class="routineType" @click="selectRoutineType(`event_prep`)">
                        event prep
                      </div>
                    </div>
                  
                  
                <small>*indicates required field</small>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="createRoutineDialog = false">Close</v-btn>
                <v-btn color="blue darken-1" text @click="createRoutineDialog = false">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>



          <!-- EDIT ROUTINE DIALOG -->
          <v-dialog v-model="editRoutineDialog" persistent max-width="700px" scrollable>
            <v-card>
              <v-card-title>
                <span class="headline">Edit Routine</span>
              </v-card-title>
              <v-card-text>
                <div class="formHolder">
                  <div>
                    <v-text-field 
                      label="Routine title*"
                      hint="a short, descriptive title"
                      v-model="newRoutine.routineTemplate.routineTitle"
                      required
                      ></v-text-field>
                  </div>
                  <div>
                    <v-combobox
                      :value="intentMeanCategoryListSelectForRoutine"               
                      :items="intentMeanCategoryList"
                      @change="changeTheCategoryListValue('intentMeanForRoutine', $event)"
                      label="Select an intent mean category, or add a new one"
                      v-bind:key="'intentMeanCategoryListForRoutines'"
                    ></v-combobox>
                  </div>
                  <div>
                      isFeatured?
                      <v-switch v-model="newRoutine.isFeatured" v-bind:key="'isFeatured'"></v-switch> 
                    </div>
                  <div>
                    <v-text-field 
                      label="Marketing Content - Headline*"
                      v-model="newRoutine.marketingContent.headline"
                      required
                      ></v-text-field>
                  </div>
                  <div>
                    <v-text-field 
                      label="Marketing Content - Subheadline*"
                      v-model="newRoutine.marketingContent.subheadline"
                      required
                      ></v-text-field>
                  </div>
                  <div>
                    <v-textarea
                      label="Marketing Content - Search Summary*"
                      v-model="newRoutine.marketingContent.summary"
                      required
                      auto-grow
                      ></v-textarea>
                  </div>
                  <div>
                    <v-textarea
                      label="Marketing Content - Routine Description*"
                      v-model="newRoutine.marketingContent.description"
                      required
                      auto-grow
                      ></v-textarea>
                  </div>

                  <!-- Helpful Links -->
                  <div>
                    <v-text-field 
                      label="Helpful Link 1 Title"
                      v-model="newRoutine.helpfulLinks.link1Title"
                      ></v-text-field>
                  </div>
                  <div>
                    <v-text-field 
                      label="Helpful Link 1 Url"
                      v-model="newRoutine.helpfulLinks.link1"
                      ></v-text-field>
                  </div>
                  <div>
                    <v-text-field 
                      label="Helpful Link 2 Title"
                      v-model="newRoutine.helpfulLinks.link2Title"
                      ></v-text-field>
                  </div>
                  <div>
                    <v-text-field 
                      label="Helpful Link 2 Url"
                      v-model="newRoutine.helpfulLinks.link2"
                      ></v-text-field>
                  </div>
                  <div>
                    <v-text-field 
                      label="Helpful Link 3 Title"
                      v-model="newRoutine.helpfulLinks.link3Title"
                      ></v-text-field>
                  </div>
                  <div>
                    <v-text-field 
                      label="Helpful Link 3 Url"
                      v-model="newRoutine.helpfulLinks.link3"
                      ></v-text-field>
                  </div>
                  <div>
                    <v-text-field 
                      label="Helpful Link 4 Title"
                      v-model="newRoutine.helpfulLinks.link4Title"
                      ></v-text-field>
                  </div>
                  <div>
                    <v-text-field 
                      label="Helpful Link 4 Url"
                      v-model="newRoutine.helpfulLinks.link4"
                      ></v-text-field>
                  </div>
                  
                  
                  
                  
                    
                </div>
                <small>*indicates required field</small>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="addActionDialog = false;editRoutineDialog = false">Close</v-btn>
                <v-btn v-if="editRoutineDialog" color="blue darken-1" text @click="handleEditRoutine">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>


          <!-- EDIT ROUTINE IMAGE DIALOG -->
          <v-dialog v-model="editRoutineImageDialog" persistent max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">Edit Routine Image</span>
              </v-card-title>
              <v-card-text>
                <v-list
                  subheader
                  three-line
                >
                  <v-subheader>Icons</v-subheader>
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>Main Routine Icon</v-list-tile-title>
                      <v-list-tile-sub-title>The primary icon used to represent this routine</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <div>
                    <div class="picIconHolder" v-if="selectedItem">
                      
                      <div class="image-upload">
                        <label @click="$refs.inputUpload2_mainBlack.click()" id="label2_mainBlack">
                          <div v-if="selectedItem.routineTemplate.iconImageBlack">
                            <img :src="selectedItem.routineTemplate.iconImageBlack" width="50px">
                          </div>
                          <div v-else>
                            Black Icon<br /><i class="fas fa-camera"></i>
                          </div>
                          
                        </label>
                        <input v-show="false" id="file-input2-mainBlack" ref="inputUpload2_mainBlack" type="file" @change="uploadImg({'type': 'routine', 'iconColor': 'black'})" />
                      </div>
                    </div>
                    <div class="picIconHolder" v-if="selectedItem">
                      
                      <div class="image-upload">
                          <label @click="$refs.inputUpload2_mainGrey.click()" id="label2_mainGrey">
                            <div v-if="selectedItem.routineTemplate.iconImageGrey">
                              <img :src="selectedItem.routineTemplate.iconImageGrey" width="50px">
                            </div>
                            <div v-else>
                              Grey Icon<br /><i class="fas fa-camera"></i>
                            </div>
                          </label>
                          <input v-show="false" id="file-input2-mainGrey" ref="inputUpload2_mainGrey" type="file" @change="uploadImg({'type': 'routine', 'iconColor': 'grey'})" />
                      </div>
                    </div>
                  </div>
                  <br>
                </v-list>

                <v-divider></v-divider>
                <br>
                <v-list
                  subheader
                  two-line
                >

                  <v-subheader>Detail Page Images</v-subheader>

                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>Callout Image</v-list-tile-title>
                      <v-list-tile-sub-title>(add callout image spec requirements here)</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <div>
                    <div class="picIconHolder" v-if="selectedItem">
                      
                      <div class="image-upload">
                        <label @click="$refs.inputUpload2_callout.click()" id="label2_callout">
                          <div v-if="selectedItem.marketingContent.images.detailPage.calloutImage">
                            <img :src="selectedItem.marketingContent.images.detailPage.calloutImage.url" width="50px">
                          </div>
                          <div v-else>
                            Callout Image<br /><i class="fas fa-camera"></i>
                          </div>
                        </label>
                        <input v-show="false" id="file-input2-callout" ref="inputUpload2_callout" type="file" @change="uploadImg({'type': 'routine-callout'})" />
                      </div>
                    
                    </div>
                    <div v-if="selectedItem">
                      <div v-if="selectedItem.marketingContent.images.detailPage.calloutImage">
                          <v-text-field 
                            label="Photo Credit"
                            v-model.lazy="selectedItem.marketingContent.images.detailPage.calloutImage.credit"
                            required
                            @change="updateTheCredit(selectedItem.routineId)"
                            ></v-text-field>
                        </div>
                    </div>
                  </div>
                  <br>

                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>Content Images</v-list-tile-title>
                      <v-list-tile-sub-title>(add hero image spec requirements here)</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <div>
                    <div class="picIconHolder" v-if="selectedItem">
                      
                      <div class="image-upload">
                        <label @click="$refs.inputUpload1_content1.click()" id="label1_content1">
                          <div v-if="selectedItem.marketingContent.images.detailPage.contentImages.contentImg1">
                            <img :src="selectedItem.marketingContent.images.detailPage.contentImages.contentImg1" width="50px">
                          </div>
                          <div v-else>
                            Content 1<br /><i class="fas fa-camera"></i>
                          </div>
                        </label>
                        <input v-show="false" id="file-input1-content1" ref="inputUpload1_content1" type="file" @change="uploadImg({'type': 'routine-content1'})" />
                      </div>
                    </div>
                    <div class="picIconHolder" v-if="selectedItem">
                      
                      <div class="image-upload">
                        <label @click="$refs.inputUpload2_content2.click()" id="label2_content2">
                          <div v-if="selectedItem.marketingContent.images.detailPage.contentImages.contentImg2">
                            <img :src="selectedItem.marketingContent.images.detailPage.contentImages.contentImg2" width="50px">
                          </div>
                          <div v-else>
                            Content 2<br /><i class="fas fa-camera"></i>
                          </div>
                        </label>
                        <input v-show="false" id="file-input2-content2" ref="inputUpload2_content2" type="file" @change="uploadImg({'type': 'routine-content2'})" />
                      </div>
                    </div>
                  </div>
                  <br>
                </v-list>

              <!--
              <v-card-text>
                <div class="formHolder">
                  <div class="picIconHolder" v-if="selectedItem">
                    <div v-if="selectedItem.iconImageBlack">
                      <img :src="selectedItem.iconImageBlack" width="50px">
                    </div>
                    <div v-else class="image-upload">
                        <label @click="$refs.inputUpload2_black.click()" id="label2_black">
                          <div>
                            Black Icon<br /><i class="fas fa-camera"></i>
                          </div>
                        </label>
                        <input v-show="false" id="file-input2-black" ref="inputUpload2_black" type="file" @change="uploadImg({'type': 'routine', 'iconColor': 'black'})" />
                    </div>
                  </div>
                  <div class="picIconHolder" v-if="selectedItem">
                    <div v-if="selectedItem.iconImageGrey">
                      <img :src="selectedItem.iconImageGrey" width="50px">
                    </div>
                    <div v-else class="image-upload">
                        <label @click="$refs.inputUpload2_grey.click()" id="label2_grey">
                          <div>
                            Grey Icon<br /><i class="fas fa-camera"></i>
                          </div>
                        </label>
                        <input v-show="false" id="file-input2-grey" ref="inputUpload2_grey" type="file" @change="uploadImg({'type': 'routine', 'iconColor': 'grey'})" />
                    </div>
                  </div>
                    
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="editRoutineImageDialog = false">Close</v-btn>
              </v-card-actions>
              -->
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="editRoutineImageDialog = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>


          <!-- EDIT ROUTINE TAGS DIALOG -->
          <v-dialog v-model="editRoutineTagsDialog" persistent max-width="1000px" scrollable>
            
                  
            <TagManager 
              :selected-item="selectedItem" 
              v-on:close="closeTagsDialog"
              :tag-type="`routine`"
              />


                    
                
          </v-dialog>



          <br />
          <div class="searchRoutinesHolder">
            <div class="searchRoutines">
              <v-text-field
                v-model="searchRoutines"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </div>
          </div>
          <br />
          <v-data-table
            :headers="routineDataHeaders"
            :search="searchRoutines"
            :items="routineList"
            item-key="routineTemplate.routineTitle"
            class="elevation-1"
            :pagination.sync="pagination"
          >
            
            
            <template v-slot:items="props2">
                  <tr @click="props2.expanded = !props2.expanded">
                    <td style="padding:15px;text-align: center;"><img :src="props2.item.routineTemplate.iconImageBlack" class="icon" v-if="props2.item.routineTemplate.iconImageBlack"></td>
                    <td> {{ props2.item.routineTemplate.routineTitle }}</td>
                    <td class="text-xs-right">{{ props2.item.routineTemplate.routineType }}</td>
                    <td class="text-xs-right">{{ props2.item.routineTemplate.intentMeanCategory }}</td>
                    
                    <td class="text-xs-right">
                      &nbsp;&nbsp;&nbsp;
                      <i class="fas fa-pencil-alt" @click="editThisRoutineItem(props2.item)"></i>
                      &nbsp;&nbsp;&nbsp;
                      <i class="fas fa-camera" @click="editRoutineImage(props2.item)"></i>
                      &nbsp;&nbsp;&nbsp;
                      <i class="fas fa-hashtag" @click="editRoutineTags(props2.item)"></i>
                      &nbsp;&nbsp;&nbsp;
                      <i class="fas fa-trash-alt" @click="deleteThisRoutineItem(props2.item)"></i>

                    </td>
                  </tr>
                  
                  
                  
                </template>
            
          </v-data-table>
        </div>
      </v-card>

    </v-app>
  </div>
</template>

<script>
    import '@/plugins/vuetify'
    import firebase from '@/firebase'
    import cmsModule from '@/store/cms'
    import signinModule from '@/store/signin'
    import userModule from '@/store/user'
    import adminModule from '@/store/admin'
    import experienceModule from '@/store/experience'
    import RoutineOverlayAdmin from '@/components/RoutineOverlayAdmin'
    import TagManager from '@/components/TagManager'
    import settingsModule from '@/store/settings'
    import db from '@/db'
    
    //import NextStepper from '@/components/NextStepper'
    
    import { mapGetters, mapActions } from 'vuex'
    //import * as use from '@tensorflow-models/universal-sentence-encoder';

  export default {
    name: "admin",
    components: {
      RoutineOverlayAdmin,
      TagManager
    },
    data () {
      return {
        selectSequenceDialog: false,
        selectedItem: null,
        routineType: null,
        searchRoutines: '',
        pagination: {
          rowsPerPage: 10
        },

        
        addRoutineDialog: false,
        createRoutineDialog: false,



        rules: [
          (v) => !!v || 'Statement is required',
          (v) => v.length <= 60 || 'Max 60 characters'
        ],
        
        imageData: null,
        compressedImageData: null,

        sequenceItemTitle: null,
        

        routineDataHeaders: [
          { text: 'Icon', align: 'start', value: 'routineIconImage' },
          {
            text: 'Routine Title',
            align: 'left',
            sortable: false,
            value: 'routineTemplate.routineTitle',
          },
          { text: 'Type', align: 'right', value: 'routineTemplate.routineType' },
          { text: 'Intent Mean Category', align: 'right', value: 'routineTemplate.intentMeanCategory' },
          { text: 'Actions', align: 'right', value: 'actions', sortable: false },
        ],

        editRoutineDialog: false,
        editRoutineImageDialog: false,
        editRoutineTagsDialog: false,
        newRoutine: {
          isFeatured: false,
          marketingContent: {
            bullets: null,
            description: null,
            headline: null,
            subheadline: null,
            summary: null,
          },
          routineTemplate: {
            routineTitle: null,
            intentMeanCategory: null,
          },
          helpfulLinks: {
            link1: null,
            link1Title: null,
            link2: null,
            link2Title: null,
            link3: null,
            link3Title: null,
            link4: null,
            link4Title: null,
          }
          
          
        },

        /*
        selectedHumanActionItem: null,
        editHumanActionImageDialog: false,
        editActionDialog: false,
        editRoutineDialog: false,
        editTagsDialog: false,
        editRoutineImageDialog: false,
        editRoutineTagsDialog: false,
        newHumanAction: {
          
          actionId: null,
          actionLevel: null,
          actionLoadType: null,
          actionType: null,
          actionUom: null,
          agendaItemTitle: null,
          dateCreated: null,
          iconImageBlack: null,
          iconImageGrey: null,
          intensityType: null,
          intentMeanCategory: null,
          isAerobic: false,
          isAnaerobic: false,
          isIsometric: false,
          isLoadable: false,
          tags: [],
          vocabulary: {
            pastUsageVerb: null,
            presentUsageVerb: null,
            uomSingular: null,
            uomPlural: null,
          },
          volumeType: null,
          
        },

        */
        

      }
    },
    computed: {
      ...mapGetters('cms', [
        'checkInFeelingContent',
        'checkInThoughtContent'
      ]),
      ...mapGetters('auth', {
        profile: 'profile',
        loggedIn: 'loggedIn',
      }),
      ...mapGetters('admin', [
        'intentMeanCategoryList',
        'intentMeanCategoryListSelect',
        'intentMeanCategoryListSelectForRoutine',
        //'humanActionList',
        'routineList',
        //'sequenceList',
      ]),


      /*
      volumeTypeSelectItemsForEdits () {
        if(this.selectedHumanActionItem.isIsometric) {
          return ['duration', 'none']
        }else {
          return ['items','reps', 'distance', 'duration', 'tabata', 'none']
        }
      },

      intensityTypeSelectItemsForEdits () {
        if(this.selectedHumanActionItem.isAnaerobic) {
          return ['pace','%1rm', '%effort', 'load', 'tabata', 'none']
        }else if(this.selectedHumanActionItem.isAerobic){
          return ['pace', '%effort', '%mhr', 'tabata', 'none']
        }else if(this.selectedHumanActionItem.isIsometric){
          return ['load', 'none']
        }else{
          return ['completion rate', 'none']
        }
      },

      volumeTypeSelectItemsForAdds () {
        if(this.newHumanAction.isIsometric) {
          return ['duration', 'none']
        }else {
          return ['items','reps', 'distance', 'duration', 'tabata', 'none']
        }
      },

      intensityTypeSelectItemsForAdds () {
        if(this.newHumanAction.isAnaerobic) {
          return ['pace','%1rm', '%effort', 'load', 'tabata', 'none']
        }else if(this.newHumanAction.isAerobic){
          return ['pace', '%effort', '%mhr', 'tabata', 'none']
        }else if(this.newHumanAction.isIsometric){
          return ['load', 'none']
        }else{
          return ['completion rate', 'none']
        }
      },
      */


    },
    methods: {
      
      ...mapActions('user', [
        'getUserState',
        'updateNotificationHistory'
      ]),
      ...mapActions('admin', [
        'getIntentMeanCategoryList',
        //'setIntentMeanCategoryListSelect',
        'setIntentMeanCategoryListSelectForRoutine',
        //'resetIntentMeanCategoryListSelect',
        'resetIntentMeanCategoryListSelectForRoutine',
        //'addHumanAction',
        //'addRoutine',
        //'getHumanActionList',
        //'getRoutineData',
        'getRoutineList',
        //'getSequenceList',
        //'editHumanAction',
        'editRoutine',
        //'deleteActionItem',
        'deleteRoutineItem',
        'changeCategoryListValue',
        'updateCredit'
      ]),

      ...mapActions('signin', [
        'googleLogin',
        'anonymousLogin',
        'autoSignIn'
      ]),
      ...mapActions('auth', [
        'setAutoSignInFlag',
        'setProfile',
        'setAnonymousProfile'
      ]),
      editThisRoutineItem(item) {
        //reset
        this.resetNewRoutine()

        //set
        this.selectedItem = item
        this.newRoutine.routineTemplate.routineTitle = item.routineTemplate.routineTitle
        this.setIntentMeanCategoryListSelectForRoutine(item.routineTemplate.intentMeanCategory)
        this.newRoutine.marketingContent.description = item.marketingContent.description
        this.newRoutine.marketingContent.headline = item.marketingContent.headline
        this.newRoutine.marketingContent.subheadline = item.marketingContent.subheadline
        this.newRoutine.marketingContent.summary = item.marketingContent.summary
        this.newRoutine.isFeatured = item.isFeatured

        this.newRoutine.helpfulLinks.link1 = (item.helpfulLinks.link1) ? item.helpfulLinks.link1 : null
        this.newRoutine.helpfulLinks.link2 = (item.helpfulLinks.link2) ? item.helpfulLinks.link2 : null
        this.newRoutine.helpfulLinks.link3 = (item.helpfulLinks.link3) ? item.helpfulLinks.link3 : null
        this.newRoutine.helpfulLinks.link4 = (item.helpfulLinks.link4) ? item.helpfulLinks.link4 : null
        this.newRoutine.helpfulLinks.link1Title = (item.helpfulLinks.link1Title) ? item.helpfulLinks.link1Title : null
        this.newRoutine.helpfulLinks.link2Title = (item.helpfulLinks.link2Title) ? item.helpfulLinks.link2Title : null
        this.newRoutine.helpfulLinks.link3Title = (item.helpfulLinks.link3Title) ? item.helpfulLinks.link3Title : null
        this.newRoutine.helpfulLinks.link4Title = (item.helpfulLinks.link4Title) ? item.helpfulLinks.link4Title : null

        //this.addRoutineDialog = true
        this.editRoutineDialog = true

      },
      deleteThisRoutineItem(item) {
        this.deleteRoutineItem(item)
      },
       closeTagsDialog() {
        this.editRoutineTagsDialog = false
      },
      editRoutineImage(item) {
        this.selectedItem = item
        this.editRoutineImageDialog = true
      },
      editRoutineTags(item) {
        this.selectedItem = item
        this.editRoutineTagsDialog = true
      },
      closeRoutineTagsDialog(){
        this.editRoutineTagsDialog = false
      },
      updateTheCredit (id) {
        var obj = {
          'routineId': id,
          'credit': this.selectedItem.marketingContent.images.detailPage.calloutImage.credit,
        }
        this.updateCredit(obj)

      },
      overlayOff () {
        this.routinetype = null
        //this.$router.push('/admin')
        document.getElementById("routineOverlayAdmin").style.display = "none";
      },
      showOverlay (payload) {
        this.selectedItem = payload.item
        this.routineType = payload.routineType
        document.getElementById("vApp").style.minHeight = "6000px";
        window.scrollTo(0,0)
        document.getElementById("routineOverlayAdmin").style.display = "block";
      },
      selectRoutineType(type) {
        this.createRoutineDialog = false
        this.$router.push('/admin/routines/create/'+type+'/')
      },
     
      async submit (thoughtObj) {
        try {
          
            this.thoughtObj = thoughtObj
            this.e6 = 3
            var checkInObj = {
              'thoughtObj': thoughtObj,
              'feelingObj': this.feelingObj
            }


            //check for logged in user
            var unsubscribe = firebase.auth().onAuthStateChanged(async(user) =>{   //Commenting out this code stopped multiple Minders from being created.  I could unsubscribe.
              //var user = firebase.auth().currentUser;  //This line works, but want to see if I can unsubscribe
              unsubscribe()
              var acctType = this.$store.state.auth.profile.accountType
              var loggedInBool = this.$store.state.auth.loggedIn
              if(!user) {
                //console.log("Submitting checkin, no user")
                if(loggedInBool === true) {
                  //console.log("loggedInBool equals true")
                  //heediUser
                  if(acctType === 'heediUser') {
                    //console.log("acctType is heediUser")
                    await this.googleLogin()
                  }
                  //anonymous
                  else if(acctType === 'anonymous') {
                    //console.log("acctType is anonymous")
                    await this.anonymousLogin()
                  }
                  this.submitCheckIn(checkInObj)
                  //this.setMinderExperienceState({state:'checkedIn', userUid: this.$store.state.auth.profile.uid})
                  this.setShowCheckInCard(true)
                  this.$router.push('/console')
                }

              } else {
                //console.log("Submitting checkin, there is a user")
                //heediUser
                if(acctType == 'heediUser') {
                  //console.log("acctType is heediUser")
                  await this.setProfile(user)
                }
                //anonymous
                if(acctType == 'anonymous') {
                  //console.log("acctType is anonymous")
                  await this.setAnonymousProfile(user)
                }
                await this.getUserState(user.uid)
                this.submitCheckIn(checkInObj)
                //this.setXpState({state:'checkedIn', userUid: this.$store.state.auth.profile.uid})
                this.setShowCheckInCard(true)
                this.$router.push('/console')

                //configure Google Analytics with user uid
                //gtag('config', 'G-H9RD8W4MLR', {
                //  'user_id': user.uid
                //})
              }
            })

            //gtag('event', 'checked_in') 

        } catch (error) {
          alert(error)
        }
      },

      handleEditRoutine () {
        this.editRoutineDialog = false
        var newUpdate = this.createNewRoutineUpdate()  
        this.editRoutine({'update':newUpdate, 'routineId': this.selectedItem.routineId})
        this.resetNewRoutine()
      },
      createNewRoutineUpdate () {
        var obj = {
          'isFeatured': this.newRoutine.isFeatured,
          'routineTemplate': {
            'routineTitle': this.newRoutine.routineTemplate.routineTitle,
            'intentMeanCategory': this.intentMeanCategoryListSelectForRoutine[0],
          },
          'marketingContent': {
            'headline': this.newRoutine.marketingContent.headline,
            'subheadline': this.newRoutine.marketingContent.subheadline,
            'description': this.newRoutine.marketingContent.description,
            'summary': this.newRoutine.marketingContent.summary,
          },
          'helpfulLinks': {
            'link1': this.newRoutine.helpfulLinks.link1,
            'link1Title': this.newRoutine.helpfulLinks.link1Title,
            'link2': this.newRoutine.helpfulLinks.link2,
            'link2Title': this.newRoutine.helpfulLinks.link2Title,
            'link3': this.newRoutine.helpfulLinks.link3,
            'link3Title': this.newRoutine.helpfulLinks.link3Title,
            'link4': this.newRoutine.helpfulLinks.link4,
            'link4Title': this.newRoutine.helpfulLinks.link4Title,
          }
        }
        return obj
      },
      resetNewRoutine () {

        this.newRoutine = {
          isFeatured: false,
          marketingContent: {
            bullets: null,
            description: null,
            headline: null,
            subheadline: null,
            summary: null,
          },
          routineTemplate: {
            intentMeanCategory: null,
            routineTitle: null,
          },
          helpfulLinks: {
            link1: null,
            link1Title: null,
            link2: null,
            link2Title: null,
            link3: null,
            link3Title: null,
            link4: null,
            link4Title: null,
          }
          
          
        }

      },

      changeTheCategoryListValue(type, event) {
        var obj = {
          'type': type,
          'event': event
        }
        this.changeCategoryListValue(obj)
      },
      
      
      

      


      /*
      addHumanActionClick () {
        //reset
        this.addActionDialog = true
        this.resetNewHumanAction()
      },
      addRoutineClick () {
        this.resetNewRoutine()
      },
      closeEditActionItem() {
        //this.newHumanAction.intentMeanCategory = null
      },
      editThisActionItem(item) {

        this.selectedHumanActionItem =  {
          ...item
        } 

        this.editActionDialog = true

      },
      deleteThisActionItem(item) {
        this.deleteActionItem(item)
      },
      editThisRoutineItem(item) {
        //reset
        this.resetNewRoutine()

        //set
        this.selectedItem = item
        this.newRoutine.routineTemplate.routineTitle = item.routineTemplate.routineTitle
        this.setIntentMeanCategoryListSelectForRoutine(item.routineTemplate.intentMeanCategory)
        this.newRoutine.marketingContent.description = item.marketingContent.description
        this.newRoutine.marketingContent.headline = item.marketingContent.headline
        this.newRoutine.marketingContent.subheadline = item.marketingContent.subheadline
        this.newRoutine.marketingContent.summary = item.marketingContent.summary

        this.newRoutine.helpfulLinks.link1 = (item.helpfulLinks.link1) ? item.helpfulLinks.link1 : null
        this.newRoutine.helpfulLinks.link2 = (item.helpfulLinks.link2) ? item.helpfulLinks.link2 : null
        this.newRoutine.helpfulLinks.link3 = (item.helpfulLinks.link3) ? item.helpfulLinks.link3 : null
        this.newRoutine.helpfulLinks.link4 = (item.helpfulLinks.link4) ? item.helpfulLinks.link4 : null
        this.newRoutine.helpfulLinks.link1Title = (item.helpfulLinks.link1Title) ? item.helpfulLinks.link1Title : null
        this.newRoutine.helpfulLinks.link2Title = (item.helpfulLinks.link2Title) ? item.helpfulLinks.link2Title : null
        this.newRoutine.helpfulLinks.link3Title = (item.helpfulLinks.link3Title) ? item.helpfulLinks.link3Title : null
        this.newRoutine.helpfulLinks.link4Title = (item.helpfulLinks.link4Title) ? item.helpfulLinks.link4Title : null

        //this.addRoutineDialog = true
        this.editRoutineDialog = true

      },
      deleteThisRoutineItem(item) {
        this.deleteRoutineItem(item)
      },
      
      handleAddAction() {
        this.addActionDialog = false
        var newAction = this.createNewHumanAction()
        this.addHumanAction(newAction)
        this.resetNewHumanAction()
      },
      
      handleEditAction () {
        //this.addActionDialog = false
        this.editActionDialog = false
        var newAction = this.createEditHumanAction()
        this.editHumanAction(newAction)
      },
      handleEditRoutine () {
        this.editRoutineDialog = false
        var newUpdate = this.createNewRoutineUpdate()  
        this.editRoutine({'update':newUpdate, 'routineId': this.selectedItem.routineId})
        this.resetNewRoutine()
      },
      createNewHumanAction () {
        var obj = {
          'actionId': this.newHumanAction.actionId,
          'actionLevel': this.newHumanAction.actionLevel,
          'actionLoadType': this.newHumanAction.actionLoadType,
          'actionType': this.newHumanAction.actionType,
          'actionUom': this.newHumanAction.actionUom,
          'agendaItemTitle': this.newHumanAction.agendaItemTitle,
          'iconImageBlack': this.newHumanAction.iconImageBlack,  //these get added manually later
          'iconImageGrey': this.newHumanAction.iconImageGrey,   //this gets added manually later
          'intensityType': this.newHumanAction.intensityType,
          'intentMeanCategory': this.newHumanAction.intentMeanCategory,
          'isAerobic': this.newHumanAction.isAerobic,
          'isAnaerobic': this.newHumanAction.isAnaerobic,
          'isIsometric': this.newHumanAction.isIsometric,
          'isLoadable': this.newHumanAction.isLoadable,
          'tags': this.newHumanAction.tags,
          'vocabulary': {
            'pastUsageVerb': this.newHumanAction.vocabulary.pastUsageVerb,
            'presentUsageVerb': this.newHumanAction.vocabulary.presentUsageVerb,
            'uomSingular': this.newHumanAction.vocabulary.uomSingular,
            'uomPlural': this.newHumanAction.vocabulary.uomPlural,
          },
          'volumeType': this.newHumanAction.volumeType,
        }
        return obj
      },
      createEditHumanAction () {
        var obj = {
          'actionId': this.selectedHumanActionItem.actionId,
          'actionLevel': this.selectedHumanActionItem.actionLevel,
          'actionLoadType': this.selectedHumanActionItem.actionLoadType,
          'actionType': this.selectedHumanActionItem.actionType,
          'actionUom': (this.selectedHumanActionItem.actionUom) ? this.selectedHumanActionItem.actionUom : null,
          'agendaItemTitle': this.selectedHumanActionItem.agendaItemTitle,
          'iconImageBlack': (this.selectedHumanActionItem) ? this.selectedHumanActionItem.iconImageBlack : null,  //these get added manually later
          'iconImageGrey': (this.selectedHumanActionItem) ? this.selectedHumanActionItem.iconImageGrey : null,   //this gets added manually later
          'intensityType': this.selectedHumanActionItem.intensityType,
          'intentMeanCategory': this.selectedHumanActionItem.intentMeanCategory,
          'isAerobic': this.selectedHumanActionItem.isAerobic,
          'isAnaerobic': this.selectedHumanActionItem.isAnaerobic,
          'isIsometric': this.selectedHumanActionItem.isIsometric,
          'isLoadable': this.selectedHumanActionItem.isLoadable,
          'tags': (this.selectedHumanActionItem) ? this.selectedHumanActionItem.tags : [],
          'vocabulary': {
            'pastUsageVerb': this.selectedHumanActionItem.vocabulary.pastUsageVerb,
            'presentUsageVerb': this.selectedHumanActionItem.vocabulary.presentUsageVerb,
            'uomSingular': this.selectedHumanActionItem.vocabulary.uomSingular,
            'uomPlural': this.selectedHumanActionItem.vocabulary.uomPlural,
          },
          'volumeType': this.selectedHumanActionItem.volumeType,
          
        }
        return obj
      },
      createNewRoutineUpdate () {
        var obj = {
          'routineTemplate': {
            'routineTitle': this.newRoutine.routineTemplate.routineTitle,
            'intentMeanCategory': this.intentMeanCategoryListSelectForRoutine[0],
          },
          'marketingContent': {
            'headline': this.newRoutine.marketingContent.headline,
            'subheadline': this.newRoutine.marketingContent.subheadline,
            'description': this.newRoutine.marketingContent.description,
            'summary': this.newRoutine.marketingContent.summary,
          },
          'helpfulLinks': {
            'link1': this.newRoutine.helpfulLinks.link1,
            'link1Title': this.newRoutine.helpfulLinks.link1Title,
            'link2': this.newRoutine.helpfulLinks.link2,
            'link2Title': this.newRoutine.helpfulLinks.link2Title,
            'link3': this.newRoutine.helpfulLinks.link3,
            'link3Title': this.newRoutine.helpfulLinks.link3Title,
            'link4': this.newRoutine.helpfulLinks.link4,
            'link4Title': this.newRoutine.helpfulLinks.link4Title,
          }
        }
        return obj
      },
      resetNewHumanAction () {
        this.resetIntentMeanCategoryListSelect()
        this.newHumanAction = {
          actionId: null,
          actionLevel: null,
          actionLoadType: null,
          actionType: null,
          actionUom: null,
          agendaItemTitle: null,
          iconImageBlack: null,
          iconImageGrey: null,
          intensityType: null,
          intentMeanCategory: null,
          isLoadable: false,
          isIsometric: false,
          isAerobic: false,
          isAnaerobic: false,
          tags: [],
          vocabulary: {
            pastUsageVerb: null,
            presentUsageVerb: null,
            uomSingular: null,
            uomPlural: null,
          },
          volumeType: null,
          
        }
      },
      resetNewRoutine () {

        this.newRoutine = {
          marketingContent: {
            bullets: null,
            description: null,
            headline: null,
            subheadline: null,
            summary: null,
          },
          routineTemplate: {
            routineTitle: null,
          },
          helpfulLinks: {
            link1: null,
            link1Title: null,
            link2: null,
            link2Title: null,
            link3: null,
            link3Title: null,
            link4: null,
            link4Title: null,
          }
          
          
        }

      },

      changeCategoryListValue(type, event) {
        this.$store.dispatch("admin/changeCategoryListValue", { type, event }); 
        this.newHumanAction.intentMeanCategory = event
      },
      changeActionTypeSelect(event) {
        this.newHumanAction.actionType = event
      },
      changeActionLevelSelect(event) {
        this.newHumanAction.actionLevel = event
      },
      changeVolumeTypeSelect(event) {
        this.newHumanAction.volumeType = event
      },
      changeIntensityTypeSelect(event) {
        this.newHumanAction.intensityType = event
      },
      editImage(item) {
        this.selectedHumanActionItem = item
        this.$nextTick(()=>{
          this.editHumanActionImageDialog = true
        })
        
        
      },
      editTags(item) {
        this.selectedHumanActionItem = item
        this.$nextTick(() => {
          this.editTagsDialog = true
        })
        
      },
      closeTagsDialog() {
        this.editTagsDialog = false
        this.editRoutineTagsDialog = false
      },
      editRoutineImage(item) {
        this.selectedItem = item
        this.editRoutineImageDialog = true
      },
      editRoutineTags(item) {
        this.selectedItem = item
        this.editRoutineTagsDialog = true
      },
      closeRoutineTagsDialog(){
        this.editRoutineTagsDialog = false
      },

      */
      
      

      


      async uploadImg(payload) {

        var type = payload.type
        var iconColor = (payload.iconColor) ? payload.iconColor : null;

        if(event.target.files[0] == "") {return}
        var storageRef = firebase.storage().ref();
        //var imageName = null
        var folderName = null
        var uploadTask = null
        var metadata = null
        
        this.imageData = event.target.files[0]
        
        //compress
        const width = 500
        const reader = new FileReader()
        
        
        reader.readAsDataURL(this.imageData)
        reader.onload = async (event) => {
          const img = new Image()
          img.src = event.target.result
          img.onload = async () => {
            const scaleFactor = width / img.width
            const elem = document.createElement('canvas')
            elem.width = width
            elem.height = scaleFactor * img.height
            const ctx = elem.getContext('2d')
            ctx.drawImage(img, 0, 0, width, scaleFactor*img.height)
            ctx.canvas.toBlob(async(blob) => {
              // imageName = (type == 'humanAction') ? 'actionIconImg' : 'routineIconImg'
              folderName = (iconColor == 'black') ? '_black' : '_grey'
              metadata = { 'contentType': 'image/jpg'};
              if(type === 'humanAction') {
                uploadTask = storageRef.child(`heediHumanActionIcons${folderName}/${this.selectedHumanActionItem.agendaItemTitle}`)
                                      //.put(this.imageData, metadata)
                                      .put(blob, metadata)
              }else if(type === 'routine') {
                uploadTask = storageRef.child(`heediRoutineIcons${folderName}/${this.selectedItem.routineId}`)
                                      //.put(this.imageData, metadata)
                                      .put(blob, metadata)
              }else if(type === 'routine-callout') {
                uploadTask = storageRef.child(`heediRoutineCalloutImages/${this.selectedItem.routineId}`)
                                      //.put(this.imageData, metadata)
                                      .put(blob, metadata)
              }else if(type === 'routine-content1') {
                uploadTask = storageRef.child(`heediRoutineContentImages/${this.selectedItem.routineId}-1`)
                                      //.put(this.imageData, metadata)
                                      .put(blob, metadata)
              }else if(type === 'routine-content2') {
                uploadTask = storageRef.child(`heediRoutineContentImages/${this.selectedItem.routineId}-2`)
                                      //.put(this.imageData, metadata)
                                      .put(blob, metadata)
              }
              

              uploadTask.on(`state_changed`, async (snapshot) => {
                //this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                    //console.log('Upload is paused');
                    break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                    //console.log('Upload is running');
                    break;
                }
              },async (error) => {
                /* eslint-disable no-console */
                console.log(error.message)
              },async () => {
                  //this.uploadValue=100;
                  uploadTask.snapshot.ref.getDownloadURL().then(async(url) => {

                    if(type == `humanAction`) {
                      var ref = await db.collection('human_actions').doc(this.selectedHumanActionItem.actionId)
                
                      if(iconColor == 'black') {
                        document.getElementById("file-input1-black").value = "";
                        await ref.set({
                            'iconImageBlack': url,

                        }, {merge: true})
                      }else if(iconColor == 'grey') {
                        document.getElementById("file-input1-grey").value = "";
                        await ref.set({
                            'iconImageGrey': url,

                        }, {merge: true})
                      }

                      /* eslint-disable no-console */
                      console.log('File available at', url);
                      this.editHumanActionImageDialog = false


                    }else if(type == `routine`) {
                      var ref2 = await db.collection('routines').doc(this.selectedItem.routineId)
                      
                      if(iconColor == 'black') {
                        document.getElementById("file-input2-mainBlack").value = "";
                        await ref2.set({
                          'routineTemplate': {
                            'iconImageBlack': url,
                          }

                        }, {merge: true})
                      }else if(iconColor == 'grey') {
                        document.getElementById("file-input2-mainGrey").value = "";
                        await ref2.set({
                          'routineTemplate': {
                            'iconImageGrey': url,
                          }

                        }, {merge: true})
                      }
                      

                      /* eslint-disable no-console */
                      console.log('File available at', url);
                      this.editRoutineImageDialog = false
                      
                    }else if(type == `routine-callout`) {
                      var ref3 = await db.collection('routines').doc(this.selectedItem.routineId)
                      document.getElementById("file-input2-callout").value = "";
                      await ref3.set({
                        'marketingContent': {
                          'images': {
                            'detailPage': {
                              'calloutImage': {
                                'url': url,
                                'credit': null,
                              },
                            }
                          }
                        }

                      }, {merge: true})
                      /* eslint-disable no-console */
                      console.log('File available at', url);
                      this.editRoutineImageDialog = false
                      
                    }else if(type == `routine-content1`) {
                      var ref4 = await db.collection('routines').doc(this.selectedItem.routineId)
                      document.getElementById("file-input1-content1").value = "";
                      await ref4.set({
                        'marketingContent': {
                          'images': {
                            'detailPage': {
                              'contentImages': {
                                'contentImg1': url,
                                'credit': null,
                              }
                            }
                          }
                        }

                      }, {merge: true})
                      /* eslint-disable no-console */
                      console.log('File available at', url);
                      this.editRoutineImageDialog = false
                      
                    }else if(type == `routine-content2`) {
                      var ref5 = await db.collection('routines').doc(this.selectedItem.routineId)
                      document.getElementById("file-input2-content2").value = "";
                      await ref5.set({
                        'marketingContent': {
                          'images': {
                            'detailPage': {
                              'contentImages': {
                                'contentImg2': url,
                                'credit': null,
                              }
                            }
                          }
                        }

                      }, {merge: true})
                      /* eslint-disable no-console */
                      console.log('File available at', url);
                      this.editRoutineImageDialog = false
                      
                    }
                      



                  })
                },
              );
            }, this.imageData.type, 1)

          }
        }
        reader.onerror = (error) => {
          /* eslint-disable no-console */
          console.log(error)
        }
      },

      
    },
    beforeCreate () {
      if(!this.$store.state.cms) this.$store.registerModule('cms', cmsModule)
      if(!this.$store.state.user) this.$store.registerModule('user', userModule)
      if(!this.$store.state.signin) this.$store.registerModule('signin', signinModule)
      if(!this.$store.state.admin) this.$store.registerModule('admin', adminModule)
      if(!this.$store.state.experience) this.$store.registerModule('experience', experienceModule)
      if(!this.$store.state.settings) this.$store.registerModule('settings', settingsModule)
    },
    created () {
      //this.resetCheckIn()
      //gtag('config', 'G-H9RD8W4MLR', {'page_path': '/admin'});
      //this.autoSignIn()


      this.getIntentMeanCategoryList()
      //this.getHumanActionList()
      this.getRoutineList()

      //this.getSequenceList()


      


      
    },
    async mounted () {
      var command = (this.$route.params.command) ? this.$route.params.command : null
      var type = (this.$route.params.type) ? this.$route.params.type : null
      //var resourceId = (this.$route.params.resourceId) ? this.$route.params.resourceId : null

      //if(resourceId) {
      //  resourceId = resourceId.replace(/-/g, ' ');   //resource could be
      //}
      if(type) {
        type = type.replace(/_/g, ' ')
        //type = type.replace(/-/g, ' ')
      }
      if(command) {
        switch(command) {
          case "add":   //add an existing routine to the the user's routineTemplates sub-collection, resourceId could be a routineId

            /*await this.addRoutineToUser({
              'routineType': type,
              'routineId': resourceId
            })
            this.$router.push('/console')*/
            break;

          case "create":   //create a new routine template; add a copy in routines collection and in user's routineTemplates sub-collection

            //if no type param, then need to show select dialog
            if(type) {

              document.getElementById('adminConsole').style.height = "6000px"
              document.getElementById('routineOverlayAdmin').style.height = "6000px"
              this.showOverlay({
                'ctaType': 'create_routine',
                'routineType': type,
                'item': null,
              })
            }

            break;


          case "modify":   //add an existing routine to the the user's routineTemplates sub-collection, resourceId could be a routineId
            //console.log("case modify")
            //use resourceId to pull the data
            //var result = await this.getRoutineData(resourceId)

            //this.showOverlay({
            //  'category': result.routineTemplate.intentMeanCategory,
            //  'ctaType': 'routine_modify',
            //  'parentCategory': null,
            //  'routineType': result.routineTemplate.routineType,
            //  'item': result.routineTemplate,
            //})
          

            break;

          

          default:

            break;
        }
      }
      
    },
    updated () {

    },
    watch: {

    }
  }
</script>

<style scoped>

.searchRoutinesHolder {
  text-align: right;
  width: 100%;
  padding-right: 10px;
  background-color: transparent;
}
.searchRoutines {
  display: inline-block;
  width: 300px;
  background-color: transparent;
}

.adminConsole {
  margin: 0 auto;  /* this centers the div on the page */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

#vApp {
 background-color: transparent;
}

.icon {
  max-width: 50px;
  max-height: 40px;
}

.adminHeader {
  background-color: white;
  text-align: center;
  padding-bottom: 20px;
}
.headerNavItem {
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
  padding: 7px;
}


.contentContainer {
  max-width: 900px;
  margin: 0 auto;
  min-height: 500px;
  padding-bottom: 100px;
}
.routineType {
  border: 1px solid grey;
  border-radius: 8px;
  margin: 10px;
  padding: 20px;
}
.formHolder {
  padding-right: 30px;
  padding-left: 30px;
}

.picIconHolder {
  display: inline-block;

  font-size: 1.3em;
  color: rgb(73, 73, 73);
  width: 100px;
  background-color: white;
  margin: 0 auto;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  padding: 5px;
  border: solid 1px grey;

}
</style>
