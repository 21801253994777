<template>
  <div class="routineOverlayAdmin" :id="'routineOverlayAdmin'">

    <div class="wrapper">
      <div class="titleHolder">
              
        <div class="titleHolder2">
          <div v-if="routineType == 'cessation'">
            Create cessation routine
          </div>
          <div v-else-if="routineType == 'work sessions'">
            Create work session routine
          </div>
          <div v-else-if="routineType == 'event follow-up'">
            Create event follow-up routine
          </div>
          <div v-else-if="routineType == 'exercise' || routineType == 'exercise2'">
            Create exercise routine
          </div>
          <div v-else-if="routineType == 'everyday habit'">
            Create everyday habit routine
          </div>
          <div v-else-if="routineType == 'generic'">
            Create super-powered generic routine
          </div>
          <div v-else-if="routineType == 'cooking'">
            Create cooking routine
          </div>
          <div v-else-if="routineType == 'list draw'">
            Create list draw routine
          </div>
          <div v-else-if="routineType == 'event prep'">
            Create event prep routine
          </div>
          <div v-else>
            Create routine
          </div>
        </div>
        <div>
          <div class="dotsHolder">
            <div v-for="(dot, index) in FLOWS[currentFlowManifest]" v-bind:key="'stepDots'+index" class="dots">
              <i
              :id="'stepDot_'+index"
              @click="onDotClick(index)" 
              class="fas fa-arrow-right greyArrow"
              v-if="(currentStep > (index+1)) && dot == 'INTRO'"
              ></i>
              <i
              :id="'stepDot_'+index" 
              class="fas fa-arrow-right currentDot"
              v-else-if="dot == 'INTRO'"
              ></i>
              <i
              :id="'stepDot_'+index" 
              @click="onDotClick(index)"
              class="fas fa-circle"
              v-else-if="currentStep > (index+1)"
              ></i>
              <i
              :id="'stepDot_'+index" 
              class="fas fa-circle currentDot"
              v-else-if="(currentStep == (index+1)) && (dot !='OUTRO') && (dot !='OUTRO_CESSATION') && (dot !='OUTRO_EVENT_BASED')"
              ></i>
              <i
              :id="'stepDot_'+index" 
              class="fas fa-check greenOutroDot"
              v-else-if="(FLOWS[currentFlowManifest].length === (currentStep)) && ((dot =='OUTRO') ||(dot =='OUTRO_CESSATION') ||(dot =='OUTRO_EVENT_BASED'))"
              ></i>
              <i
              :id="'stepDot_'+index" 
              class="fas fa-check greyOutroDot"
              v-else-if="(dot =='OUTRO') ||(dot =='OUTRO_CESSATION') ||(dot =='OUTRO_EVENT_BASED')"
              ></i>
              <i
              :id="'stepDot_'+index" 
              class="far fa-circle"
              v-else
              ></i>

            </div>
          </div>
        </div>
        
      </div>
      
      
      <!-- FLOW STEP TEMPLATE BLOCKS -->
      <!-- FLOW STEP TEMPLATE BLOCKS -->

      <!-- ROUTINE_TYPE -->
      <div class="stepHolder" v-if="ROUTINE_TYPE_STEP_ACTIVE">
        <div class="stepCard">
          <div class="stimulusQuestion" :key="'ROUTINE_TYPE_stimulusQuestion'">
            <h1>{{ this.ROUTINE_TYPE_STEP_CONTENT.thoughtStimulusQuestion }}</h1>
          </div>
          <div class="stepTextField">
            <v-select
              :id="'ROUTINE_TYPE'"           
              v-bind:key="'ROUTINE_TYPE'"
              ref="ROUTINE_TYPE_input"
              :rules="[(v) => !!v || 'Selection is required']"
              autofocus
              :items="this.ROUTINE_TYPE_STEP_CONTENT.selectRoutineTypeItems"
              label="times per day"
              v-model="ANSWERS[currentFlowAnswers].userSelectedRoutineType"
            ></v-select>
          </div>  
        </div>
        <div class="stepButtonHolder">
          <v-btn @click="overlayOff" dark>Cancel</v-btn>
          <v-btn @click="backAStep" v-if="currentStep > 1" dark>Back</v-btn>
          <v-btn @click="handleAnswer('ROUTINE_TYPE')">Continue</v-btn>
        </div>
      </div>


      <!-- WHAT_TO_ROUTINE (This is in Routine Flow Only) -->
      <div class="stepHolder" v-if="WHAT_TO_ROUTINE_STEP_ACTIVE">
        <div class="stepCard">
          <div class="stimulusQuestion lessMinHeight" :key="'WHAT_TO_ROUTINE_stimulusQuestion'">
            <!--<h1>{{ this.WHAT_TO_ROUTINE_STEP_CONTENT.thoughtStimulusQuestion }}</h1>-->
          </div>
          <div v-if="ANSWERS[currentFlowAnswers].whatToRoutineAction.agendaItemTitle">
            <img :src="ANSWERS[currentFlowAnswers].whatToRoutineAction.actionIconBlack" width="80px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {{ ANSWERS[currentFlowAnswers].whatToRoutineAction.agendaItemTitle }}
          </div>
          <div v-else>
            <SearchHumanActions
              v-on:action-selected="actionSelected2"
              :number="3" />
          </div>

          <!--
          <div class="stepTextField lessMarginTop">
            <v-text-field
              v-bind:key="'WHAT_TO_ROUTINE_STATEMENT'"
              ref="WHAT_TO_ROUTINE_STATEMENT_input"
              name="WHAT_TO_ROUTINE_STATEMENT_thought"
              id="WHAT_TO_ROUTINE_STATEMENT"
              autofocus
              :rules="textRules"
              hint="Enter a short title of an action you want to do routinely"
              label="What do you want to routine?"
              counter="20"
              clearable
              type="text"
              v-model="ANSWERS[currentFlowAnswers].whatToRoutineStatement">
            </v-text-field>
          </div> 
          -->


        </div>
        <div class="stepButtonHolder">
          <v-btn @click="overlayOff" dark>Cancel</v-btn>
          <v-btn @click="backAStep" v-if="currentStep > 1" dark>Back</v-btn>
          <v-btn @click="handleAnswer('WHAT_TO_ROUTINE')">Continue</v-btn>
        </div>
      </div>


      <!-- SELECT_PERIODIZATION -->
      <div class="stepHolder" v-if="SELECT_PERIODIZATION_STEP_ACTIVE">
        <div class="stepCard">
          <div class="stimulusQuestion lessMinHeight" :key="'SELECT_PERIODIZATION_stimulusQuestion'">
            <h1>{{ this.SELECT_PERIODIZATION_STEP_CONTENT.thoughtStimulusQuestion }}</h1>
          </div>
          <div class="stepTextField lessMarginTop">
            <div class="column">
              <table width="100%">
                <tr>
                  <td style="padding:15px;width:50%;text-align:left;">
                    <v-checkbox
                      ref="SELECT_PERIODIZATION_PreTrain"
                      v-bind:key="'SELECT_PERIODIZATION_STEP_CONTENT - Pre-Training'"
                      :label="'Pre-Training Phase'"
                      v-model="ANSWERS[currentFlowAnswers].periodization['Pre-Training']">
                    </v-checkbox>
                  </td>
                  <td v-if="ANSWERS[currentFlowAnswers].periodization['Pre-Training']" style="padding:5px;width:50%;">
                    <v-text-field
                      v-bind:key="'PERIOD_WEEKS_PRETRAIN'"
                      ref="PERIOD_WEEKS_PRETRAIN_input"
                      :rules="textRules"
                      label="number of weeks"
                      clearable
                      type="text"
                      v-model="ANSWERS[currentFlowAnswers].periodWeeks['Pre-Training']">
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <td style="padding:15px;width:50%;text-align:left;">
                    <v-checkbox
                      ref="SELECT_PERIODIZATION_Endurance"
                      v-bind:key="'SELECT_PERIODIZATION_STEP_CONTENT - Endurance'"
                      :label="'Endurance Phase'"
                      v-model="ANSWERS[currentFlowAnswers].periodization['Endurance']">
                    </v-checkbox>
                  </td>
                  <td v-if="ANSWERS[currentFlowAnswers].periodization['Endurance']" style="padding:5px;width:50%;">
                    <v-text-field
                      v-bind:key="'PERIOD_WEEKS_ENDURANCE'"
                      ref="PERIOD_WEEKS_ENDURANCE_input"
                      :rules="textRules"
                      label="number of weeks"
                      clearable
                      type="text"
                      v-model="ANSWERS[currentFlowAnswers].periodWeeks['Endurance']">
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <td style="padding:15px;width:50%;text-align:left;">
                    <v-checkbox
                      ref="SELECT_PERIODIZATION_Hypertrophy"
                      v-bind:key="'SELECT_PERIODIZATION_STEP_CONTENT - Hypertrophy'"
                      :label="'Hypertrophy Phase'"
                      v-model="ANSWERS[currentFlowAnswers].periodization['Hypertrophy']">
                    </v-checkbox>
                  </td>
                  <td v-if="ANSWERS[currentFlowAnswers].periodization['Hypertrophy']" style="padding:5px;width:50%;">
                    <v-text-field
                      v-bind:key="'PERIOD_WEEKS_HYPERTROPHY'"
                      ref="PERIOD_WEEKS_HYPERTROPHY_input"
                      :rules="textRules"
                      label="number of weeks"
                      clearable
                      type="text"
                      v-model="ANSWERS[currentFlowAnswers].periodWeeks['Hypertrophy']">
                    </v-text-field>
                  </td>
                </tr>
                <tr>
                  <td style="padding:15px;width:50%;text-align:left;">
                    <v-checkbox
                      ref="SELECT_PERIODIZATION_Strength"
                      v-bind:key="'SELECT_PERIODIZATION_STEP_CONTENT - Strength'"
                      :label="'Strength Phase'"
                      v-model="ANSWERS[currentFlowAnswers].periodization['Strength']">
                    </v-checkbox>
                    
                  </td>
                  <td v-if="ANSWERS[currentFlowAnswers].periodization['Strength']" style="padding:5px;width:50%;">
                    <v-text-field
                      v-bind:key="'PERIOD_WEEKS_STRENGTH'"
                      ref="PERIOD_WEEKS_STRENGTH_input"
                      :rules="textRules"
                      label="number of weeks"
                      clearable
                      type="text"
                      v-model="ANSWERS[currentFlowAnswers].periodWeeks['Strength']">
                    </v-text-field>
                  </td>
                </tr>
              </table>
            </div>
          </div>  
        </div>
        <div class="stepButtonHolder">
          <v-btn @click="overlayOff" dark>Cancel</v-btn>
          <v-btn @click="backAStep" v-if="currentStep > 1" dark>Back</v-btn>
          <v-btn @click="handleAnswer('SELECT_PERIODIZATION')">Continue</v-btn>
        </div>
      </div>


      <!-- DAYS_OF_WEEK (This is in Routine Flow Only) -->
      <div class="stepHolder" v-if="DAYS_OF_WEEK_STEP_ACTIVE">
        <div class="stepCard">
          <div class="stimulusQuestion lessMinHeight" :key="'DAYS_OF_WEEK_stimulusQuestion'">
            <h1>{{ this.DAYS_OF_WEEK_STEP_CONTENT.thoughtStimulusQuestion }}</h1>
          </div>
          <div class="stepTextField lessMarginTop">
            <div class="column">
              <v-checkbox
                ref="DAYS_OF_WEEK_Monday"
                v-bind:key="'DAYS_OF_WEEK_STEP_CONTENT - Monday'"
                :label="'Monday'"
                v-model="ANSWERS[currentFlowAnswers].daysOfWeek['Monday']">
              </v-checkbox>
              <v-checkbox  
                ref="DAYS_OF_WEEK_Tuesday"
                v-bind:key="'DAYS_OF_WEEK_STEP_CONTENT - Tuesday'"
                :label="'Tuesday'"
                v-model="ANSWERS[currentFlowAnswers].daysOfWeek['Tuesday']">
              </v-checkbox>
              <v-checkbox 
                ref="DAYS_OF_WEEK_Wednesday" 
                v-bind:key="'DAYS_OF_WEEK_STEP_CONTENT - Wednesday'"
                :label="'Wednesday'"
                v-model="ANSWERS[currentFlowAnswers].daysOfWeek['Wednesday']">
              </v-checkbox>
              <v-checkbox
                ref="DAYS_OF_WEEK_Thursday"  
                v-bind:key="'DAYS_OF_WEEK_STEP_CONTENT - Thursday'"
                :label="'Thursday'"
                v-model="ANSWERS[currentFlowAnswers].daysOfWeek['Thursday']">
              </v-checkbox>
            </div>
            <div class="column">
              <v-checkbox
                ref="DAYS_OF_WEEK_Friday"  
                v-bind:key="'DAYS_OF_WEEK_STEP_CONTENT - Friday'"
                :label="'Friday'"
                v-model="ANSWERS[currentFlowAnswers].daysOfWeek['Friday']">
              </v-checkbox>
              <v-checkbox
                ref="DAYS_OF_WEEK_Saturday"  
                v-bind:key="'DAYS_OF_WEEK_STEP_CONTENT - Saturday'"
                :label="'Saturday'"
                v-model="ANSWERS[currentFlowAnswers].daysOfWeek['Saturday']">
              </v-checkbox>
              <v-checkbox  
                ref="DAYS_OF_WEEK_Sunday"
                v-bind:key="'DAYS_OF_WEEK_STEP_CONTENT - Sunday'"
                :label="'Sunday'"
                v-model="ANSWERS[currentFlowAnswers].daysOfWeek['Sunday']">
              </v-checkbox>
            </div>
          </div>  
        </div>
        <div class="stepButtonHolder">
          <v-btn @click="overlayOff" dark>Cancel</v-btn>
          <v-btn @click="backAStep" v-if="currentStep > 1" dark>Back</v-btn>
          <v-btn @click="handleAnswer('DAYS_OF_WEEK')">Continue</v-btn>
        </div>
      </div>

      <!-- SELECT_COOKING_DAYS (This is in Routine Flow Only) -->
      <div class="stepHolder" v-if="SELECT_COOKING_DAYS_STEP_ACTIVE">
        <div class="stepCard">
          <div class="stimulusQuestion lessMinHeight" :key="'SELECT_COOKING_DAYS_stimulusQuestion'">
            <h1>{{ this.SELECT_COOKING_DAYS_STEP_CONTENT.thoughtStimulusQuestion }}</h1>
          </div>
          <div class="stepTextField lessMarginTop">
            <div class="column">
              <v-checkbox
                ref="SELECT_COOKING_DAYS_Monday"
                v-bind:key="'SELECT_COOKING_DAYS_STEP_CONTENT - Monday'"
                :label="'Monday'"
                v-model="ANSWERS[currentFlowAnswers].cookingDays['Monday']">
              </v-checkbox>
              <v-checkbox  
                ref="SELECT_COOKING_DAYS_Tuesday"
                v-bind:key="'SELECT_COOKING_DAYS_STEP_CONTENT - Tuesday'"
                :label="'Tuesday'"
                v-model="ANSWERS[currentFlowAnswers].cookingDays['Tuesday']">
              </v-checkbox>
              <v-checkbox 
                ref="SELECT_COOKING_DAYS_Wednesday" 
                v-bind:key="'SELECT_COOKING_DAYS_STEP_CONTENT - Wednesday'"
                :label="'Wednesday'"
                v-model="ANSWERS[currentFlowAnswers].cookingDays['Wednesday']">
              </v-checkbox>
              <v-checkbox
                ref="SELECT_COOKING_DAYS_Thursday"  
                v-bind:key="'SELECT_COOKING_DAYS_STEP_CONTENT - Thursday'"
                :label="'Thursday'"
                v-model="ANSWERS[currentFlowAnswers].cookingDays['Thursday']">
              </v-checkbox>
            </div>
            <div class="column">
              <v-checkbox
                ref="SELECT_COOKING_DAYS_Friday"  
                v-bind:key="'SELECT_COOKING_DAYS_STEP_CONTENT - Friday'"
                :label="'Friday'"
                v-model="ANSWERS[currentFlowAnswers].cookingDays['Friday']">
              </v-checkbox>
              <v-checkbox
                ref="SELECT_COOKING_DAYS_Saturday"  
                v-bind:key="'SELECT_COOKING_DAYS_STEP_CONTENT - Saturday'"
                :label="'Saturday'"
                v-model="ANSWERS[currentFlowAnswers].cookingDays['Saturday']">
              </v-checkbox>
              <v-checkbox  
                ref="SELECT_COOKING_DAYS_Sunday"
                v-bind:key="'SELECT_COOKING_DAYS_STEP_CONTENT - Sunday'"
                :label="'Sunday'"
                v-model="ANSWERS[currentFlowAnswers].cookingDays['Sunday']">
              </v-checkbox>
            </div>
          </div>  
        </div>
        <div class="stepButtonHolder">
          <v-btn @click="overlayOff" dark>Cancel</v-btn>
          <v-btn @click="backAStep" v-if="currentStep > 1" dark>Back</v-btn>
          <v-btn @click="handleAnswer('SELECT_COOKING_DAYS')">Continue</v-btn>
        </div>
      </div>

      <!-- SELECT_MENU -->
      <div class="stepHolder" v-if="SELECT_MENU_STEP_ACTIVE">
        <div class="stepCard">
          <div class="stimulusQuestion" :key="'SELECT_MENU_stimulusQuestion'">
            <h1>{{ this.SELECT_MENU_STEP_CONTENT.thoughtStimulusQuestion }}</h1>
          </div>
          <div class="stepTextField">
            <v-select
              :id="'SELECT_MENU'"           
              v-bind:key="'SELECT_MENU'"
              ref="SELECT_MENU_input"
              :rules="[(v) => !!v || 'Selection is required']"
              autofocus
              :items="['Menu 1', 'Menu 2', 'Menu 3']"
              label="Select menu"
              v-model="ANSWERS[currentFlowAnswers].menuId"
            ></v-select>
          </div>  
        </div>
        <div class="stepButtonHolder">
          <v-btn @click="overlayOff" dark>Cancel</v-btn>
          <v-btn @click="backAStep" v-if="currentStep > 1" dark>Back</v-btn>
          <v-btn @click="handleAnswer('SELECT_MENU')">Continue</v-btn>
        </div>
      </div>


      <!-- TIMES_PER_DAY -->
      <div class="stepHolder" v-if="TIMES_PER_DAY_STEP_ACTIVE">
        <div class="stepCard">
          <div class="stimulusQuestion" :key="'TIMES_PER_DAY_stimulusQuestion'">
            <h1>{{ this.TIMES_PER_DAY_STEP_CONTENT.thoughtStimulusQuestion }}</h1>
          </div>
          <div class="stepTextField">
            <v-select
              :id="'TIMES_PER_DAY'"           
              v-bind:key="'TIMES_PER_DAY'"
              ref="TIMES_PER_DAY_input"
              :rules="[(v) => !!v || 'Selection is required']"
              autofocus
              :items="this.TIMES_PER_DAY_STEP_CONTENT.selectItems"
              label="times per day"
              v-model="ANSWERS[currentFlowAnswers].timesPerDay"
            ></v-select>
          </div>  
        </div>
        <div class="stepButtonHolder">
          <v-btn @click="overlayOff" dark>Cancel</v-btn>
          <v-btn @click="backAStep" v-if="currentStep > 1" dark>Back</v-btn>
          <v-btn @click="handleAnswer('TIMES_PER_DAY')">Continue</v-btn>
        </div>
      </div>

      <!-- USER_DEFINED_ATTRIBUTES -->
      <div class="stepHolder" v-if="USER_DEFINED_ATTRIBUTES_STEP_ACTIVE">
        <div class="stepCard">
          <div class="stimulusQuestion lessMinHeight" :key="'USER_DEFINED_ATTRIBUTES_stimulusQuestion'">
            <h1>{{ this.USER_DEFINED_ATTRIBUTES_STEP_CONTENT.thoughtStimulusQuestion }}</h1>
          </div>
          <div class="stepTextField lessMarginTop">
            <div class="planRoutine_dayOfWeekHolder">
              <div v-if="userDefinedAttributesList.length > 0">
                <div v-for="(attribute, index) in userDefinedAttributesList" v-bind:key="`UserDefinedAttributes`+index">
                  <div class="addEvents_detailsHolder">
                    <div class="addEvents_controlsHolder">

                      <table width="100%">
                        <tr>
                          <td colspan="2">
                            {{ attribute.attributeVariableName }}
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 10px;">
                            <v-text-field
                              v-bind:key="'USER_DEFINED_ATTRIBUTES_stimulus_question'+index"
                              :ref="`USER_DEFINED_ATTRIBUTES_stimulus_question`+index"
                              :id="`USER_DEFINED_ATTRIBUTES_stimulus_question`+index"
                              clearable
                              :rules="textRules"
                              counter="150"
                              label="Stimulus question"
                              type="text"
                              v-model="ANSWERS[currentFlowAnswers].routineUserDefinedAttributes[attribute.attributeVariableName].attributeStimulusQuestion">
                            </v-text-field>
                            
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 10px;">
                            <v-text-field
                              v-bind:key="'USER_DEFINED_ATTRIBUTES_variableUom'+index"
                              :ref="`USER_DEFINED_ATTRIBUTES_variableUom`+index"
                              :id="`USER_DEFINED_ATTRIBUTES_variableUom`+index"
                              clearable
                              :rules="textRules"
                              counter="150"
                              label="Variable unit of measure label"
                              type="text"
                              v-model="ANSWERS[currentFlowAnswers].routineUserDefinedAttributes[attribute.attributeVariableName].attributeVariableUom">
                            </v-text-field>
                            
                          </td>
                        </tr>  

                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <v-text-field
                  v-bind:key="'USER_DEFINED_ATTRIBUTES_nameHolder'"
                  :ref="`USER_DEFINED_ATTRIBUTES_nameHolder`"
                  :id="`USER_DEFINED_ATTRIBUTES_nameHolder`"
                  clearable
                  :rules="textRules"
                  counter="150"
                  label="Enter variable name"
                  type="text"
                  v-model="ANSWERS[currentFlowAnswers].userDefinedAttributeNameHolder">
                </v-text-field>
              </div>
              <div class="addPeriods_addPeriodIcon" v-on:click="addAttributeToRoutine">
                <i class="fas fa-plus-circle"></i>
              </div>
            </div>
          </div>  
        </div>
        <div class="stepButtonHolder">
          <v-btn @click="overlayOff" dark>Cancel</v-btn>
          <v-btn @click="backAStep" v-if="currentStep > 1" dark>Back</v-btn>
          <v-btn @click="handleAnswer('USER_DEFINED_ATTRIBUTES')">Continue</v-btn>
        </div>
      </div>


      <!-- ADD_PERIODS -->
      <div class="stepHolder" v-if="ADD_PERIODS_STEP_ACTIVE">
        <div class="stepCard">
          <div class="stimulusQuestion lessMinHeight" :key="'ADD_PERIODS_stimulusQuestion'">
            <h1>{{ this.ADD_PERIODS_STEP_CONTENT.thoughtStimulusQuestion }}</h1>
          </div>
          <div class="stepTextField lessMarginTop">
            <div class="planRoutine_dayOfWeekHolder">
              <div v-for="(period, name, index) in ANSWERS[currentFlowAnswers].v2_routinePeriodsObject" v-bind:key="`Periods`+index">
                <div class="addEvents_detailsHolder">
                  <div class="addEvents_controlsHolder">

                    <table width="100%">
                      <tr>
                        <td width="40%" class="addPeriods_periodName">
                          <v-text-field
                            :ref="`ADD_PERIODS_name`+index"
                            v-model="ANSWERS[currentFlowAnswers].v2_routinePeriodsObject[name].periodName"
                            label="Period name"
                          ></v-text-field>
                        </td>

                        <td width="60%" class="addPeriods_periodDesc">
                          <v-text-field
                            v-bind:key="'ADD_PERIODS_desc'+index"
                            :ref="`ADD_PERIODS_desc`+index"
                            :id="`ADD_PERIODS_desc`+index"
                            clearable
                            :rules="textRules"
                            counter="150"
                            label="Period description"
                            type="text"
                            v-model="ANSWERS[currentFlowAnswers].v2_routinePeriodsObject[name].periodDesc">
                          </v-text-field>
                          
                        </td>
                      </tr>
                      <tr>
                        <td width="50%" class="addPeriods_periodNumWeeks">
                          <v-text-field
                            v-bind:key="'ADD_PERIODS_numWeeks'+index"
                            :ref="`ADD_PERIODS_numWeeks`+index"
                            :id="`ADD_PERIODS_numWeeks`+index"
                            clearable
                            :rules="textRules"
                            counter="150"
                            label="number of weeks"
                            type="text"
                            v-model="ANSWERS[currentFlowAnswers].v2_routinePeriodsObject[name].periodNumWeeks">
                          </v-text-field>
                          
                        </td>
                        <td width="50%" class="addPeriods_periodNumSets">





                          <v-select
                            
                            v-bind:key="'ADD_MOMENTS_TO_PERIODS_volumeType'+index"
                            :rules="[(v) => !!v || 'Selection is required']"
                            :ref="'ADD_MOMENTS_TO_PERIODS_volumeType'+index"  
                            :items="['exercise', 'cooking']"
                            label="Period type"
                            v-model="ANSWERS[currentFlowAnswers].v2_routinePeriodsObject[name].periodType">
                          </v-select>
                          
                        </td>
                      </tr>
                      <tr>
                        <td width="50%" class="addPeriods_periodNumSets">
                          <v-text-field
                            v-if="ANSWERS[currentFlowAnswers].v2_routinePeriodsObject[name].periodType == `exercise`"
                            v-bind:key="'ADD_PERIODS_numSets'+index"
                            :ref="`ADD_PERIODS_numSets`+index"
                            :id="`ADD_PERIODS_numSets`+index"
                            clearable
                            :rules="textRules"
                            counter="150"
                            label="number of sets"
                            type="text"
                            v-model="ANSWERS[currentFlowAnswers].v2_routinePeriodsObject[name].periodNumSets">
                          </v-text-field>
                        </td>
                        <td width="50%" class="addPeriods_periodNumSets">
                          


                          



                        </td>
                      </tr> 
                      <!--
                      <tr>
                        <td width="100%" colspan="2">
                          <div class="addPeriods_periodDayOfWeek">
                            <v-checkbox
                              :ref="`ADD_PERIODS_ref_Monday`+index"
                              :v-bind:key="'ADD_PERIODS_key_Monday'+index"
                              :label="'Monday'"
                              value="Monday"
                              v-model="ANSWERS[currentFlowAnswers].v2_routinePeriodsObject[name].periodDaysOfWeek">
                            </v-checkbox>
                          </div>
                          <div class="addPeriods_periodDayOfWeek">
                            <v-checkbox
                              :ref="`ADD_PERIODS_ref_Tuesday`+index"
                              :v-bind:key="'ADD_PERIODS_key_Tuesday'+index"
                              :label="'Tuesday'"
                              value="Tuesday"
                              v-model="ANSWERS[currentFlowAnswers].v2_routinePeriodsObject[name].periodDaysOfWeek">
                            </v-checkbox>
                          </div>
                          <div class="addPeriods_periodDayOfWeek">
                            <v-checkbox
                              :ref="`ADD_PERIODS_ref_Wednesday`+index"
                              :v-bind:key="'ADD_PERIODS_key_Wednesday'+index"
                              :label="'Wednesday'"
                              value="Wednesday"
                              v-model="ANSWERS[currentFlowAnswers].v2_routinePeriodsObject[name].periodDaysOfWeek">
                            </v-checkbox>
                          </div>
                          <div class="addPeriods_periodDayOfWeek">
                            <v-checkbox
                              :ref="`ADD_PERIODS_ref_Thursday`+index"
                              :v-bind:key="'ADD_PERIODS_key_Thursday'+index"
                              :label="'Thursday'"
                              value="Thursday"
                              v-model="ANSWERS[currentFlowAnswers].v2_routinePeriodsObject[name].periodDaysOfWeek">
                            </v-checkbox>
                          </div>
                          <div class="addPeriods_periodDayOfWeek">
                            <v-checkbox
                              :ref="`ADD_PERIODS_ref_Friday`+index"
                              :v-bind:key="'ADD_PERIODS_key_Friday'+index"
                              :label="'Friday'"
                              value="Friday"
                              v-model="ANSWERS[currentFlowAnswers].v2_routinePeriodsObject[name].periodDaysOfWeek">
                            </v-checkbox>
                          </div>
                          <div class="addPeriods_periodDayOfWeek">
                            <v-checkbox
                              :ref="`ADD_PERIODS_ref_Saturday`+index"
                              :v-bind:key="'ADD_PERIODS_key_Saturday'+index"
                              :label="'Saturday'"
                              value="Saturday"
                              v-model="ANSWERS[currentFlowAnswers].v2_routinePeriodsObject[name].periodDaysOfWeek">
                            </v-checkbox>
                          </div>
                          <div class="addPeriods_periodDayOfWeek">
                            <v-checkbox
                              :ref="`ADD_PERIODS_ref_Sunday`+index"
                              :v-bind:key="'ADD_PERIODS_key_Sunday'+index"
                              :label="'Sunday'"
                              value="Sunday"
                              v-model="ANSWERS[currentFlowAnswers].v2_routinePeriodsObject[name].periodDaysOfWeek">
                            </v-checkbox>
                          </div>
                        </td>
                      </tr>
                      -->
                    </table>
                  </div>
                </div>
              </div>
              <div class="addPeriods_addPeriodIcon" v-on:click="addV2PeriodToRoutine">
                <i class="fas fa-plus-circle"></i>
              </div>
            </div>
          </div>  
        </div>
        <div class="stepButtonHolder">
          <v-btn @click="overlayOff" dark>Cancel</v-btn>
          <v-btn @click="backAStep" v-if="currentStep > 1" dark>Back</v-btn>
          <v-btn @click="handleAnswer('ADD_PERIODS')">Continue</v-btn>
        </div>
      </div>


      <!-- ADD_MOMENTS_TO_PERIODS -->
      <div class="stepHolder" v-if="ADD_MOMENTS_TO_PERIODS_STEP_ACTIVE">
        <div class="stepCard">
          <div class="stimulusQuestion lessMinHeight" :key="'ADD_MOMENTS_TO_PERIODS_stimulusQuestion'">
            <h1>{{ ADD_MOMENTS_TO_PERIODS_STEP_CONTENT.thoughtStimulusQuestion }}</h1>
          </div>
          <div class="stepTextField lessMarginTop">

            
            <div class="planRoutine_momentHolder" v-for="(period, key) in ANSWERS[currentFlowAnswers].v2_routinePeriodsObject" v-bind:key="key">
              <div class="planRoutine_dayOfWeekHolder">
                <div class="planRoutine_dayOfWeekTitle">
                  {{ period.periodName }}
                </div>

                <div class="planRoutine_momentHolder2" v-for="(moment, idx) in ANSWERS[currentFlowAnswers].v2_periodMoments[key]" v-bind:key="idx">
                  <div class="planRoutine_momentTitle">
                    {{ idx+1 }}: &nbsp;{{ moment.momentTitle }}
                  </div>
                  <div class="planRoutine_detailsHolder">
                    <div class="planRoutine_controlsHolder">
                      <table width="100%">
                        <tr>
                          <td width="33%" class="planRoutine_selectTimeOfDay">
                                   
                            <v-select
                              v-bind:key="'ADD_MOMENTS_TO_PERIODS_selectTimeOfDay'+idx"
                              :rules="[(v) => !!v || 'Selection is required']"
                              :ref="'ADD_MOMENTS_TO_PERIODS_selectTimeOfDay'+idx"  
                              :items="ADD_MOMENTS_TO_PERIODS_STEP_CONTENT.selectTimeOfDayItems"
                              label="Select time of day"
                              v-model="ANSWERS[currentFlowAnswers].v2_periodMoments[key][idx].timeOfDay">
                            </v-select>
                            
                          </td>
                          
                          <td width="34%" class="planRoutine_volumetype">
                            
                            <v-select
                              v-if="!moment.sequenceSteps"
                              v-bind:key="'ADD_MOMENTS_TO_PERIODS_volumeType'+idx"
                              :rules="[(v) => !!v || 'Selection is required']"
                              :ref="'ADD_MOMENTS_TO_PERIODS_volumeType'+idx"  
                              :items="['reps', 'duration', 'distance', 'tabata']"
                              label="Change volume type"
                              v-model="ANSWERS[currentFlowAnswers].v2_periodMoments[key][idx].actionAttributes.volumeType">
                            </v-select>
                            
                          </td>
                          <td width="33%" class="planRoutine_intensityType">
                            
                            <v-select
                              v-if="!moment.sequenceSteps"
                              v-bind:key="'ADD_MOMENTS_TO_PERIODS_intensityType'+idx"
                              :rules="[(v) => !!v || 'Selection is required']"
                              :ref="'ADD_MOMENTS_TO_PERIODS_intensityType'+idx"  
                              :items="['none', '%1rm', 'load', 'tabata', 'pace', '%effort', '%mhr']"
                              label="Change intensity type"
                              v-model="ANSWERS[currentFlowAnswers].v2_periodMoments[key][idx].actionAttributes.intensityType">
                            </v-select>
                            
                          </td>
                        </tr>
                        <tr>
                        <td width="100%" colspan="3">
                          <div class="addPeriods_periodDayOfWeek">
                            <v-checkbox
                              :ref="`ADD_PERIODS_ref_Monday`+idx"
                              :v-bind:key="'ADD_PERIODS_key_Monday'+idx"
                              :label="'Monday'"
                              value="Monday"
                              v-model="ANSWERS[currentFlowAnswers].v2_periodMoments[key][idx].daysOfWeek">
                            </v-checkbox>
                          </div>
                          <div class="addPeriods_periodDayOfWeek">
                            <v-checkbox
                              :ref="`ADD_PERIODS_ref_Tuesday`+idx"
                              :v-bind:key="'ADD_PERIODS_key_Tuesday'+idx"
                              :label="'Tuesday'"
                              value="Tuesday"
                              v-model="ANSWERS[currentFlowAnswers].v2_periodMoments[key][idx].daysOfWeek">
                            </v-checkbox>
                          </div>
                          <div class="addPeriods_periodDayOfWeek">
                            <v-checkbox
                              :ref="`ADD_PERIODS_ref_Wednesday`+idx"
                              :v-bind:key="'ADD_PERIODS_key_Wednesday'+idx"
                              :label="'Wednesday'"
                              value="Wednesday"
                              v-model="ANSWERS[currentFlowAnswers].v2_periodMoments[key][idx].daysOfWeek">
                            </v-checkbox>
                          </div>
                          <div class="addPeriods_periodDayOfWeek">
                            <v-checkbox
                              :ref="`ADD_PERIODS_ref_Thursday`+idx"
                              :v-bind:key="'ADD_PERIODS_key_Thursday'+idx"
                              :label="'Thursday'"
                              value="Thursday"
                              v-model="ANSWERS[currentFlowAnswers].v2_periodMoments[key][idx].daysOfWeek">
                            </v-checkbox>
                          </div>
                          <div class="addPeriods_periodDayOfWeek">
                            <v-checkbox
                              :ref="`ADD_PERIODS_ref_Friday`+idx"
                              :v-bind:key="'ADD_PERIODS_key_Friday'+idx"
                              :label="'Friday'"
                              value="Friday"
                              v-model="ANSWERS[currentFlowAnswers].v2_periodMoments[key][idx].daysOfWeek">
                            </v-checkbox>
                          </div>
                          <div class="addPeriods_periodDayOfWeek">
                            <v-checkbox
                              :ref="`ADD_PERIODS_ref_Saturday`+idx"
                              :v-bind:key="'ADD_PERIODS_key_Saturday'+idx"
                              :label="'Saturday'"
                              value="Saturday"
                              v-model="ANSWERS[currentFlowAnswers].v2_periodMoments[key][idx].daysOfWeek">
                            </v-checkbox>
                          </div>
                          <div class="addPeriods_periodDayOfWeek">
                            <v-checkbox
                              :ref="`ADD_PERIODS_ref_Sunday`+idx"
                              :v-bind:key="'ADD_PERIODS_key_Sunday'+idx"
                              :label="'Sunday'"
                              value="Sunday"
                              v-model="ANSWERS[currentFlowAnswers].v2_periodMoments[key][idx].daysOfWeek">
                            </v-checkbox>
                          </div>
                        </td>
                      </tr>
                      </table>
                    </div>
                  </div>
                </div>

                
                



                <div class="planRoutine_addMomentIcon" v-on:click="addActionMomentToRoutine(key)">
                  <i class="fas fa-plus-circle"></i> action moment<br>
                  <div v-if="showSearchAction[key]">
                    <SearchHumanActions 
                      :period-key="key"
                      v-on:action-selected="actionSelected3"
                      :number="3" />
                  </div>
                </div>
                <div class="planRoutine_addMomentIcon" v-on:click="addSequenceMomentToRoutine(key)">
                  <i class="fas fa-plus-circle"></i> sequence moment<br>
                  <div v-if="showSearchSequence[key]">
                    <SearchSequences 
                      :period-key="key"
                      v-on:seq-selected="sequenceSelected"
                      :number="4" />
                  </div>
                </div>


              </div>  
            </div>
            


          </div>  
        </div>
        <div class="stepButtonHolder">
          <v-btn @click="overlayOff" dark>Cancel</v-btn>
          <v-btn @click="backAStep" v-if="currentStep > 1" dark>Back</v-btn>
          <v-btn @click="handleAnswer('ADD_MOMENTS_TO_PERIODS')">Continue</v-btn>
        </div>
      </div>

      <!-- ADD_EVENT -->
      <!-- ADD_PREP_TASKS -->
      <!-- ADD_LISTINGS -->

      <!-- ADD_EVENTS -->
      <div class="stepHolder" v-if="ADD_EVENTS_STEP_ACTIVE">
        <div class="stepCard">
          <div class="stimulusQuestion lessMinHeight" :key="'ADD_EVENTS_stimulusQuestion'">
            <h1>{{ this.ADD_EVENTS_STEP_CONTENT.thoughtStimulusQuestion }}</h1>
          </div>
          <div class="stepTextField lessMarginTop">
            <div class="planRoutine_dayOfWeekHolder">
              <div v-for="(event, index) in ANSWERS[currentFlowAnswers].eventsArray" v-bind:key="index">
                <div class="addEvents_detailsHolder">
                  <div class="addEvents_controlsHolder">

                    <table width="100%">
                      <tr>
                        <td width="30%" class="addEvents_inputEvent">
                          <!-- DATE PICKER -->
                          <v-menu
                            :ref="`ADD_EVENTS_menu`+index"
                            v-model="menu[index]"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                :ref="`ADD_EVENTS_date`+index"
                                v-model="ANSWERS[currentFlowAnswers].eventsArray[index].eventDayStamp"
                                label="Select date"
                                readonly
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="ANSWERS[currentFlowAnswers].eventsArray[index].eventDayStamp"
                              color="black"
                              @input="menu[index] = false"
                            >
                              
                            </v-date-picker>
                          </v-menu>
                        </td>

                        <td width="70%" class="addEvents_selectDate">
                          <v-text-field
                            v-bind:key="'ADD_EVENTS'+index"
                            :ref="`ADD_EVENTS_input`+index"
                            name="ADD_EVENTS_eventName"
                            :id="`ADD_EVENTS`+index"
                            clearable
                            :rules="textRules"
                            counter="150"
                            label="Event title"
                            type="text"
                            v-model="ANSWERS[currentFlowAnswers].eventsArray[index].eventName">
                          </v-text-field>
                          
                        </td>
                      </tr>
                    </table>


                  </div>
                </div>
              </div>
              <div class="addEvents_addEventIcon" v-on:click="addEventToRoutine">
                <i class="fas fa-plus-circle"></i>
              </div>
            </div>
          </div>  
        </div>
        <div class="stepButtonHolder">
          <v-btn @click="overlayOff" dark>Cancel</v-btn>
          <v-btn @click="backAStep" v-if="currentStep > 1" dark>Back</v-btn>
          <v-btn @click="handleAnswer('ADD_EVENTS')">Continue</v-btn>
        </div>
      </div>


      <!-- ADD_QUESTION_GROUP -->
      <div class="stepHolder" v-if="ADD_QUESTION_GROUP_STEP_ACTIVE">
        <div class="stepCard">
          <div class="stimulusQuestion lessMinHeight" :key="'ADD_QUESTION_GROUP_stimulusQuestion'">
            <h1>{{ this.ADD_QUESTION_GROUP_STEP_CONTENT.thoughtStimulusQuestion }}</h1>
          </div>
          <div class="stepTextField lessMarginTop">

            
            <div class="planRoutine_dayOfWeekHolder">
              <div v-for="(questionGroup, key, groupIndex) in ANSWERS[currentFlowAnswers].routineQuestionGroups" v-bind:key="groupIndex">
                <div class="addEvents_detailsHolder">
                  <div class="addEvents_controlsHolder">

                    <table width="100%">
                      <tr>
                        <td width="70%" class="addEvents_inputEvent">
                          <v-text-field
                            v-bind:key="'ADD_QUESTION_GROUP'+groupIndex"
                            :ref="`ADD_QUESTION_GROUP_input`+groupIndex"
                            name="ADD_QUESTION_GROUP_questionGroupName"
                            :id="`ADD_QUESTION_GROUP`+groupIndex"
                            clearable
                            :rules="textRules"
                            counter="150"
                            label="Question group name"
                            type="text"
                            v-model="ANSWERS[currentFlowAnswers].routineQuestionGroups[`group`+groupIndex].questionGroupName">
                          </v-text-field>
                        </td>

                        <td width="30%" class="addEvents_selectDate">

                            
              
                        </td>
                      </tr>
                    </table>

                    <div v-if="ANSWERS[currentFlowAnswers].routineQuestionGroups[`group`+groupIndex].eventAttributeQuestions.length > 0">
                      <table v-for="(question, questionIndex) in ANSWERS[currentFlowAnswers].routineQuestionGroups[`group`+groupIndex].eventAttributeQuestions" v-bind:key="`eventAttributeQuestion`+questionIndex" class="questionObjHolder">
                        <tr>
                          <td width="70%">
                            <v-text-field
                              v-bind:key="'ADD_QUESTION_GROUP_question'+questionIndex"
                              :ref="`ADD_QUESTION_GROUP_questionref`+questionIndex"
                              name="ADD_QUESTION_GROUP_question"
                              :id="`ADD_QUESTION_GROUP_question`+questionIndex"
                              clearable
                              :rules="textRules"
                              counter="150"
                              label="Question to ask yourself"
                              type="text"
                              v-model="ANSWERS[currentFlowAnswers].routineQuestionGroups[`group`+groupIndex].eventAttributeQuestions[questionIndex].question">
                            </v-text-field>
                          </td>
                          <td width="30%"  class="addEvents_selectDate">
                            <v-text-field
                              :ref="`ADD_QUESTION_GROUP_answerUom`+questionIndex"
                              v-model="ANSWERS[currentFlowAnswers].routineQuestionGroups[`group`+groupIndex].eventAttributeQuestions[questionIndex].answerUom"
                              label="answer Uom"
                              type="text"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr>
                          <td width="70%">
                            <v-text-field
                              v-bind:key="'ADD_QUESTION_TO_TASK_answerVariableName'+questionIndex"
                              :ref="`ADD_QUESTION_TO_TASK_answerVariableNameref`+questionIndex"
                              name="ADD_QUESTION_TO_TASK_answerVariableName"
                              :id="`ADD_QUESTION_TO_TASK_answerVariableName`+questionIndex"
                              clearable
                              :rules="textRules"
                              counter="150"
                              label="Event variable name"
                              type="text"
                              v-model="ANSWERS[currentFlowAnswers].routineQuestionGroups[`group`+groupIndex].eventAttributeQuestions[questionIndex].eventVariableName">
                            </v-text-field>
                          </td>
                        </tr>
                        <tr>
                          <td width="70%">
                            <v-select
                              v-bind:key="'ADD_QUESTION_TO_TASK_questionDependency'+questionIndex"
                              :ref="`ADD_QUESTION_TO_TASK_questionDependencyref`+questionIndex"
                              name="ADD_QUESTION_TO_TASK_questionDependency"
                              :id="`ADD_QUESTION_TO_TASK_questionDependency`+questionIndex"
                              :items="routineQuestionDependencyList"
                              :rules="[(v) => !!v || 'Selection is required']"
                              label="Question dependency"
                              v-model="ANSWERS[currentFlowAnswers].routineQuestionGroups[`group`+groupIndex].eventAttributeQuestions[questionIndex].questionDependency">
                            </v-select>
                          </td>
                        </tr>

                      </table>
                    </div>

                    <div v-if="ANSWERS[currentFlowAnswers].routineQuestionGroups[`group`+groupIndex].eventFollowUpQuestions.length > 0">
                      <table v-for="(question, questionIndex) in ANSWERS[currentFlowAnswers].routineQuestionGroups[`group`+groupIndex].eventFollowUpQuestions" v-bind:key="`eventFollowUpQuestion`+questionIndex" class="questionObjHolder">
                        <tr>
                          <td width="70%">
                            <v-text-field
                              v-bind:key="'ADD_QUESTION_GROUP_event_followup_question'+questionIndex"
                              :ref="`ADD_QUESTION_GROUP_event_followup_questionref`+questionIndex"
                              name="ADD_QUESTION_GROUP_event_followup_question"
                              :id="`ADD_QUESTION_GROUP_event_followup_question`+questionIndex"
                              clearable
                              :rules="textRules"
                              counter="150"
                              label="Question to ask yourself"
                              type="text"
                              v-model="ANSWERS[currentFlowAnswers].routineQuestionGroups[`group`+groupIndex].eventFollowUpQuestions[questionIndex].question">
                            </v-text-field>
                          </td>
                          <td width="30%"  class="addEvents_selectDate">
                            <v-text-field
                              :ref="`ADD_QUESTION_GROUP_event_followup_answerUom`+questionIndex"
                              v-model="ANSWERS[currentFlowAnswers].routineQuestionGroups[`group`+groupIndex].eventFollowUpQuestions[questionIndex].answerUom"
                              label="answer Uom"
                              type="text"
                            ></v-text-field>
                          </td>
                        </tr>
                        
                        <tr>
                          <td width="70%">
                            <v-select
                              v-bind:key="'ADD_QUESTION_TO_TASK_event_followup_questionDependency'+questionIndex"
                              :ref="`ADD_QUESTION_TO_TASK_event_followup_questionDependencyref`+questionIndex"
                              name="ADD_QUESTION_TO_TASK_event_followup_questionDependency"
                              :id="`ADD_QUESTION_TO_TASK_event_followup_questionDependency`+questionIndex"
                              :items="routineQuestionDependencyList"
                              :rules="[(v) => !!v || 'Selection is required']"
                              label="Question dependency"
                              v-model="ANSWERS[currentFlowAnswers].routineQuestionGroups[`group`+groupIndex].eventFollowUpQuestions[questionIndex].questionDependency">
                            </v-select>
                          </td>
                        </tr>

                      </table>
                    </div>

                    <br /><br />
                  </div>
                  
                  <div class="addTaskQuestionIcon" @click="addQuestionToQuestionGroup({
                                                                                      'groupIndex':groupIndex, 
                                                                                      'questionType': 'eventFollowUpQuestion',
                                                                                      'eventId': null
                                                                                      })">
                    ADD EVENT FOLLOW-UP TASK <i class="fas fa-question"></i>
                  </div>
                  <div class="addEventQuestionIcon" @click="addQuestionToQuestionGroup({
                                                                                      'groupIndex':groupIndex, 
                                                                                      'questionType': 'eventAttributeQuestion',
                                                                                      'eventId': null
                                                                                      })">
                    ADD EVENT ATTRIBUTE <i class="fas fa-question"></i>
                  </div>
                  

                </div>
              </div>
              <div class="addEvents_addEventIcon" v-on:click="addQuestionGroupToRoutine">
                <i class="fas fa-plus-circle"></i>
              </div>
            </div>
            
            
            
          </div>  
        </div>
        <div class="stepButtonHolder">
          <v-btn @click="overlayOff" dark>Cancel</v-btn>
          <v-btn @click="backAStep" v-if="currentStep > 1" dark>Back</v-btn>
          <v-btn @click="handleAnswer('ADD_QUESTION_GROUP')">Continue</v-btn>
        </div>
      </div>


      <!-- ADD_FOLLOW_ON_TASKS -->
      <div class="stepHolder" v-if="ADD_FOLLOW_ON_TASKS_STEP_ACTIVE">
        <div class="stepCard">
          <div class="stimulusQuestion lessMinHeight" :key="'ADD_FOLLOW_ON_TASKS_stimulusQuestion'">
            <h1>{{ this.ADD_FOLLOW_ON_TASKS_STEP_CONTENT.thoughtStimulusQuestion }}</h1>
          </div>
          <div class="stepTextField lessMarginTop">


            <div class="planRoutine_dayOfWeekHolder">
              <div v-for="(task, taskIndex) in ANSWERS[currentFlowAnswers].tasksArray" v-bind:key="taskIndex">
                <div class="addEvents_detailsHolder">
                  <div class="addEvents_controlsHolder">

                    <table width="100%">
                      <tr>
                        <td width="70%" class="addEvents_inputEvent">
                          <v-text-field
                            v-bind:key="'ADD_FOLLOW_ON_TASKS'+taskIndex"
                            :ref="`ADD_FOLLOW_ON_TASKS_input`+taskIndex"
                            name="ADD_FOLLOW_ON_TASKS_eventName"
                            :id="`ADD_FOLLOW_ON_TASKS`+taskIndex"
                            clearable
                            :rules="textRules"
                            counter="150"
                            label="Task title"
                            type="text"
                            v-model="ANSWERS[currentFlowAnswers].tasksArray[taskIndex].taskName">
                          </v-text-field>
                        </td>

                        <td width="30%" class="addEvents_selectDate">
                          
                          <v-text-field
                            :ref="`ADD_FOLLOW_ON_TASKS_date`+taskIndex"
                            v-model="ANSWERS[currentFlowAnswers].tasksArray[taskIndex].startDayOffsets"
                            label="# days after event"
                          ></v-text-field>
                            
              
                        </td>
                      </tr>
                      <tr>
                        <td width="70%">
                          <v-select
                            v-bind:key="'ADD_QUESTION_TO_TASK_question_group'+taskIndex"
                            :ref="`ADD_QUESTION_TO_TASK_question_group_ref`+taskIndex"
                            :items="questionGroupList"
                            item-text="name"
                            :rules="[(v) => !!v || 'Selection is required']"
                            label="Select question group"
                            @change="(e) => handleQuestionGroupChange({event: e, taskIndex})"
                            v-model="ANSWERS[currentFlowAnswers].tasksArray[taskIndex].routineQuestionGroupName">
                          </v-select>
                        </td>
                      </tr>



                    </table>


                    <br /><br />
                  </div>
                  
                  
                </div>
              </div>
              <div class="addEvents_addEventIcon" v-on:click="addTaskToRoutine">
                <i class="fas fa-plus-circle"></i>
              </div>
            </div>
            
            
          </div>  
        </div>
        <div class="stepButtonHolder">
          <v-btn @click="overlayOff" dark>Cancel</v-btn>
          <v-btn @click="backAStep" v-if="currentStep > 1" dark>Back</v-btn>
          <v-btn @click="handleAnswer('ADD_FOLLOW_ON_TASKS')">Continue</v-btn>
        </div>
      </div>


      <!-- PLAN_CESSATION_SCHEDULE -->
      <div class="stepHolder" v-if="PLAN_CESSATION_SCHEDULE_STEP_ACTIVE">
        <div class="stepCard">
          <div class="stimulusQuestion lessMinHeight" :key="'PLAN_CESSATION_SCHEDULE_stimulusQuestion'">
            <h1>{{ this.PLAN_CESSATION_SCHEDULE_STEP_CONTENT.thoughtStimulusQuestion }}</h1>
          </div>
          
          <div class="stepTextField lessMarginTop">
            <div class="resetSchedule">
              <v-btn @click="resetSchedule" color="grey">Reset Schedule</v-btn>
            </div>
            <div v-for="(dayNum, index) in ANSWERS[currentFlowAnswers].cessationScheduleStartDay" v-bind:key="index">
              <div class="planRoutine_dayOfWeekHolder">
                <div class="planCessationRoutine_PhaseTitle">
                  Phase {{ ++index }}
                </div>
                  
                <div class="planRoutine_detailsHolder">
                  <div class="planRoutine_controlsHolder">
                    <table width="100%">
                      <tr>
                        <td width="50%" class="planCessationRoutine_selectStartDay">
                          
                          <div v-if="(--index) == 0">
                            Starting Day 1
                          </div>
                          <div v-else>
                            <v-select
                              v-bind:key="'PLAN_CESSATION_SCHEDULE_selectStartDay'+ (index)"
                              :rules="[(v) => !!v || 'Selection is required']"
                              :ref="'PLAN_CESSATION_SCHEDULE_selectStartDay'+index"  
                              :items="PLAN_CESSATION_SCHEDULE_STEP_CONTENT.selectStartDay"
                              label="Select start day"
                              v-model="ANSWERS[currentFlowAnswers].cessationScheduleStartDay[index]"
                              @change="validateStartDaySchedule(index)">
                            </v-select>
                          </div>
                        </td>
                        <td width="50%" class="planCessationRoutine_selectTimesPerDay">
                          <v-select
                            
                            v-bind:key="'PLAN_CESSATION_SCHEDULE_selectTimesPerDay'+index"
                            :rules="[(v) => !!v || 'Selection is required']"
                            :ref="'PLAN_CESSATION_SCHEDULE_selectTimesPerDay'+index"  
                            :items="PLAN_CESSATION_SCHEDULE_STEP_CONTENT.selectTimesPerDay"
                            label="Select times per day"
                            v-model="ANSWERS[currentFlowAnswers].cessationScheduleTimesPerDay[index]"
                            @change="validateTimesPerDaySchedule(index)">
                          </v-select>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <!-- error message -->
                <div class="error" :id="`error`+index">
                  Enter a start day value greater than the previous phase
                </div><br />
                <div class="error" :id="`error2`+index">
                  Enter a times per day value lower than previous phase
                </div>
              </div>
              
            </div>
            <div class="planCessationRoutine_addPhaseIcon" v-on:click="addPhaseToRoutine()">
                <i class="fas fa-plus-circle"></i>
              </div>
          </div>  
        </div>
        <div class="stepButtonHolder">
          <v-btn @click="overlayOff" dark>Cancel</v-btn>
          <v-btn @click="backAStep" v-if="currentStep > 1" dark>Back</v-btn>
          <v-btn @click="handleAnswer('PLAN_CESSATION_SCHEDULE')">Continue</v-btn>
        </div>
      </div>


      <!-- PLAN_EXERCISE2_ROUTINE -->
      <div class="stepHolder" v-if="PLAN_EXERCISE_ROUTINE_STEP_ACTIVE">
        <div class="stepCard">
          <div class="stimulusQuestion lessMinHeight" :key="'PLAN_EXERCISE_ROUTINE_stimulusQuestion'">
            <h1>{{ this.PLAN_EXERCISE_ROUTINE_STEP_CONTENT.thoughtStimulusQuestion }}</h1>
          </div>
          <div class="stepTextField lessMarginTop">
            <!-- TABS -->
            <v-tabs
              v-model="tab"
              fixed-tabs
              centered
            >
              <v-tabs-slider></v-tabs-slider>
              <v-tab @click="setTab(`Pre-Training`)" v-if="ANSWERS[currentFlowAnswers].periodization[`Pre-Training`]">
                Pre-Training
              </v-tab>
              <v-tab @click="setTab(`Endurance`)" v-if="ANSWERS[currentFlowAnswers].periodization[`Endurance`]">
                Endurance
              </v-tab>
              <v-tab @click="setTab(`Hypertrophy`)" v-if="ANSWERS[currentFlowAnswers].periodization[`Hypertrophy`]">
                Hypertrophy
              </v-tab>
              <v-tab @click="setTab(`Strength`)" v-if="ANSWERS[currentFlowAnswers].periodization[`Strength`]">
                Strength
              </v-tab>

              <!-- PRE-TRAINING PERIOD TAB ITEM-->
              <v-tab-item v-if="ANSWERS[currentFlowAnswers].periodization[`Pre-Training`]">
                <br /><br />
                <v-card flat>
                  <div class="contentContainer">

                    <div v-for="(bool, period, index) in ANSWERS[currentFlowAnswers].periodization" v-bind:key="index">
                      <div v-if="bool && (period == currentTab)">
                        <div class="planRoutine_momentHolder"  v-for="(moment, idx) in ANSWERS[currentFlowAnswers].periodMoments[period]" v-bind:key="idx">
                          <div class="planRoutine_momentTitle">

                            <div v-if="moment.actionAttributes.agendaItemTitle">
                              <img :src="moment.actionAttributes.actionIcon" width="80px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              {{ moment.actionAttributes.agendaItemTitle }}
                            </div>
                            <div v-else>
                              <SearchHumanActions 
                                :this-index="idx"
                                v-on:action-selected="actionSelected"
                                :number="1" />
                            </div>


                          </div>
                          <div class="planRoutine_detailsHolder">
                            <div class="planRoutine_controlsHolder">
                              <table width="100%">
                                <tr>
                                  <td width="33%" class="planRoutine_selectTimeOfDay">
                                    
                                    <v-select
                                      v-bind:key="'PLAN_EXERCISE_ROUTINE_selectTimeOfDay'+index"
                                      :rules="[(v) => !!v || 'Selection is required']"
                                      :ref="'PLAN_EXERCISE_ROUTINE_selectTimeOfDay'+index"  
                                      :items="PLAN_EXERCISE_ROUTINE_STEP_CONTENT.selectTimeOfDayItems"
                                      label="Select time of day"
                                      v-model="ANSWERS[currentFlowAnswers].periodMoments[period][idx].timeOfDay">
                                    </v-select>
                                    
                                  </td>
                                  
                                  <td width="34%" class="planRoutine_volumetype">
                                    
                                    <v-select
                                      v-bind:key="'PLAN_EXERCISE_ROUTINE_volumeType'+index"
                                      :rules="[(v) => !!v || 'Selection is required']"
                                      :ref="'PLAN_EXERCISE_ROUTINE_volumeType'+index"  
                                      :items="['reps', 'duration', 'distance', 'tabata']"
                                      label="Change volume type"
                                      v-model="ANSWERS[currentFlowAnswers].periodMoments[period][idx].actionAttributes.volumeType">
                                    </v-select>
                                    
                                  </td>
                                  <td width="33%" class="planRoutine_intensityType">
                                    
                                    <v-select
                                      v-bind:key="'PLAN_EXERCISE_ROUTINE_intensityType'+index"
                                      :rules="[(v) => !!v || 'Selection is required']"
                                      :ref="'PLAN_EXERCISE_ROUTINE_intensityType'+index"  
                                      :items="['none', '%1rm', 'load', 'tabata', 'pace', '%effort', '%mhr']"
                                      label="Change intensity type"
                                      v-model="ANSWERS[currentFlowAnswers].periodMoments[period][idx].actionAttributes.intensityType">
                                    </v-select>
                                    
                                  </td>
                                  
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="planRoutine_addMomentIcon" v-on:click="addMomentToPeriod(period)">
                          <i class="fas fa-plus-circle"></i>
                        </div>
                        


                        
                      </div>
                      
                    </div>
                    
                  </div>
                </v-card>
              </v-tab-item>

              <!-- ENDURANCE PERIOD TAB ITEM-->
              <v-tab-item v-if="ANSWERS[currentFlowAnswers].periodization[`Endurance`]">
                <br /><br />
                <v-card flat>
                  <div class="contentContainer">

                    <div v-for="(bool, period, index) in ANSWERS[currentFlowAnswers].periodization" v-bind:key="index">
                      <div v-if="bool && (period == currentTab)">
                        <div class="planRoutine_momentHolder"  v-for="(moment, idx) in ANSWERS[currentFlowAnswers].periodMoments[period]" v-bind:key="idx">
                          <div class="planRoutine_momentTitle">

                            <div v-if="moment.actionAttributes.agendaItemTitle">
                              <img :src="moment.actionAttributes.actionIcon" width="80px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              {{ moment.actionAttributes.agendaItemTitle }}
                            </div>
                            <div v-else>
                              <SearchHumanActions 
                                :this-index="idx"
                                v-on:action-selected="actionSelected"
                                :number="2" />
                            </div>


                          </div>
                          <div class="planRoutine_detailsHolder">
                            <div class="planRoutine_controlsHolder">
                              <table width="100%">
                                <tr>
                                  <td width="33%" class="planRoutine_selectTimeOfDay">
                                    
                                    <v-select
                                      v-bind:key="'PLAN_EXERCISE_ROUTINE_ENDURANCE_selectTimeOfDay'+index"
                                      :rules="[(v) => !!v || 'Selection is required']"
                                      :ref="'PLAN_EXERCISE_ROUTINE_ENDURANCE_selectTimeOfDay'+index"  
                                      :items="PLAN_EXERCISE_ROUTINE_STEP_CONTENT.selectTimeOfDayItems"
                                      label="Select time of day"
                                      v-model="ANSWERS[currentFlowAnswers].periodMoments[period][idx].timeOfDay">
                                    </v-select>
                                    
                                  </td>
                                  
                                  <td width="34%" class="planRoutine_volumetype">
                                    
                                    <v-select
                                      v-bind:key="'PLAN_EXERCISE_ROUTINE_volumeType'+index"
                                      :rules="[(v) => !!v || 'Selection is required']"
                                      :ref="'PLAN_EXERCISE_ROUTINE_volumeType'+index"  
                                      :items="['reps', 'duration', 'distance', 'tabata']"
                                      label="Change volume type"
                                      v-model="ANSWERS[currentFlowAnswers].periodMoments[period][idx].actionAttributes.volumeType">
                                    </v-select>
                                    
                                  </td>
                                  <td width="33%" class="planRoutine_intensityType">
                                    
                                    <v-select
                                      v-bind:key="'PLAN_EXERCISE_ROUTINE_intensityType'+index"
                                      :rules="[(v) => !!v || 'Selection is required']"
                                      :ref="'PLAN_EXERCISE_ROUTINE_intensityType'+index"  
                                      :items="['none', '%1rm', 'load', 'tabata', 'pace', '%effort', '%mhr']"
                                      label="Change intensity type"
                                      v-model="ANSWERS[currentFlowAnswers].periodMoments[period][idx].actionAttributes.intensityType">
                                    </v-select>
                                    
                                  </td>
                                  
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="planRoutine_addMomentIcon" v-on:click="addMomentToPeriod(period)">
                          <i class="fas fa-plus-circle"></i>
                        </div>
                        


                        
                      </div>
                      
                    </div>

                  </div>
                </v-card>
              </v-tab-item>

              <!-- HYPERTROPHY PERIOD TAB ITEM-->
              <v-tab-item v-if="ANSWERS[currentFlowAnswers].periodization[`Hypertrophy`]">
                <br /><br />
                <v-card flat>
                  <div class="contentContainer">

                    <div v-for="(bool, period, index) in ANSWERS[currentFlowAnswers].periodization" v-bind:key="index">
                      <div v-if="bool && (period == currentTab)">
                        <div class="planRoutine_momentHolder"  v-for="(moment, idx) in ANSWERS[currentFlowAnswers].periodMoments[period]" v-bind:key="idx">
                          <div class="planRoutine_momentTitle">

                            <div v-if="moment.actionAttributes.agendaItemTitle">
                              <img :src="moment.actionAttributes.actionIconBlack" width="80px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              {{ moment.actionAttributes.agendaItemTitle }}
                            </div>
                            <div v-else>
                              <SearchHumanActions 
                                :this-index="idx"
                                v-on:action-selected="actionSelected"
                                :number="3" />
                            </div>


                          </div>
                          <div class="planRoutine_detailsHolder">
                            <div class="planRoutine_controlsHolder">
                              <table width="100%">
                                <tr>
                                  <td width="33%" class="planRoutine_selectTimeOfDay">
                                    
                                    <v-select
                                      v-bind:key="'PLAN_EXERCISE_ROUTINE_HYPERTROPHY_selectTimeOfDay'+index"
                                      :rules="[(v) => !!v || 'Selection is required']"
                                      :ref="'PLAN_EXERCISE_ROUTINE_HYPERTROPHY_selectTimeOfDay'+index"  
                                      :items="PLAN_EXERCISE_ROUTINE_STEP_CONTENT.selectTimeOfDayItems"
                                      label="Select time of day"
                                      v-model="ANSWERS[currentFlowAnswers].periodMoments[period][idx].timeOfDay">
                                    </v-select>
                                    
                                  </td>
                                  
                                  <td width="34%" class="planRoutine_volumetype">
                                    
                                    <v-select
                                      v-bind:key="'PLAN_EXERCISE_ROUTINE_volumeType'+index"
                                      :rules="[(v) => !!v || 'Selection is required']"
                                      :ref="'PLAN_EXERCISE_ROUTINE_volumeType'+index"  
                                      :items="['reps', 'duration', 'distance', 'tabata']"
                                      label="Change volume type"
                                      v-model="ANSWERS[currentFlowAnswers].periodMoments[period][idx].actionAttributes.volumeType">
                                    </v-select>
                                    
                                  </td>
                                  <td width="33%" class="planRoutine_intensityType">
                                    
                                    <v-select
                                      v-bind:key="'PLAN_EXERCISE_ROUTINE_intensityType'+index"
                                      :rules="[(v) => !!v || 'Selection is required']"
                                      :ref="'PLAN_EXERCISE_ROUTINE_intensityType'+index"  
                                      :items="['none', '%1rm', 'load', 'tabata', 'pace', '%effort', '%mhr']"
                                      label="Change intensity type"
                                      v-model="ANSWERS[currentFlowAnswers].periodMoments[period][idx].actionAttributes.intensityType">
                                    </v-select>
                                    
                                  </td>
                                  
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="planRoutine_addMomentIcon" v-on:click="addMomentToPeriod(period)">
                          <i class="fas fa-plus-circle"></i>
                        </div>
                      </div>                 
                    </div>
                  </div>
                </v-card>
              </v-tab-item>

              <!-- STRENGTH PERIOD TAB ITEM-->
              <v-tab-item v-if="ANSWERS[currentFlowAnswers].periodization[`Strength`]">
                <br /><br />
                <v-card flat>
                  <div class="contentContainer">

                    <div v-for="(bool, period, index) in ANSWERS[currentFlowAnswers].periodization" v-bind:key="index">
                      <div v-if="bool && (period == currentTab)">
                        <div class="planRoutine_momentHolder"  v-for="(moment, idx) in ANSWERS[currentFlowAnswers].periodMoments[period]" v-bind:key="idx">
                          <div class="planRoutine_momentTitle">

                            <div v-if="moment.actionAttributes.agendaItemTitle">
                              <img :src="moment.actionAttributes.actionIcon" width="80px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              {{ moment.actionAttributes.agendaItemTitle }}
                            </div>
                            <div v-else>
                              <SearchHumanActions 
                                :this-index="idx"
                                v-on:action-selected="actionSelected"
                                :number="4" />
                            </div>


                          </div>
                          <div class="planRoutine_detailsHolder">
                            <div class="planRoutine_controlsHolder">
                              <table width="100%">
                                <tr>
                                  <td width="33%" class="planRoutine_selectTimeOfDay">
                                    
                                    <v-select
                                      v-bind:key="'PLAN_EXERCISE_ROUTINE_STRENGTH_selectTimeOfDay'+index"
                                      :rules="[(v) => !!v || 'Selection is required']"
                                      :ref="'PLAN_EXERCISE_ROUTINE_STRENGTH_selectTimeOfDay'+index"  
                                      :items="PLAN_EXERCISE_ROUTINE_STEP_CONTENT.selectTimeOfDayItems"
                                      label="Select time of day"
                                      v-model="ANSWERS[currentFlowAnswers].periodMoments[period][idx].timeOfDay">
                                    </v-select>
                                    
                                  </td>
                                  
                                  <td width="34%" class="planRoutine_volumetype">
                                    
                                    <v-select
                                      v-bind:key="'PLAN_EXERCISE_ROUTINE_volumeType'+index"
                                      :rules="[(v) => !!v || 'Selection is required']"
                                      :ref="'PLAN_EXERCISE_ROUTINE_volumeType'+index"  
                                      :items="['reps', 'duration', 'distance', 'tabata']"
                                      label="Change volume type"
                                      v-model="ANSWERS[currentFlowAnswers].periodMoments[period][idx].actionAttributes.volumeType">
                                    </v-select>
                                    
                                  </td>
                                  <td width="33%" class="planRoutine_intensityType">
                                    
                                    <v-select
                                      v-bind:key="'PLAN_EXERCISE_ROUTINE_intensityType'+index"
                                      :rules="[(v) => !!v || 'Selection is required']"
                                      :ref="'PLAN_EXERCISE_ROUTINE_intensityType'+index"  
                                      :items="['none', '%1rm', 'load', 'tabata', 'pace', '%effort', '%mhr']"
                                      label="Change intensity type"
                                      v-model="ANSWERS[currentFlowAnswers].periodMoments[period][idx].actionAttributes.intensityType">
                                    </v-select>
                                    
                                  </td>
                                  
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="planRoutine_addMomentIcon" v-on:click="addMomentToPeriod(period)">
                          <i class="fas fa-plus-circle"></i>
                        </div>
                      </div>                 
                    </div>

                  </div>
                </v-card>
              </v-tab-item>

            </v-tabs>


            
            
          </div>  
        </div>
        <div class="stepButtonHolder">
          <v-btn @click="overlayOff" dark>Cancel</v-btn>
          <v-btn @click="backAStep" v-if="currentStep > 1" dark>Back</v-btn>
          <v-btn @click="handleAnswer('PLAN_EXERCISE_ROUTINE')">Continue</v-btn>
        </div>
      </div>


      <!-- PLAN_ROUTINE -->
      <div class="stepHolder" v-if="PLAN_ROUTINE_STEP_ACTIVE">
        <div class="stepCard">
          <div class="stimulusQuestion lessMinHeight" :key="'PLAN_ROUTINE_stimulusQuestion'">
            <h1>{{ this.PLAN_ROUTINE_STEP_CONTENT.thoughtStimulusQuestion }}</h1>
          </div>
          <div class="stepTextField lessMarginTop">

            <div v-for="(bool, day, index) in ANSWERS[currentFlowAnswers].daysOfWeek" v-bind:key="index">
              <div v-if="ANSWERS[currentFlowAnswers].daysOfWeek[day]">
 
                <div class="planRoutine_dayOfWeekHolder">
                  <div class="planRoutine_dayOfWeekTitle">
                    {{ day.toUpperCase() }}s
                  </div>

                  <div class="planRoutine_momentHolder" v-for="(moment, idx) in ANSWERS[currentFlowAnswers].routineMoments[day]" v-bind:key="idx">
                    <div class="planRoutine_momentTitle">
                      {{ idx+1 }}: &nbsp;{{ moment.momentTitle }}
                    </div>
                    <div class="planRoutine_detailsHolder">
                      <div class="planRoutine_controlsHolder">
                        <table width="100%">
                          <tr>
                            <td width="33%" class="planRoutine_selectTimeOfDay">
                              
                              <v-select
                                
                                v-bind:key="'PLAN_ROUTINE_selectTimeOfDay'+index"
                                :rules="[(v) => !!v || 'Selection is required']"
                                :ref="'PLAN_ROUTINE_selectTimeOfDay'+index"  
                                :items="PLAN_ROUTINE_STEP_CONTENT.selectTimeOfDayItems"
                                label="Select time of day"
                                v-model="ANSWERS[currentFlowAnswers].timeOfDay[day][idx]">
                              </v-select>
                              
                            </td>
                            <td width="34%" class="planRoutine_selectSetCount">
                              <v-select
                                
                                v-bind:key="'PLAN_ROUTINE_selectSetNumber'+index"
                                :rules="[(v) => !!v || 'Selection is required']"
                                :ref="'PLAN_ROUTINE_selectSetNumber'+index"  
                                :items="PLAN_ROUTINE_STEP_CONTENT.selectSetNumberItems"
                                label="Select number of sets"
                                v-model="ANSWERS[currentFlowAnswers].setCount[day][idx]">
                              </v-select>
                            </td>
                            <td width="33%" class="planRoutine_selectActionStepUom">
                              <v-select
                                
                                v-bind:key="'PLAN_ROUTINE_selectActionStepUom'+index"
                                :rules="[(v) => !!v || 'Selection is required']"
                                :ref="'PLAN_ROUTINE_selectActionStepUom'+index"  
                                :items="PLAN_ROUTINE_STEP_CONTENT.selectActionStepUom"
                                label="Select action step unit of measure"
                                v-model="ANSWERS[currentFlowAnswers].actionStepUom[day][idx]">
                              </v-select>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                  

                  <div class="planRoutine_addMomentIcon" v-on:click="addMomentToRoutine(day)">
                    <i class="fas fa-plus-circle"></i>
                  </div>
                </div>  
              </div>
            </div>
            
          </div>  
        </div>
        <div class="stepButtonHolder">
          <v-btn @click="overlayOff" dark>Cancel</v-btn>
          <v-btn @click="backAStep" v-if="currentStep > 1" dark>Back</v-btn>
          <v-btn @click="handleAnswer('PLAN_ROUTINE')">Continue</v-btn>
        </div>
      </div>


      <!-- PLAN_WORK_SESSIONS -->
      <div class="stepHolder" v-if="PLAN_WORK_SESSIONS_STEP_ACTIVE">
        <div class="stepCard">
          <div class="stimulusQuestion lessMinHeight" :key="'PLAN_WORK_SESSIONS_stimulusQuestion'">
            <h1>{{ this.PLAN_WORK_SESSIONS_STEP_CONTENT.thoughtStimulusQuestion }}</h1>
          </div>
          <div class="stepTextField lessMarginTop">

            <div v-for="(bool, day, index) in ANSWERS[currentFlowAnswers].daysOfWeek" v-bind:key="index">
              <div v-if="ANSWERS[currentFlowAnswers].daysOfWeek[day]">
 
                <div class="planRoutine_dayOfWeekHolder">
                  <div class="planRoutine_dayOfWeekTitle">
                    {{ day.toUpperCase() }}s
                  </div>

                  <div class="planRoutine_momentHolder" v-for="(moment, idx) in ANSWERS[currentFlowAnswers].routineMoments[day]" v-bind:key="idx">
                    <div class="planRoutine_momentTitle">
                      {{ idx+1 }}: &nbsp;{{ moment.momentTitle }}
                    </div>
                    <div class="planRoutine_detailsHolder">
                      <div class="planRoutine_controlsHolder">
                        <table width="100%">
                          <tr>
                            <td width="50%" class="planRoutine_selectTimeOfDay">
                              
                              <v-select
                                
                                v-bind:key="'PLAN_WORK_SESSIONS_selectTimeOfDay'+index"
                                :rules="[(v) => !!v || 'Selection is required']"
                                :ref="'PLAN_WORK_SESSIONS_selectTimeOfDay'+index"  
                                :items="PLAN_WORK_SESSIONS_STEP_CONTENT.selectTimeOfDayItems"
                                label="Select time of day"
                                v-model="ANSWERS[currentFlowAnswers].timeOfDay[day][idx]">
                              </v-select>
                              
                            </td>
                            <td width="50%" class="planRoutine_selectSetCount">
                              <!--
                              <v-select
                                
                                v-bind:key="'PLAN_WORK_SESSIONS_selectSetNumber'+index"
                                :rules="[(v) => !!v || 'Selection is required']"
                                :ref="'PLAN_WORK_SESSIONS_selectSetNumber'+index"  
                                :items="PLAN_WORK_SESSIONS_STEP_CONTENT.selectSetNumberItems"
                                label="Select number of sets"
                                v-model="ANSWERS[currentFlowAnswers].setCount[day][idx]">
                              </v-select>
                              -->
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                  

                  <div class="planRoutine_addMomentIcon" v-on:click="addMomentToRoutine(day)">
                    <i class="fas fa-plus-circle"></i>
                  </div>
                </div>  
              </div>
            </div>
            
          </div>  
        </div>
        <div class="stepButtonHolder">
          <v-btn @click="overlayOff" dark>Cancel</v-btn>
          <v-btn @click="backAStep" v-if="currentStep > 1" dark>Back</v-btn>
          <v-btn @click="handleAnswer('PLAN_ROUTINE')">Continue</v-btn>
        </div>
      </div>


      <!-- PREDEFINED_NEXT_STEPS -->
      <div class="stepHolder" v-if="PREDEFINED_NEXT_STEPS_STEP_ACTIVE">
        <div class="stepCard">
          <div class="stimulusQuestion lessMinHeight" :key="'PREDEFINED_NEXT_STEPS_stimulusQuestion'">
            <h1>{{ this.PREDEFINED_NEXT_STEPS_STEP_CONTENT.thoughtStimulusQuestion }}</h1>
          </div>
          <div class="stepTextField lessMarginTop">

            <div v-for="(bool, day, index) in ANSWERS[currentFlowAnswers].daysOfWeek" v-bind:key="index">
              <div v-if="ANSWERS[currentFlowAnswers].daysOfWeek[day]">
 
                <div class="planRoutine_dayOfWeekHolder">
                  <div class="planRoutine_dayOfWeekTitle">
                    {{ day.toUpperCase() }}s
                  </div>

                  <div class="planRoutine_momentHolder" v-for="(moment, idx) in ANSWERS[currentFlowAnswers].routineMoments[day]" v-bind:key="idx">
                    <div class="planRoutine_momentTitle">
                      {{ idx+1 }}: &nbsp;{{ moment.momentTitle }}
                    </div>
                    <div class="planRoutine_detailsHolder">
                      <div class="planRoutine_controlsHolder">
                        <table width="100%">
                          <tr>
                            <td width="50%" class="planRoutine_selectTimeOfDay">
                              
                              <v-select
                                
                                v-bind:key="'PLAN_WORK_SESSIONS_selectTimeOfDay'+index"
                                :rules="[(v) => !!v || 'Selection is required']"
                                :ref="'PLAN_WORK_SESSIONS_selectTimeOfDay'+index"  
                                :items="PLAN_WORK_SESSIONS_STEP_CONTENT.selectTimeOfDayItems"
                                label="Select time of day"
                                v-model="ANSWERS[currentFlowAnswers].timeOfDay[day][idx]">
                              </v-select>
                              
                            </td>
                            <td width="50%" class="planRoutine_selectSetCount">
                              <!--
                              <v-select
                                
                                v-bind:key="'PLAN_WORK_SESSIONS_selectSetNumber'+index"
                                :rules="[(v) => !!v || 'Selection is required']"
                                :ref="'PLAN_WORK_SESSIONS_selectSetNumber'+index"  
                                :items="PLAN_WORK_SESSIONS_STEP_CONTENT.selectSetNumberItems"
                                label="Select number of sets"
                                v-model="ANSWERS[currentFlowAnswers].setCount[day][idx]">
                              </v-select>
                              -->
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                  

                  <div class="planRoutine_addMomentIcon" v-on:click="addMomentToRoutine(day)">
                    <i class="fas fa-plus-circle"></i>
                  </div>
                </div>  
              </div>
            </div>
            
          </div>  
        </div>
        <div class="stepButtonHolder">
          <v-btn @click="overlayOff" dark>Cancel</v-btn>
          <v-btn @click="backAStep" v-if="currentStep > 1" dark>Back</v-btn>
          <v-btn @click="handleAnswer('PREDEFINED_NEXT_STEPS')">Continue</v-btn>
        </div>
      </div>


      <!-- SETUP_NOTIFICATIONS -->
      <div class="stepHolder" v-if="SETUP_NOTIFICATIONS_STEP_ACTIVE">
        <div class="stepCard">
          <div class="stimulusQuestion" :key="'SETUP_NOTIFICATIONS_stimulusQuestion'">
            <h1>{{ this.SETUP_NOTIFICATIONS_STEP_CONTENT.thoughtStimulusQuestion }}</h1>
          </div>
          <div class="stepTextField" v-bind:key="'SETUP_NOTIFICATIONS'">
            <div class="notificationSwitchHolder">
              <div class="switchColumn">
                <v-switch 
                  v-bind:key="'SETUP_NOTIFICATIONS'" 
                  v-model="notificationToggle" 
                  @change="changeNotificationToggle"></v-switch>
              </div>
              <div class="switchLabel" v-bind:key="'SETUP_NOTIFICATIONS_switch'" >
                Web Notifications
              </div>
            </div>
          </div>  
        </div>
        <div class="stepButtonHolder">
          <v-btn @click="overlayOff" dark>Cancel</v-btn>
          <v-btn @click="backAStep" v-if="currentStep > 1" dark>Back</v-btn>
          <v-btn @click="handleAnswer('SETUP_NOTIFICATIONS')" >Continue</v-btn>
        </div>
      </div>


      <!-- OUTRO_CESSATION -->
      <div class="stepHolder" v-if="OUTRO_CESSATION_STEP_ACTIVE">
        <div class="stepCard">

          <div class="stimulusQuestion lessMinHeight" :key="'OUTRO_CESSATION_stimulusQuestion'">
            <h1>{{ this.OUTRO_CESSATION_STEP_CONTENT.thoughtStimulusQuestion }}</h1>
          </div>

          <div class="stepTextField lessMarginTop">
            <div class="routineTitle">
              <v-text-field
                v-bind:key="'ROUTINE_TITLE'"
                ref="ROUTINE_TITLE_input"
                id="ROUTINE_TITLE"
                autofocus
                label="Title for your routine"
                :rules="textRules"
                counter="45"
                clearable
                type="text"
                v-model="ANSWERS[currentFlowAnswers].routineTitle">
              </v-text-field>
            </div>
            <br />

            <div v-for="(day, index) in ANSWERS[currentFlowAnswers].cessationScheduleStartDay" v-bind:key="index">
              <div class="reviewRoutine_dayOfWeekHolder">
                <div class="planRoutine_dayOfWeekTitle">
                  Phase {{ ++index }}s
                </div>

                <div class="planRoutine_detailsHolder">
                  <div class="planRoutine_controlsHolder">
                    <table width="100%">
                      <tr>
                        <td width="50%" class="planCessationRoutine_selectStartDay">
                          
                          <div v-if="(--index) == 0">
                            Starting Day 1
                          </div>
                          <div v-else>
                            Starting Day {{ ANSWERS[currentFlowAnswers].cessationScheduleStartDay[index] }}
                          </div>
                        </td>
                        <td width="50%" class="planCessationRoutine_selectTimesPerDay">
                          {{ANSWERS[currentFlowAnswers].cessationScheduleTimesPerDay[index]}} times per day
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>  
            </div>
          </div>  
        </div>
        <div class="stepButtonHolder">
          <v-btn @click="overlayOff" dark>Cancel</v-btn>
          <v-btn @click="backAStep" v-if="currentStep > 1" dark>Back</v-btn>
          <v-btn @click="handleAnswer('OUTRO_CESSATION')">Submit</v-btn>
        </div>
      </div>



      <!-- OUTRO_EVENT_FOLLOWUP -->
      <div class="stepHolder" v-if="OUTRO_EVENT_FOLLOWUP_STEP_ACTIVE">
        <div class="stepCard">

          <div class="stimulusQuestion lessMinHeight" :key="'OUTRO_EVENT_FOLLOWUP_stimulusQuestion'">
            <h1>{{ this.OUTRO_EVENT_FOLLOWUP_STEP_CONTENT.thoughtStimulusQuestion }}</h1>
          </div>

          <div class="stepTextField lessMarginTop">
            <div class="routineTitle">
              <v-text-field
                v-bind:key="'ROUTINE_TITLE'"
                ref="ROUTINE_TITLE_input"
                id="ROUTINE_TITLE"
                autofocus
                label="Title for your routine"
                :rules="textRules"
                counter="45"
                clearable
                type="text"
                v-model="ANSWERS[currentFlowAnswers].routineTitle">
              </v-text-field>
            </div>
            <br />

            <div>
              <div class="reviewRoutine_dayOfWeekHolder">
                <div class="planRoutine_dayOfWeekTitle">
                  Following each of the {{ this.ANSWERS[currentFlowAnswers].eventsArray.length }} events, the following tasks will be performed:
                </div>

                <div class="planRoutine_detailsHolder">
                  <div class="planRoutine_controlsHolder">
                    <table width="100%">
                      <tr v-for="(task, index) in ANSWERS[currentFlowAnswers].tasksArray" v-bind:key="`tasks`+index">
                        <td width="50%" class="planCessationRoutine_selectStartDay">

                          {{ task.taskName }}

                        </td>
                        <td width="50%" class="planCessationRoutine_selectTimesPerDay">
                          {{ task.startDayOffset }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>  
            </div>
          </div>  
        </div>
        <div class="stepButtonHolder">
          <v-btn @click="overlayOff" dark>Cancel</v-btn>
          <v-btn @click="backAStep" v-if="currentStep > 1" dark>Back</v-btn>
          <v-btn @click="handleAnswer('OUTRO_EVENT_FOLLOWUP')" color="orange">Submit</v-btn>
        </div>
      </div>


      <!-- OUTRO_EXERCISE -->
      <div class="stepHolder" v-if="OUTRO_EXERCISE_STEP_ACTIVE">
        <div class="stepCard">

          <div class="stimulusQuestion lessMinHeight" :key="'OUTRO_EXERCISE_stimulusQuestion'">
            <h1>{{ this.OUTRO_EXERCISE_STEP_CONTENT.thoughtStimulusQuestion }}</h1>
          </div>

          <div class="stepTextField lessMarginTop">
            <div class="routineTitle">
              <v-text-field
                v-bind:key="'ROUTINE_TITLE'"
                ref="ROUTINE_TITLE_input"
                id="ROUTINE_TITLE"
                autofocus
                label="Title for your routine"
                :rules="textRules"
                counter="45"
                clearable
                type="text"
                v-model="ANSWERS[currentFlowAnswers].routineTitle">
              </v-text-field>
            </div>
            <br />
          </div>  
        </div>
        <div class="stepButtonHolder">
          <v-btn @click="overlayOff" dark>Cancel</v-btn>
          <v-btn @click="backAStep" v-if="currentStep > 1" dark>Back</v-btn>
          <v-btn @click="handleAnswer('OUTRO_EXERCISE')" color="orange">Submit</v-btn>
        </div>
      </div>

      <!-- OUTRO_LIST_DRAW -->
      <div class="stepHolder" v-if="OUTRO_LIST_DRAW_STEP_ACTIVE">
        <div class="stepCard">

          <div class="stimulusQuestion lessMinHeight" :key="'OUTRO_LIST_DRAW_stimulusQuestion'">
            <h1>{{ this.OUTRO_LIST_DRAW_STEP_CONTENT.thoughtStimulusQuestion }}</h1>
          </div>

          <div class="stepTextField lessMarginTop">
            <div class="routineTitle">
              <v-text-field
                v-bind:key="'ROUTINE_TITLE'"
                ref="ROUTINE_TITLE_input"
                id="ROUTINE_TITLE"
                autofocus
                label="Title for your routine"
                :rules="textRules"
                counter="45"
                clearable
                type="text"
                v-model="ANSWERS[currentFlowAnswers].routineTitle">
              </v-text-field>
            </div>
            <br />
          </div>  
        </div>
        <div class="stepButtonHolder">
          <v-btn @click="overlayOff" dark>Cancel</v-btn>
          <v-btn @click="backAStep" v-if="currentStep > 1" dark>Back</v-btn>
          <v-btn @click="handleAnswer('OUTRO_LIST_DRAW')" color="orange">Submit</v-btn>
        </div>
      </div>

      <!-- OUTRO_EVENT_PREP -->
      <div class="stepHolder" v-if="OUTRO_EVENT_PREP_STEP_ACTIVE">
        <div class="stepCard">

          <div class="stimulusQuestion lessMinHeight" :key="'OUTRO_EVENT_PREP_stimulusQuestion'">
            <h1>{{ this.OUTRO_EVENT_PREP_STEP_CONTENT.thoughtStimulusQuestion }}</h1>
          </div>

          <div class="stepTextField lessMarginTop">
            <div class="routineTitle">
              <v-text-field
                v-bind:key="'ROUTINE_TITLE'"
                ref="ROUTINE_TITLE_input"
                id="ROUTINE_TITLE"
                autofocus
                label="Title for your routine"
                :rules="textRules"
                counter="45"
                clearable
                type="text"
                v-model="ANSWERS[currentFlowAnswers].routineTitle">
              </v-text-field>
            </div>
            <br />
          </div>  
        </div>
        <div class="stepButtonHolder">
          <v-btn @click="overlayOff" dark>Cancel</v-btn>
          <v-btn @click="backAStep" v-if="currentStep > 1" dark>Back</v-btn>
          <v-btn @click="handleAnswer('OUTRO_EVENT_PREP')" color="orange">Submit</v-btn>
        </div>
      </div>


      <!-- OUTRO -->
      <div class="stepHolder" v-if="OUTRO_STEP_ACTIVE">
        <div class="stepCard">

          <div class="stimulusQuestion lessMinHeight" :key="'OUTRO_stimulusQuestion'">
            <h1>{{ this.OUTRO_STEP_CONTENT.thoughtStimulusQuestion }}</h1>
          </div>

          <div class="stepTextField lessMarginTop">
            <div class="routineTitle">
              <v-text-field
                v-bind:key="'ROUTINE_TITLE'"
                ref="ROUTINE_TITLE_input"
                id="ROUTINE_TITLE"
                autofocus
                label="Title for your routine"
                :rules="textRules"
                counter="45"
                clearable
                type="text"
                v-model="ANSWERS[currentFlowAnswers].routineTitle">
              </v-text-field>
            </div>
            <br />

            <div v-for="(bool, day, index) in ANSWERS[currentFlowAnswers].daysOfWeek" v-bind:key="index">
              <div v-if="bool">
                <div class="reviewRoutine_dayOfWeekHolder">
                  <div class="planRoutine_dayOfWeekTitle">
                    {{ day.toUpperCase() }}s
                  </div>
                  <div class="reviewRoutine_momentHolder" v-for="(moment, idx) in ANSWERS[currentFlowAnswers].routineMoments[day]" v-bind:key="idx">
                    <div class="reviewRoutine_momentTitle">
                      {{ idx+1 }}: &nbsp;{{ moment.momentTitle }}&nbsp;&nbsp;&nbsp;
                      <span style="font-size:.75em;">{{ ANSWERS[currentFlowAnswers].timeOfDay[day][idx] }}&nbsp;&nbsp;&nbsp;
                      {{ ANSWERS[currentFlowAnswers].setCount[day][idx] }} set{{ (ANSWERS[currentFlowAnswers].setCount[day][idx] > 1) ? "s" : null}}</span>
                    </div>
                  </div>
                </div>  
              </div>
            </div>


            <div v-if="routineType == 'everyday habit'" class="reviewRoutine_dayOfWeekHolder">
              <div class="planRoutine_dayOfWeekTitle">
                Everyday
              </div>
              <div class="reviewRoutine_momentHolder">
                <div class="reviewRoutine_momentTitle">
                  1: &nbsp;{{ ANSWERS[currentFlowAnswers].whatToRoutineStatement }}&nbsp;&nbsp;&nbsp;
                  <span style="font-size:.75em;">{{ ANSWERS[currentFlowAnswers].timesPerDay }}&nbsp;times per day</span>
                </div>
              </div>
            </div>


          </div>  
        </div>
        <div class="stepButtonHolder">
          <v-btn @click="overlayOff" dark>Cancel</v-btn>
          <v-btn @click="backAStep" v-if="currentStep > 1" dark>Back</v-btn>
          <v-btn @click="handleAnswer('OUTRO')">Submit</v-btn>
        </div>
      </div>

      <br />
      <br />


    </div> <!-- END OF WRAPPER -->

  </div>
  
</template>

<script>
  //Some example imports you could have:
    //import Worker from "worker-loader!@/worker";
    import SearchHumanActions from '@/components/SearchHumanActions'
    import SearchSequences from '@/components/SearchSequences'
    import minderModule from '@/store/minder'
    //import firebase from '@/firebase'
    //import momentModule from '@/store/moment'
    //import feelingModule from '@/store/feeling'
    import settingsModule from '@/store/settings'
    import experienceModule from '@/store/experience'
    import signinModule from '@/store/signin'
    import imageModule from '@/store/image'
    import { mapGetters, mapActions } from 'vuex'
    import routineModule from '@/store/routine'
    import '@/plugins/vuetify'
    import moment from 'moment-timezone'
    import Vue from 'vue'
    import { v4 as uuidv4 } from 'uuid'

    

  export default {
    name: "RoutineOverlayAdmin",
    components: {
      SearchHumanActions,
      SearchSequences
      //CountdownTimer
    },
    props: {

      parentCategory: {
        type: String
      },

      parentView: {
        type: String
      },
      routineType: {
        type: String
      },
      selectedItem: {
        type: Object
      }
    },

    data () {
      return {
        flowInitialized: false,

        currentView: 'INSERT_VIEW_HERE',
        imageComplete: false,
        categoryImageSrc: '',
        categoryCreditText: '',
        categoryImageLink: '',

        currentStep: 1,
        currentStepId: null,
        currentStepContent: null,
        currentFlowManifest: null,
        currentFlowAnswers: null,

        actionAfterAction: null,

        randomNumber: null,

        textRules: [
          (v) => !!v || 'Input is required',
          (v) => (v && v.length <= 45) || 'Max 45 characters'
        ],

        selectRules: [
          (v) => !!v || 'Input is required',
        ],

        thereIsAStartDayError: {},
        thereIsATimesPerDayError: {},

        currentTab: "Pre-Training",
        tab: null,

        
        menu: [false],

        showSearchAction: {},
        showSearchSequence: {},

        //INSTRUCTIONS FOR ADDING FUTURE FLOWS:
        //1 - Create the step template above
        //1 - CREATE A MANIFEST OF STEPS FOR THE FLOW, OR ADD STEP TO EXISTING MANIFEST
        //2 - CREATE A FLOW ANSWER OBJECT, OR ADD TO AN EXISTING ONE
        //3 - CREATE STEP OBJECTS FOR ANY UNIQUE FLOW STEPS NOT ALREADY DEFINED



        ANSWERS: {

          

          ROUTINE_FLOW_ANSWERS: {
            /* Generic Flow - V2 */
            v2_routinePeriodsObject: {
              'Period0':  {
                periodDaysOfWeek: [],
                periodDesc: null,
                periodName: 'Period0',
                periodNumSets: "0",
                periodNumWeeks: "0",
                periodSequenceNumber: 1,
                periodType: null,
              },
                
            },
            v2_periodMoments: {
              'Period0': [],
            },

            /* Cooking Flow */
            cookingDays: {
              'Sunday': false,
              'Monday': false,
              'Tuesday': false,
              'Wednesday': false,
              'Thursday': false,
              'Friday': false,
              'Saturday': false,
            },
            menuId: 'Menu 1',
            routineCookingMoments: [],



            /* Other Flows */
            daysOfWeek: {
              'Sunday': false,
              'Monday': false,
              'Tuesday': false,
              'Wednesday': false,
              'Thursday': false,
              'Friday': false,
              'Saturday': false,
            },
            routineMoments: {
              'Sunday': [],
              'Monday': [],
              'Tuesday': [],
              'Wednesday': [],
              'Thursday': [],
              'Friday': [],
              'Saturday': [],
            },            
            timeOfDay: {
              'Sunday': [],
              'Monday': [],
              'Tuesday': [],
              'Wednesday': [],
              'Thursday': [],
              'Friday': [],
              'Saturday': [],
            },
            setCount: {
              'Sunday': [],
              'Monday': [],
              'Tuesday': [],
              'Wednesday': [],
              'Thursday': [],
              'Friday': [],
              'Saturday': [],
            },
            actionStepUom: {
              'Sunday': [],
              'Monday': [],
              'Tuesday': [],
              'Wednesday': [],
              'Thursday': [],
              'Friday': [],
              'Saturday': [],
            },
            periodization: {
              'Pre-Training': false,
              'Endurance': true,
              'Hypertrophy': true,
              'Strength': true,
            },
            periodWeeks: {
              'Pre-Training': "5",
              'Endurance': "3",
              'Hypertrophy': "3",
              'Strength': "3",
            },
            periodStartSetCount: {
              'Pre-Training': "1",
              'Endurance': "3",
              'Hypertrophy': "3",
              'Strength': "3",
            },
            periodFinishSetCount: {
              'Pre-Training': "2",
              'Endurance': "4",
              'Hypertrophy': "3",
              'Strength': "3",
            },
            periodStartRepCount: {
              'Pre-Training': "1",
              'Endurance': "12",
              'Hypertrophy': "12",
              'Strength': "5",
            },
            periodFinishRepCount: {
              'Pre-Training': "12",
              'Endurance': "18",
              'Hypertrophy': "8",
              'Strength': "5",
            },
            periodMoments: {
              'Pre-Training': [],
              'Endurance': [],
              'Hypertrophy': [],
              'Strength': [],
            },
            eventsArray: [
              {
                'eventName': null,
                'eventDayStamp': null,
              },
            ],
            tasksArray: [
              {
                'taskName': null,
                'startDayOffsets': null,
                'routineQuestionGroupId': null,
                'routineQuestionGroupName': null,
              },
            ],
            routineQuestionGroups: {
              'group0': {
                'questionGroupName': null,
                'questionGroupId': 'group0',
                'eventAttributeQuestions': [],
                'eventFollowUpQuestions': []
              },
            },
            routineUserDefinedAttributes: {},
            userDefinedAttributeNameHolder: null,
            preDefinedNextSteps: [],
            routineQuestions: {},
            cessationScheduleStartDay: ['0','3', '5', '8'],     //Used only for the cessation routine
            cessationScheduleTimesPerDay: ['9', '8', '7', '6'],
            timesPerDay: null,
            intentMeanStatement: null,  //intentMeanStatement provied by system  (this.category)
            endStatement: null,
            valueStatement: null,
            actionMeanStatement: "following this routine",
            actionAfterActionStatement: null,   //becomes a second next step in minder, also added to category or parentCategory node
            notificationsBool: false,
            wakeUpTime: null,
            whatToRoutineAction: {
              actionDuration: 0,
              actionIconBlack: null,
              actionIconGrey: null,
              actionId: null,
              actionUom: null,
              agendaItemTitle: null,
              actionLevel: null,
              actionLoadType: null,
              intensityType: null,
              intentMeanCategory: null,
              isAerobic: false,
              isAnaerobic: false,
              isIsometric: false,
              isLoadable: false,
              repCountOverride: 0,
              restDuration: 0,
              setCountOverride: 0,
              volumeType: null,
            },
            whatToRoutineStatement: null,
            routineTitle: null,
            userSelectedRoutineType: null,  //Used only when creating a generic routine without pre-defined intent means
            routineTags: []
            
          },
          
        },
        
        FLOWS: {

          ROUTINE_FLOW_MANIFEST: [
            'WHAT_TO_ROUTINE',
            'DAYS_OF_WEEK',
            'PLAN_ROUTINE',
            'SETUP_NOTIFICATIONS',
            'OUTRO'
          ],

          ROUTINE_WORK_SESSIONS_FLOW_MANIFEST: [
            'WHAT_TO_ROUTINE',
            'DAYS_OF_WEEK',
            'PLAN_WORK_SESSIONS',
            'PREDEFINED_NEXT_STEPS',
            'USER_DEFINED_ATTRIBUTES',
            'OUTRO'
          ],

          
          ROUTINE_COOKING_FLOW_MANIFEST: [
            'SELECT_COOKING_DAYS',
            'SELECT_MENU',
            'OUTRO'
          ],


          ROUTINE_GENERIC_FLOW_MANIFEST: [
            'ADD_PERIODS',
            'ADD_MOMENTS_TO_PERIODS',
            'USER_DEFINED_ATTRIBUTES',
            'OUTRO'
          ],


          ROUTINE_EXERCISE_FLOW_MANIFEST: [
            'WHAT_TO_ROUTINE',
            'DAYS_OF_WEEK',
            'PLAN_ROUTINE',
            'OUTRO'
          ],

          ROUTINE_EXERCISE2_FLOW_MANIFEST: [
            'SELECT_PERIODIZATION',
            'DAYS_OF_WEEK',
            'PLAN_EXERCISE_ROUTINE',
            'OUTRO_EXERCISE'
          ],


          ROUTINE_CESSATION_FLOW_MANIFEST: [
            'WHAT_TO_ROUTINE',
            'PLAN_CESSATION_SCHEDULE',
            'OUTRO_CESSATION'
          ],

          ROUTINE_EVERYDAY_HABIT_FLOW_MANIFEST: [
            'WHAT_TO_ROUTINE',
            'TIMES_PER_DAY',
            'OUTRO'
          ],

          ROUTINE_EVENT_FOLLOWUP_FLOW_MANIFEST: [
            'WHAT_TO_ROUTINE',
            'ADD_EVENTS',
            'ADD_QUESTION_GROUP',
            'ADD_FOLLOW_ON_TASKS',
            'OUTRO_EVENT_FOLLOWUP'
          ],

          ROUTINE_EVENT_PREP_FLOW_MANIFEST: [
            'WHAT_TO_ROUTINE',
            'ADD_EVENT',      //NEW
            'ADD_PREP_TASKS',   //NEW
            'OUTRO_EVENT_PREP'    //NEW
          ],

          ROUTINE_LIST_DRAW_FLOW_MANIFEST: [
            'WHAT_TO_ROUTINE',
            'ADD_LISTINGS',     //NEW
            'DAYS_OF_WEEK',
            'OUTRO_LIST_DRAW'     //NEW
          ],
        
        },


        //STEP VARIABLES
        ADD_EVENT_STEP_ACTIVE: false,
        ADD_EVENT_STEP_CONTENT: {
          thoughtStimulusQuestion: 'Add an event to prepare for',
          stepInstructions: '',
          stepHint: '',
        },
        ADD_PREP_TASKS_STEP_ACTIVE: false,
        ADD_PREP_TASKS_STEP_CONTENT: {
          thoughtStimulusQuestion: 'Add prep tasks',
          stepInstructions: '',
          stepHint: '',
        },
        ADD_LISTINGS_STEP_ACTIVE: false,
        ADD_LISTINGS_STEP_CONTENT: {
          thoughtStimulusQuestion: 'Add listings',
          stepInstructions: '',
          stepHint: '',
        },
        OUTRO_EVENT_PREP_STEP_ACTIVE: false,
        OUTRO_EVENT_PREP_STEP_CONTENT: {
          thoughtStimulusQuestion: 'Title, review & submit',
          stepInstructions: '',
          stepHint: '',
        },
        OUTRO_LIST_DRAW_STEP_ACTIVE: false,
        OUTRO_LIST_DRAW_STEP_CONTENT: {
          thoughtStimulusQuestion: 'Title, review & submit',
          stepInstructions: '',
          stepHint: '',
        },
        




        SELECT_COOKING_DAYS_STEP_ACTIVE: false,
        SELECT_COOKING_DAYS_STEP_CONTENT: {
          thoughtStimulusQuestion: 'Select cooking days',
          stepInstructions: '',
          stepHint: '',
        },

        SELECT_MENU_STEP_ACTIVE: false,
        SELECT_MENU_STEP_CONTENT: {
          thoughtStimulusQuestion: 'Select menu',
          stepInstructions: '',
          stepHint: '',
        },
        

        ADD_MOMENTS_TO_PERIODS_STEP_ACTIVE: false,
        ADD_MOMENTS_TO_PERIODS_STEP_CONTENT: {
          thoughtStimulusQuestion: 'Add moments to periods',
          stepInstructions: '',
          stepHint: '',
          selectTimeOfDayItems: [
            'morning',
            'afternoon',
            'evening',
            'late night',
          ],
          
        },



        PREDEFINED_NEXT_STEPS_STEP_ACTIVE: false,
        PREDEFINED_NEXT_STEPS_STEP_CONTENT: {
          thoughtStimulusQuestion: 'Add any pre-defined next steps',
          stepInstructions: '',
          stepHint: '',
          
        },


        USER_DEFINED_ATTRIBUTES_STEP_ACTIVE: false,
        USER_DEFINED_ATTRIBUTES_STEP_CONTENT: {
          thoughtStimulusQuestion: 'Add any user-defined attributes questions',
          stepInstructions: '',
          stepHint: '',
          
        },


        ROUTINE_TYPE_STEP_ACTIVE: false,
        ROUTINE_TYPE_STEP_CONTENT: {
          thoughtStimulusQuestion: 'What type of routine you want to make?',
          stepInstructions: '',
          stepHint: '',
          selectRoutineTypeItems: [
            'Exercise routine',
            'Everyday habit routine',
            'Cooking routine',
          ]
        },


        WHAT_TO_ROUTINE_STEP_ACTIVE: false,
        WHAT_TO_ROUTINE_STEP_CONTENT: {
          thoughtStimulusQuestion: 'What do you want to routine?',
          stepInstructions: '',
          stepHint: '',
        },


        SELECT_PERIODIZATION_STEP_ACTIVE: false,
        SELECT_PERIODIZATION_STEP_CONTENT: {
          thoughtStimulusQuestion: 'Select exercise periods',
          stepInstructions: '',
          stepHint: '',
        },


        DAYS_OF_WEEK_STEP_ACTIVE: false,
        DAYS_OF_WEEK_STEP_CONTENT: {
          thoughtStimulusQuestion: 'Select days of week',
          stepInstructions: '',
          stepHint: '',
        },


        TIMES_PER_DAY_STEP_ACTIVE: false,
        TIMES_PER_DAY_STEP_CONTENT: {
          thoughtStimulusQuestion: 'Select times per day',
          stepInstructions: '',
          stepHint: '',
          selectItems: ['1','2','3','4','5','6','7','8','9','10'],
        },

        PLAN_ROUTINE_STEP_ACTIVE: false,
        PLAN_ROUTINE_STEP_CONTENT: {
          thoughtStimulusQuestion: 'Plan your routine',
          stepInstructions: '',
          stepHint: '',
          selectSetNumberItems: ['1','2','3','4','5','6','7','8','9','10'],
          selectActionStepUom: [
            'cycles',
            'miles',
            'minutes',
            'laps',
            'repetitions',
            'rounds',
            'seconds',
          ],
          selectTimeOfDayItems: [
            'morning',
            'afternoon',
            'evening',
            'late night',
          ],
        },

        PLAN_WORK_SESSIONS_STEP_ACTIVE: false,
        PLAN_WORK_SESSIONS_STEP_CONTENT: {
          thoughtStimulusQuestion: 'Plan your action sessions',
          stepInstructions: '',
          stepHint: '',
          selectSetNumberItems: ['1','2','3','4','5','6','7','8','9','10'],
          selectTimeOfDayItems: [
            'morning',
            'afternoon',
            'evening',
            'late night',
          ],
        },

        PLAN_EXERCISE_ROUTINE_STEP_ACTIVE: false,
        PLAN_EXERCISE_ROUTINE_STEP_CONTENT: {
          thoughtStimulusQuestion: 'Plan your routine',
          stepInstructions: '',
          stepHint: '',
          selectSetNumberItems: ['1','2','3','4','5','6','7','8','9','10'],
          selectTimeOfDayItems: [
            'morning',
            'afternoon',
            'evening',
            'late night',
          ],
        },

        PLAN_CESSATION_SCHEDULE_STEP_ACTIVE: false,
        PLAN_CESSATION_SCHEDULE_STEP_CONTENT: {
          thoughtStimulusQuestion: 'Plan your cessation schedule',
          stepInstructions: 'Select phase start day and number of times per day you want to smoke in that phase.  Add additional phases and reduce the number of times you smoke with each added phase ',
          stepHint: '',
          selectTimesPerDay: ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15'],
          selectStartDay: [
            '1', '2', '3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30'
          ],
        },

        ADD_EVENTS_STEP_ACTIVE: false,
        ADD_EVENTS_STEP_CONTENT: {
          thoughtStimulusQuestion: 'Enter event date & title:',
          stepInstructions: '',
          stepHint: '',
        },


        ADD_PERIODS_STEP_ACTIVE: false,
        ADD_PERIODS_STEP_CONTENT: {
          thoughtStimulusQuestion: 'Add periods:',
          stepInstructions: '',
          stepHint: '',
        },


        ADD_QUESTION_GROUP_STEP_ACTIVE: false,
        ADD_QUESTION_GROUP_STEP_CONTENT: {
          thoughtStimulusQuestion: 'Add question group:',
          stepInstructions: '',
          stepHint: '',
        },

        ADD_FOLLOW_ON_TASKS_STEP_ACTIVE: false,
        ADD_FOLLOW_ON_TASKS_STEP_CONTENT: {
          thoughtStimulusQuestion: 'Enter follow-on tasks:',
          stepInstructions: '',
          stepHint: '',
        },


        SETUP_NOTIFICATIONS_STEP_ACTIVE: false,
        SETUP_NOTIFICATIONS_STEP_CONTENT: {
          thoughtStimulusQuestion: 'Allow notifications',
          stepInstructions: '',
          stepHint: '',
        },

        OUTRO_STEP_ACTIVE: false,
        OUTRO_STEP_CONTENT: {
          thoughtStimulusQuestion: 'Title, review & submit',
          stepInstructions: '',
          stepHint: '',
        },


        OUTRO_CESSATION_STEP_ACTIVE: false,
        OUTRO_CESSATION_STEP_CONTENT: {
          thoughtStimulusQuestion: 'Title, review & submit',
          stepInstructions: '',
          stepHint: '',
        },

        OUTRO_EXERCISE_STEP_ACTIVE: false,
        OUTRO_EXERCISE_STEP_CONTENT: {
          thoughtStimulusQuestion: 'Title, review & submit',
          stepInstructions: '',
          stepHint: '',
        },

        OUTRO_EVENT_FOLLOWUP_STEP_ACTIVE: false,
        OUTRO_EVENT_FOLLOWUP_STEP_CONTENT: {
          thoughtStimulusQuestion: 'Title, review & submit',
          stepInstructions: '',
          stepHint: '',
        },


      }
    },

    computed: {

      ...mapGetters('auth', {
        profile: 'profile',
        loggedIn: 'loggedIn',
      }),
      ...mapGetters('tally', [
        'selectUnitOfMeasureList'
      ]),
      notificationToggle: {
        set: function (value) {
          this.$store.commit('settings/SET_NOTIFICATION_TOGGLE', { notificationToggle: value }, { root: true })
        },
        get: function () {
          return this.$store.state.settings.notificationToggle
        }
      },

      whatToRoutine () {
        var cat = this.parentCategory
        if(cat) {
          //lower case all
          cat = cat.toLowerCase()
          //capitalize first letter
          return cat.charAt(0).toUpperCase() + cat.slice(1);
        }else{
          return null
        }
      },

      todayDayStamp () {
        var now = moment()
        var dayStamp = now.format("YYYY") + "-" + now.format("MM") + "-" + now.format("DD")
        return dayStamp
      },

      routineQuestionDependencyList () {
        if(Object.keys(this.ANSWERS[this.currentFlowAnswers].routineQuestionGroups).length > 0) {
          var dependencyList = []
          Object.keys(this.ANSWERS[this.currentFlowAnswers].routineQuestionGroups).forEach((key) => {
            this.ANSWERS[this.currentFlowAnswers].routineQuestionGroups[key].eventAttributeQuestions.forEach((question) => {
              if(question.eventVariableName) {
                dependencyList.push(question.eventVariableName)
              }
            })
          })
          return dependencyList
        }else{
          return []
        }
      },

      questionGroupList () {
        if(Object.keys(this.ANSWERS[this.currentFlowAnswers].routineQuestionGroups).length > 0) {
          var questionGroupList = []
          Object.keys(this.ANSWERS[this.currentFlowAnswers].routineQuestionGroups).forEach((key) => {
            var obj = {
              'name': this.ANSWERS[this.currentFlowAnswers].routineQuestionGroups[key].questionGroupName,
              'value':this.ANSWERS[this.currentFlowAnswers].routineQuestionGroups[key].questionGroupId
            }

            questionGroupList.push(obj)
          })
          return questionGroupList
        }else{
          return []
        }
      },
      userDefinedAttributesList () {
        if(Object.keys(this.ANSWERS[this.currentFlowAnswers].routineUserDefinedAttributes).length > 0) {
          var userDefinedAttributeList = []
          Object.keys(this.ANSWERS[this.currentFlowAnswers].routineUserDefinedAttributes).forEach((key) => {
            var obj = {
              'attributeStimulusQuestion': this.ANSWERS[this.currentFlowAnswers].routineUserDefinedAttributes[key].attributeStimulusQuestion,
              'attributeVariableName': this.ANSWERS[this.currentFlowAnswers].routineUserDefinedAttributes[key].attributeVariableName,
              'attributeVariableValue': this.ANSWERS[this.currentFlowAnswers].routineUserDefinedAttributes[key].attributeVariableValue,
              'attributeVariableUom': this.ANSWERS[this.currentFlowAnswers].routineUserDefinedAttributes[key].attributeVariableUom,
            }
            userDefinedAttributeList.push(obj)
          })
          return userDefinedAttributeList
        }else{
          return []
        }
      }



    },
    watch: {
      //MAIN ENTRY POINT
      routineType: function(newValue) {
        if(newValue) {
          this.initializeFlow()
          this.currentStep = 1
          this.goToStep(this.currentStep)
        }
        
      },
      parentCategory: function(newValue) {
        if(newValue && !this.imageComplete) {  //I have incoming parameters that arrive at different times.
 
          var newParentCatSlug = this.parentCategory.replace(/\s+/g, '-').toLowerCase();
          var obj = {
            'parentCategory': newValue,
            'parentCategorySlug': newParentCatSlug,
          }
        
          this.getCategoryImage(obj).then((image) => {   //If its a bad category, there will be no hero image
            
            this.categoryImageSrc = image.src
            this.categoryCreditText = image.credit
            this.categoryImageLink = image.link
            this.imageComplete = true
            //show the image
            document.getElementById('category-img').style.display = "block";
            //this.getUnitOfMeasures(newValue)
            //console.log("got image")

          })
        } 
      },
      
    },
    methods: { 
      ...mapActions('image', [
        'getCategoryImage'
      ]),
      ...mapActions('signin', [
        'googleLogin',
        'anonymousLogin',
        'autoSignIn'
      ]),
      ...mapActions('experience', [
        'setXpPhase'
      ]),
      ...mapActions('routine', [
        'createRoutineTemplate',
        'deleteDailyAgendaReport',
        'updateCurrentAgendaItems'
      ]),
      ...mapActions('settings', [
        'changeNotification',
        'saveSubscriptionToUser',
      ]),
      ...mapActions('search', [
        'setShowSearch'
      ]),
      ...mapActions('settings', [
        'changeNotificationDeliveryPeriodJournalInFS'
      ]),
      changeNotificationToggle (value) {
        //console.log('changeNotificationToggle() has been called')
        this.changeNotification(value)
      },
      resetSteps() {
        this.ROUTINE_TYPE_STEP_ACTIVE = false
        this.WHAT_TO_ROUTINE_STEP_ACTIVE = false
        this.DAYS_OF_WEEK_STEP_ACTIVE = false
        this.PLAN_ROUTINE_STEP_ACTIVE = false
        this.PLAN_WORK_SESSIONS_STEP_ACTIVE = false
        this.SELECT_PERIODIZATION_STEP_ACTIVE = false
        this.PLAN_EXERCISE_ROUTINE_STEP_ACTIVE = false
        this.PLAN_CESSATION_SCHEDULE_STEP_ACTIVE = false
        this.SETUP_NOTIFICATIONS_STEP_ACTIVE = false
        this.TIMES_PER_DAY_STEP_ACTIVE = false
        this.ROUTINE_TYPE_STEP_ACTIVE = false
        this.OUTRO_STEP_ACTIVE = false
        this.OUTRO_CESSATION_STEP_ACTIVE = false
        this.OUTRO_EVENT_FOLLOWUP_STEP_ACTIVE = false
        this.OUTRO_EXERCISE_STEP_ACTIVE = false
        this.ADD_EVENTS_STEP_ACTIVE = false
        this.ADD_QUESTION_GROUP_STEP_ACTIVE = false
        this.ADD_FOLLOW_ON_TASKS_STEP_ACTIVE = false
        this.USER_DEFINED_ATTRIBUTES_STEP_ACTIVE = false
        this.PREDEFINED_NEXT_STEPS_STEP_ACTIVE = false
        this.ADD_MOMENTS_TO_PERIODS_STEP_ACTIVE = false
        this.SELECT_COOKING_DAYS_STEP_ACTIVE = false
        this.SELECT_MENU_STEP_ACTIVE = false
        this.ADD_EVENT_STEP_ACTIVE = false
        this.ADD_PREP_TASKS_STEP_ACTIVE = false
        this.OUTRO_EVENT_PREP_STEP_ACTIVE = false
        this.ADD_LISTINGS_STEP_ACTIVE = false
        this.OUTRO_LIST_DRAW_STEP_ACTIVE = false
      },
      loadRoutine() {
        var item = this.selectedItem

        //*periodization
        if(item.routinePeriods) {
          this.ANSWERS[this.currentFlowAnswers].periodization = item.routinePeriods
        }
            
        //*periodWeeks
        //*periodStartSetCount
        //*periodFinishSetCount
        //*periodStartRepCount
        //*periodFinishRepCount
        if(item.routinePeriodAttributes && item.routinePeriods) {
          for(let period in item.routinePeriods) {
            if(item.routinePeriods[period]) {
              this.ANSWERS[this.currentFlowAnswers].periodWeeks[period] = item.routinePeriodAttributes[period].periodWeeks
              this.ANSWERS[this.currentFlowAnswers].periodStartSetCount[period] = item.routinePeriodAttributes[period].periodStartSetCount
              this.ANSWERS[this.currentFlowAnswers].periodFinishSetCount[period] = item.routinePeriodAttributes[period].periodFinishSetCount
              this.ANSWERS[this.currentFlowAnswers].periodStartRepCount[period] = item.routinePeriodAttributes[period].periodStartRepCount
              this.ANSWERS[this.currentFlowAnswers].periodFinishRepCount[period] = item.routinePeriodAttributes[period].periodFinishRepCount
            }
          }
        }

        //*periodMoments 
        if(item.routinePeriods) {
          for(let period in item.routinePeriods) {
            if(item.routinePeriods[period]) {
              item.routineMoments.forEach(moment => {
                this.ANSWERS[this.currentFlowAnswers].periodMoments[period].push(moment)
              })
              
            }
          }
        }


        //*routineMoments
        //*daysOfWeek 
        //*timeOfDay
        //*setCount
        if(item.routineMoments  && !item.routinePeriods) {
          var day = null
          item.routineMoments.forEach(moment => {
            if(moment.timeOfDay == 1) {day = 'Monday'}
            if(moment.timeOfDay == 2) {day = 'Tuesday'}
            if(moment.timeOfDay == 3) {day = 'Wednesday'}
            if(moment.timeOfDay == 4) {day = 'Thursday'}
            if(moment.timeOfDay == 5) {day = 'Friday'}
            if(moment.timeOfDay == 6) {day = 'Saturday'}
            if(moment.timeOfDay == 7) {day = 'Sunday'}

            if(day){
              this.ANSWERS[this.currentFlowAnswers].routineMoments[day].push(moment)
              this.ANSWERS[this.currentFlowAnswers].timeOfDay[day].push(moment.timeOfDay)
              this.ANSWERS[this.currentFlowAnswers].daysOfWeek[day] = true
              this.ANSWERS[this.currentFlowAnswers].setCount[day].push(array.length.toString())
            }
            
            var array = moment.momentSteps.filter(step => step.stepType == 'action')

          })
        }

        //*eventsArray
        if(item.routineEvents) {
          for(let event in item.routineEvents) {
            this.ANSWERS[this.currentFlowAnswers].eventsArray.push(event)
          }
        }


        //*tasksArray
        if(item.routineTasks) {
          for(let task in item.routineTasks) {
            this.ANSWERS[this.currentFlowAnswers].tasksArray.push(task)
          }
        }


        //*cessationScheduleStartDay
        //*cessationScheduleTimesPerDay
        if(item.routineAgendaItemOccurranceSchedule) {
          var newSchedule = item.routineAgendaItemOccurranceSchedule.reverse()
          var splitArray = newSchedule.split('-')
          var theday = splitArray[0]
          var timesPerDay = splitArray[1]

          this.ANSWERS[this.currentFlowAnswers].cessationScheduleTimesPerDay.push(timesPerDay)
          this.ANSWERS[this.currentFlowAnswers].cessationScheduleStartDay.push(theday)

        }





        //*timesPerDay
        if(item.routineTimesPerDay) {
          this.ANSWERS.ROUTINE_FLOW_ANSWERS.timesPerDay = item.routineTimesPerDay.toString()
        }

        //*intentMeanStatement
        if(item.intentMeanCategory) {
          this.category = item.intentMeanCategory
        }

        
        //whatToRoutineStatement
        //if(item.routineTitle) {
        //  this.ANSWERS.ROUTINE_FLOW_ANSWERS.whatToRoutineStatement = item.routineTitle
        //}

        //*routineTitle
        if(item.routineTitle) {
          this.ANSWERS.ROUTINE_FLOW_ANSWERS.routineTitle = item.routineTitle
        }
        
        
      },
      initializeFlow () {

        this.resetSteps()
        if(this.selectedItem) {
          this.loadRoutine()
        }

        this.actionAfterAction = 'following routine'

        this.currentFlowAnswers = 'ROUTINE_FLOW_ANSWERS'


        if(this.whatToRoutine) {
          this.ANSWERS[this.currentFlowAnswers].whatToRoutineStatement = this.whatToRoutine
        }


        if(this.routineType == "exercise") {
          this.currentFlowManifest= 'ROUTINE_EXERCISE_FLOW_MANIFEST'

        }else if(this.routineType == "work sessions") {
          this.currentFlowManifest = 'ROUTINE_WORK_SESSIONS_FLOW_MANIFEST'

        }else if(this.routineType == "exercise2") {
          this.currentFlowManifest = 'ROUTINE_EXERCISE2_FLOW_MANIFEST'

        }else if(this.routineType == "everyday habit") {
          this.currentFlowManifest = 'ROUTINE_EVERYDAY_HABIT_FLOW_MANIFEST'

        }else if(this.routineType == "cooking") {
          this.currentFlowManifest = 'ROUTINE_COOKING_FLOW_MANIFEST'

        }else if(this.routineType == "cessation") {
          this.currentFlowManifest = 'ROUTINE_CESSATION_FLOW_MANIFEST'

        }else if(this.routineType == "event follow-up") {
          this.currentFlowManifest = 'ROUTINE_EVENT_FOLLOWUP_FLOW_MANIFEST'

        }else if(this.routineType == "generic") {
          this.currentFlowManifest = 'ROUTINE_GENERIC_FLOW_MANIFEST'

        }else if(this.routineType == "event prep") {
          this.currentFlowManifest = 'ROUTINE_EVENT_PREP_FLOW_MANIFEST'

        }else if(this.routineType == "list draw") {
          this.currentFlowManifest = 'ROUTINE_LIST_DRAW_FLOW_MANIFEST'

        }else {
          this.currentFlowManifest = 'ROUTINE_FLOW_MANIFEST'

        }
       
        
        
      },
      setTab (tab) {
        this.currentTab = tab
      },
      actionSelected (payload) {
        this.ANSWERS[this.currentFlowAnswers].periodMoments[this.currentTab][payload.index].actionAttributes.actionId = (payload.action.id) ? payload.action.id : null
        this.ANSWERS[this.currentFlowAnswers].periodMoments[this.currentTab][payload.index].actionAttributes.agendaItemTitle = (payload.action.at) ? payload.action.at : null
        this.ANSWERS[this.currentFlowAnswers].periodMoments[this.currentTab][payload.index].actionAttributes.actionLevel = (payload.action.l) ? payload.action.l : null
        this.ANSWERS[this.currentFlowAnswers].periodMoments[this.currentTab][payload.index].actionAttributes.actionLoadType = (payload.action.alt) ? payload.action.alt : null
        this.ANSWERS[this.currentFlowAnswers].periodMoments[this.currentTab][payload.index].actionAttributes.actionIconBlack = (payload.action.i) ? payload.action.i : null
        this.ANSWERS[this.currentFlowAnswers].periodMoments[this.currentTab][payload.index].actionAttributes.actionIconGrey = (payload.action.ig) ? payload.action.ig : null
        this.ANSWERS[this.currentFlowAnswers].periodMoments[this.currentTab][payload.index].actionAttributes.actionUom = (payload.action.au) ? payload.action.au : null
        this.ANSWERS[this.currentFlowAnswers].periodMoments[this.currentTab][payload.index].actionAttributes.intensityType = (payload.action.it) ? payload.action.it : null
        this.ANSWERS[this.currentFlowAnswers].periodMoments[this.currentTab][payload.index].actionAttributes.volumeType = (payload.action.vt) ? payload.action.vt : null
        this.ANSWERS[this.currentFlowAnswers].periodMoments[this.currentTab][payload.index].actionAttributes.isLoadable = (payload.action.il) ? payload.action.il : false
        this.ANSWERS[this.currentFlowAnswers].periodMoments[this.currentTab][payload.index].actionAttributes.isAerobic = (payload.action.ia) ? payload.action.ia : false
        this.ANSWERS[this.currentFlowAnswers].periodMoments[this.currentTab][payload.index].actionAttributes.isAnaerobic = (payload.action.ian) ? payload.action.ian : false
        this.ANSWERS[this.currentFlowAnswers].periodMoments[this.currentTab][payload.index].actionAttributes.isIsometric = (payload.action.ii) ? payload.action.ii : false

        this.ANSWERS[this.currentFlowAnswers].periodMoments[this.currentTab][payload.index].actionAttributes.isIsometric = (payload.action.ii) ? payload.action.ii : false
        if(payload.action.t) {
          payload.action.t.forEach(tag => {
            if(!this.ANSWERS[this.currentFlowAnswers].routineTags.includes(tag)) {
              this.ANSWERS[this.currentFlowAnswers].routineTags.push(tag)
            }
          })
        }
        
       

      },
      actionSelected2 (payload) {
        this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.actionId = (payload.action.id) ? payload.action.id : null
        this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.agendaItemTitle = (payload.action.at) ? payload.action.at : null
        this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.actionIconBlack = (payload.action.i) ? payload.action.i : null
        this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.actionIconGrey = (payload.action.ig) ? payload.action.ig : null
        this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.actionLoadType = (payload.action.alt) ? payload.action.alt : null
        this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.actionLevel = (payload.action.l) ? payload.action.l : null
        this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.actionUom = (payload.action.au) ? payload.action.au : false
        this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.intensityType = (payload.action.it) ? payload.action.it : null
        this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.volumeType = (payload.action.vt) ? payload.action.vt : null
        this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.isLoadable = (payload.action.il) ? payload.action.il : false
        this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.isAerobic = (payload.action.ia) ? payload.action.ia : false
        this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.isIsometric = (payload.action.ii) ? payload.action.ii : false
        this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.isAnaerobic = (payload.action.ian) ? payload.action.ian : false
        this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.intentMeanCategory = (payload.action.im) ? payload.action.im : null
        if(payload.action.t && payload.action.t.length > 0) {
          payload.action.t.forEach(tag => {
            if(!this.ANSWERS[this.currentFlowAnswers].routineTags.includes(tag)) {
              this.ANSWERS[this.currentFlowAnswers].routineTags.push(tag)
            }
          })
        }
        

      },
      /* eslint-disable no-unused-vars */
      actionSelected3 (payload) {
        var mom = this.createRoutineMoment()
        mom.momentImage = (payload.action.i) ? payload.action.i : null
        this.ANSWERS[this.currentFlowAnswers].v2_periodMoments[payload.periodKey].push(mom)

        var periodMoments = this.ANSWERS[this.currentFlowAnswers].v2_periodMoments[payload.periodKey]
        var len = periodMoments.length
        this.$set(this.ANSWERS[this.currentFlowAnswers].v2_periodMoments[payload.periodKey][len-1].actionAttributes, 'actionId', (payload.action.id) ? payload.action.id : null)
        this.$set(this.ANSWERS[this.currentFlowAnswers].v2_periodMoments[payload.periodKey][len-1].actionAttributes, 'agendaItemTitle', (payload.action.at) ? payload.action.at : null)
        this.$set(this.ANSWERS[this.currentFlowAnswers].v2_periodMoments[payload.periodKey][len-1], 'momentTitle', (payload.action.at) ? payload.action.at : null)
        this.$set(this.ANSWERS[this.currentFlowAnswers].v2_periodMoments[payload.periodKey][len-1].actionAttributes, 'actionIconBlack' , (payload.action.i) ? payload.action.i : null)
        this.$set(this.ANSWERS[this.currentFlowAnswers].v2_periodMoments[payload.periodKey][len-1].actionAttributes, 'actionIconGrey', (payload.action.ig) ? payload.action.ig : null)
        this.$set(this.ANSWERS[this.currentFlowAnswers].v2_periodMoments[payload.periodKey][len-1].actionAttributes, 'actionLoadType', (payload.action.alt) ? payload.action.alt : null)
        this.$set(this.ANSWERS[this.currentFlowAnswers].v2_periodMoments[payload.periodKey][len-1].actionAttributes, 'actionLevel', (payload.action.l) ? payload.action.l : null)
        this.$set(this.ANSWERS[this.currentFlowAnswers].v2_periodMoments[payload.periodKey][len-1].actionAttributes, 'actionUom', (payload.action.au) ? payload.action.au : false)
        this.$set(this.ANSWERS[this.currentFlowAnswers].v2_periodMoments[payload.periodKey][len-1].actionAttributes, 'intensityType', (payload.action.it) ? payload.action.it : null)
        this.$set(this.ANSWERS[this.currentFlowAnswers].v2_periodMoments[payload.periodKey][len-1].actionAttributes, 'volumeType', (payload.action.vt) ? payload.action.vt : null)
        this.$set(this.ANSWERS[this.currentFlowAnswers].v2_periodMoments[payload.periodKey][len-1].actionAttributes, 'isLoadable', (payload.action.il) ? payload.action.il : false)
        this.$set(this.ANSWERS[this.currentFlowAnswers].v2_periodMoments[payload.periodKey][len-1].actionAttributes, 'isAerobic', (payload.action.ia) ? payload.action.ia : false)
        this.$set(this.ANSWERS[this.currentFlowAnswers].v2_periodMoments[payload.periodKey][len-1].actionAttributes, 'isIsometric', (payload.action.ii) ? payload.action.ii : false)
        this.$set(this.ANSWERS[this.currentFlowAnswers].v2_periodMoments[payload.periodKey][len-1].actionAttributes, 'isAnaerobic', (payload.action.ian) ? payload.action.ian : false)
        this.$set(this.ANSWERS[this.currentFlowAnswers].v2_periodMoments[payload.periodKey][len-1].actionAttributes, 'intentMeanCategory', (payload.action.im) ? payload.action.im : null)
        if(payload.action.t && payload.action.t.length > 0) {
          payload.action.t.forEach(tag => {
            if(!this.ANSWERS[this.currentFlowAnswers].routineTags.includes(tag)) {
              this.ANSWERS[this.currentFlowAnswers].routineTags.push(tag)
            }
          })
        }

        this.$nextTick(() => {
          this.$set(this.showSearchAction, payload.periodKey, false)
        })
        
      },
      sequenceSelected (payload) {
        //payload.seq, payload.index
        //seq.st - sequenceTitle
        //seq.t = sequenceTags
        //seq.sid - sequenceId
        //seq.ss - sequenceSteps
        //seq.i - sequenceIconImageBlack
        //seq.typ - sequenceType
        //seq.im - intentMeanCategory
        var mom = this.createRoutineMoment()
        mom.sequenceSteps = (payload.seq.ss) ? payload.seq.ss : null
        mom.momentTitle = (payload.seq.st) ? payload.seq.st : null
        mom.momentImage = (payload.seq.i) ? payload.seq.i : null
        this.ANSWERS[this.currentFlowAnswers].v2_periodMoments[payload.periodKey].push(mom)
        var periodMoments = this.ANSWERS[this.currentFlowAnswers].v2_periodMoments[payload.periodKey]
        var len = periodMoments.length

        if(payload.seq.t) {
          this.ANSWERS[this.currentFlowAnswers].routineTags.push(payload.seq.t)
        }

        this.$nextTick(() => {
          this.$set(this.showSearchSequence, payload.periodKey, false)
        })
        
      },
      backAStep () {
        window.scrollTo(0,0)
        //var fromStep = this.currentStep
        var fromStepId = this.currentStepId
        var toStep = this.currentStep-1
        var toStepId = this.FLOWS[this.currentFlowManifest][(toStep-1)]

        this[fromStepId+'_STEP_ACTIVE'] = false
        this[toStepId+'_STEP_ACTIVE'] = true
        this.currentStep = toStep
        this.currentStepId = toStepId
      },
      onDotClick (dotIndex) {
        this.goToStep(dotIndex+1)
      },
      resetSchedule() {
        var length = this.ANSWERS[this.currentFlowAnswers].cessationScheduleStartDay.length
        this.ANSWERS[this.currentFlowAnswers].cessationScheduleStartDay = ['1','3', '6', '9']
        this.ANSWERS[this.currentFlowAnswers].cessationScheduleTimesPerDay = ['9', '8', '7', '6']
        
        for(var i = 0; i < length; i++) {
          document.getElementById('error'+i).style.display = "none"
          document.getElementById('error2'+i).style.display = "none"
        }
        this.thereIsAStartDayError = {}
        this.thereIsATimesPerDayError = {}
        
      },
      validateStartDaySchedule(index) {
        //var previousStartDay = this.ANSWERS[this.currentFlowAnswers].cessationScheduleStartDay[index-1]
        //var startDay = this.ANSWERS[this.currentFlowAnswers].cessationScheduleStartDay[index]
        if(parseInt(this.ANSWERS[this.currentFlowAnswers].cessationScheduleStartDay[index]) <= parseInt(this.ANSWERS[this.currentFlowAnswers].cessationScheduleStartDay[index-1])) {
          //show error
          document.getElementById('error'+index).style.display = "inline-block"
          this.thereIsAStartDayError[index] = true
        }else{
          document.getElementById('error'+index).style.display = "none"
          this.thereIsAStartDayError[index] = false
        }
        var higherVal = parseInt(this.ANSWERS[this.currentFlowAnswers].cessationScheduleStartDay[index])
        this.ANSWERS[this.currentFlowAnswers].cessationScheduleStartDay.forEach((day,idx) => {
          if(idx >= index) {   //only check items after current one
            //if(parseInt(day) < parseInt(higherVal)) {
            if(parseInt(day) < parseInt(higherVal)) {
              var newVal = ++higherVal
            
              this.$set(this.ANSWERS[this.currentFlowAnswers].cessationScheduleStartDay,idx, newVal.toString())
            }
          }
        })       
      },
      validateTimesPerDaySchedule(index) {
        //if this times per day is greater or equal to the previous times per day, throw error
        if(parseInt(this.ANSWERS[this.currentFlowAnswers].cessationScheduleTimesPerDay[index]) >= parseInt(this.ANSWERS[this.currentFlowAnswers].cessationScheduleTimesPerDay[index-1])) {
          //show error
          document.getElementById('error2'+index).style.display = "inline-block"
          this.thereIsATimesPerDayError[index] = true
        }else{
          document.getElementById('error2'+index).style.display = "none"
          this.thereIsATimesPerDayError[index] = false
        }
        var lowerVal = parseInt(this.ANSWERS[this.currentFlowAnswers].cessationScheduleTimesPerDay[index])
        this.ANSWERS[this.currentFlowAnswers].cessationScheduleTimesPerDay.forEach((times,idx) => {
          if(idx > index) {   //only check items after current one
            if(parseInt(times) >= parseInt(lowerVal)) {
            //if(parseInt(times) > parseInt(this.ANSWERS[this.currentFlowAnswers].cessationScheduleTimesPerDay[index])) {
              var newVal = --lowerVal
              if(newVal > 0) {
                this.$set(this.ANSWERS[this.currentFlowAnswers].cessationScheduleTimesPerDay,idx, newVal.toString())
              }else {
                newVal = 1
                this.$set(this.ANSWERS[this.currentFlowAnswers].cessationScheduleTimesPerDay,idx, newVal.toString())
                
              }
              
            }
          }
        })



      },
      addPhaseToRoutine () {
        var len = this.ANSWERS[this.currentFlowAnswers].cessationScheduleStartDay.length
        var previousPhaseStartDay = parseInt(this.ANSWERS[this.currentFlowAnswers].cessationScheduleStartDay[len-1])
        var nextStartDay = previousPhaseStartDay + 1
        this.ANSWERS[this.currentFlowAnswers].cessationScheduleStartDay.push(nextStartDay.toString())
        var previousTimesPerDay = parseInt(this.ANSWERS[this.currentFlowAnswers].cessationScheduleTimesPerDay[len-1])
        var nextTimesPerDay = previousTimesPerDay - 1
        this.ANSWERS[this.currentFlowAnswers].cessationScheduleTimesPerDay.push(nextTimesPerDay.toString())

      },
      addEventToRoutine () {
        
        this.ANSWERS[this.currentFlowAnswers].eventsArray.push({
                'eventName': null,
                'eventDayStamp': null,
              },)

      },
      addAttributeToRoutine () {
        var name = this.ANSWERS[this.currentFlowAnswers].userDefinedAttributeNameHolder
        if(name) {
          this.$set(this.ANSWERS[this.currentFlowAnswers].routineUserDefinedAttributes, name, {
            'attributeStimulusQuestion': null,
            'attributeVariableName': name,
            'attributeVariableValue': null,
            'attributeVariableUom': null,
          })
          this.ANSWERS[this.currentFlowAnswers].userDefinedAttributeNameHolder = null
        }
        
      },
      addV2PeriodToRoutine () {
        var len = Object.keys(this.ANSWERS[this.currentFlowAnswers].v2_routinePeriodsObject).length
        var newPeriodName = `Period`+len
        var periodObj = {
          periodDaysOfWeek: this.ANSWERS[this.currentFlowAnswers].v2_routinePeriodsObject[`Period`+(len-1)].periodDaysOfWeek,
          periodDesc: null,
          periodName: newPeriodName,
          periodMoments: [],
          periodNumSets: "0",
          periodNumWeeks: "0",
          periodSequenceNumber: len + 1,
          
        }
        this.$set(this.ANSWERS[this.currentFlowAnswers].v2_routinePeriodsObject, newPeriodName, periodObj)
        this.$set(this.ANSWERS[this.currentFlowAnswers].v2_periodMoments, newPeriodName, []  )
      },
      addTaskToRoutine () {
        
        // Simple MVP version of task, about to be expanded
        this.ANSWERS[this.currentFlowAnswers].tasksArray.push({
                'taskName': null,
                'startDayOffsets': null,
                'routineQuestionGroupId': null,
                'routineQuestionGroupName': null,
              },)

      },

      handleQuestionGroupChange (payload) {
        //console.log("payload for handleQuestionGroupChange", payload)
        this.ANSWERS[this.currentFlowAnswers].tasksArray[payload.taskIndex].routineQuestionGroupId = payload.event
      },

      addMomentToRoutine (day) {
        var dow
        if(day == 'Monday'){dow = 1}      //Based on ISO standard
        if(day == 'Tuesday'){dow = 2}
        if(day == 'Wednesday'){dow = 3}
        if(day == 'Thursday'){dow = 4}
        if(day == 'Friday'){dow = 5}
        if(day == 'Saturday'){dow = 6}
        if(day == 'Sunday'){dow = 7}

        var routineMoment = {       //referred to as routineMoments
          dayOfWeek: dow,
          momentImage: null,
          momentSteps: [],
          momentTitle: this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.agendaItemTitle,
          daysOfWeek: null,
          actionAttributes: {
                      actionDuration: 0,
                      actionIconBlack: this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.actionIconBlack,
                      actionIconGrey: this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.actionIconGrey,
                      actionId: this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.actionId,
                      actionLevel: this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.actionLevel,
                      actionLoadType: this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.actionLoadType,
                      actionUom: this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.actionUom,
                      agendaItemTitle: this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.agendaItemTitle,
                      intensityType: this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.intensityType,
                      isAerobic: this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.isAerobic,
                      isAnaerobic: this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.isAnaerobic,
                      isIsometric: this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.isIsometric,
                      isLoadable: this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.isLoadable,
                      repCountOverride: 0,
                      restDuration: 0,
                      setCountOverride: 1,
                      volumeType: this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.volumeType,
                    },
        }
        this.ANSWERS[this.currentFlowAnswers].routineMoments[day].push(routineMoment)    
        this.ANSWERS[this.currentFlowAnswers].timeOfDay[day].push('afternoon')
        this.ANSWERS[this.currentFlowAnswers].setCount[day].push('2')
        this.ANSWERS[this.currentFlowAnswers].actionStepUom[day].push(this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.actionUom)
      },
      addMomentToPeriod(period) {

        var daysOfWeekArray = []
        for (let day in this.ANSWERS[this.currentFlowAnswers].daysOfWeek) {
          if(this.ANSWERS[this.currentFlowAnswers].daysOfWeek[day]) {
            daysOfWeekArray.push(day)
          }
        }

        var routineMomentTemplate = this.createRoutineMoment()
        routineMomentTemplate.daysOfWeek = daysOfWeekArray

        /*
        var routineMomentTemplate = {       //referred to as routineMoments
          'dayOfWeek': null,
          'momentImage': null,
          'momentSteps': [],
          'momentTitle': null,
          'daysOfWeek': daysOfWeekArray,
          'actionAttributes': {
            'actionDuration': 0,
            'actionLevel': null,
            'actionLoadType': null,
            'actionIconBlack': null,
            'actionIconGrey': null,
            'actionId': null,
            'actionUom': 'repetitions',
            'agendaItemTitle': null,
            'intensityType': null,
            'isAerobic': false,
            'isAnaerobic': false,
            'isIsometric': false,
            'isLoadable': false,
            'repCountOverride': 0,
            'restDuration': 0,
            'setCountOverride': 0,
            'volumeType': null,
          },
          'timeOfDay': 'morning',
        }
        */
        
        this.ANSWERS[this.currentFlowAnswers].periodMoments[period].push(routineMomentTemplate)

  

      },
      addQuestionToQuestionGroup (payload) {

        var obj = {
              'question': null,
              'answerUom': null,
              'answerInputLabel': null,
              'eventVariableName': null,
              'questionDependency': null,
              'questionType': payload.questionType,
            }
        if(payload.questionType === 'eventAttributeQuestion') {

          if(!this.ANSWERS[this.currentFlowAnswers].routineQuestionGroups[`group`+payload.groupIndex].eventAttributeQuestions) {
            Vue.set(this.ANSWERS[this.currentFlowAnswers].routineQuestionGroups[`group`+payload.groupIndex], 'eventAttributeQuestions', [])
          }
          this.ANSWERS[this.currentFlowAnswers].routineQuestionGroups[`group`+payload.groupIndex].eventAttributeQuestions.push(obj)

        }else if(payload.questionType === 'eventFollowUpQuestion') {

          if(!this.ANSWERS[this.currentFlowAnswers].routineQuestionGroups[`group`+payload.groupIndex].eventFollowUpQuestions) {
            Vue.set(this.ANSWERS[this.currentFlowAnswers].routineQuestionGroups[`group`+payload.groupIndex], 'eventFollowUpQuestions', [])
          }
          this.ANSWERS[this.currentFlowAnswers].routineQuestionGroups[`group`+payload.groupIndex].eventFollowUpQuestions.push(obj)

        }
        

      },
      addQuestionGroupToRoutine () {
        var questionGroupObj = {
                'questionGroupName': null,
                'questionGroupId': `group`+Object.keys(this.ANSWERS[this.currentFlowAnswers].routineQuestionGroups).length,
                'eventAttributeQuestions': [],
                'eventFollowUpQuestions': []
              }

        Vue.set(this.ANSWERS[this.currentFlowAnswers].routineQuestionGroups, `group`+Object.keys(this.ANSWERS[this.currentFlowAnswers].routineQuestionGroups).length, questionGroupObj)

      },
      addQuestionToRoutine (payload) {

        var questionObj = {
                'question': null,
                'answerUom': null,
                'eventVariableName': null,
                'askedAndAnswered': false,
                'questionDependency': null,
                'taskIndex': payload.taskIndex,
                'questionType': payload.questionType,
              }

        
        if(!this.ANSWERS[this.currentFlowAnswers].routineQuestions[payload.taskIndex]) {
          Vue.set(this.ANSWERS[this.currentFlowAnswers].routineQuestions, payload.taskIndex, [])
        }
        this.ANSWERS[this.currentFlowAnswers].routineQuestions[payload.taskIndex].push(questionObj)
        

        

        //Vue.set(this.ANSWERS[this.currentFlowAnswers].taskQuestionDependencies, index, [])

      },

      
      addActionMomentToRoutine(periodKey) {
        this.$set(this.showSearchAction, periodKey, true)
        this.$set(this.showSearchSequence, periodKey, false)
      },
      
      addSequenceMomentToRoutine(periodKey) {
        this.$set(this.showSearchAction, periodKey, false)
        this.$set(this.showSearchSequence, periodKey, true)

      },

      createRoutineMoment () {
        var mom = {
          'dayOfWeek': null,
          'momentImage': null,
          'momentSteps': [],
          'momentTitle': null,
          'daysOfWeek': [],
          'actionAttributes': {
            'actionDuration': 0,
            'actionLevel': null,
            'actionLoadType': null,
            'actionIconBlack': null,
            'actionIconGrey': null,
            'actionId': null,
            'actionUom': null,
            'agendaItemTitle': null,
            'intensityType': null,
            'isAerobic': false,
            'isAnaerobic': false,
            'isIsometric': false,
            'isLoadable': false,
            'repCountOverride': 0,
            'restDuration': 0,
            'setCountOverride': 0,
            'volumeType': null,
          },
          'timeOfDay': 'morning'
        }

        return mom
      },
      goToStep (goToStep) {
        window.scrollTo(0,0)
        var fromStepId;
        var fromStep
        if((goToStep == 1) && (this.currentStep < 2)) {
          fromStep = 0
          fromStepId = null
        } else {
          fromStep = this.currentStep
          fromStepId = this.FLOWS[this.currentFlowManifest][(fromStep-1)]
        }
        
        var goToStepId = this.FLOWS[this.currentFlowManifest][(goToStep-1)]
        this[goToStepId+'_STEP_ACTIVE'] = true
        //console.log('goToStepId',goToStepId)
        if(fromStepId) {
          this[fromStepId+'_STEP_ACTIVE'] = false
        }
        //console.log('fromStepId',fromStepId)

      
        this.currentStep = goToStep
        this.currentStepId = goToStepId
      },
      overlayOff () {

        this.ANSWERS.ROUTINE_FLOW_ANSWERS = {

            v2_routinePeriodsObject: {
              'Period0':  {
                periodDaysOfWeek: [],
                periodDesc: null,
                periodName: 'Period0',
                periodNumSets: "0",
                periodNumWeeks: "0",
                periodSequenceNumber: 1,
                periodType: null,
              },
                
            },
            v2_periodMoments: {
              'Period0': []
            },

            /* Cooking Flow */
            cookingDays: {
              'Sunday': false,
              'Monday': false,
              'Tuesday': false,
              'Wednesday': false,
              'Thursday': false,
              'Friday': false,
              'Saturday': false,
            },
            menuId: 'Menu 1',
            routineCookingMoments: [],


            daysOfWeek: {
              'Monday': false,
              'Tuesday': false,
              'Wednesday': false,
              'Thursday': false,
              'Friday': false,
              'Saturday': false,
              'Sunday': false,
            },
            routineMoments: {
              'Sunday': [],
              'Monday': [],
              'Tuesday': [],
              'Wednesday': [],
              'Thursday': [],
              'Friday': [],
              'Saturday': [],
            },
            timeOfDay: {
              'Sunday': [],
              'Monday': [],
              'Tuesday': [],
              'Wednesday': [],
              'Thursday': [],
              'Friday': [],
              'Saturday': [],
            },
            /*
            periodTimeOfDay: {
              'Pre-Training': [],
              'Endurance': [],
              'Hypertrophy': [],
              'Strength': [],
            }, */
            setCount: {
              'Sunday': [],
              'Monday': [],
              'Tuesday': [],
              'Wednesday': [],
              'Thursday': [],
              'Friday': [],
              'Saturday': [],
            },
            actionStepUom: {
              'Sunday': [],
              'Monday': [],
              'Tuesday': [],
              'Wednesday': [],
              'Thursday': [],
              'Friday': [],
              'Saturday': [],
            },
            periodization: {
              'Pre-Training': false,
              'Endurance': true,
              'Hypertrophy': true,
              'Strength': true,
            },
            periodWeeks: {
              'Pre-Training': "5",
              'Endurance': "3",
              'Hypertrophy': "3",
              'Strength': "3",
            },
            periodStartSetCount: {
              'Pre-Training': "1",
              'Endurance': "3",
              'Hypertrophy': "3",
              'Strength': "3",
            },
            periodFinishSetCount: {
              'Pre-Training': "2",
              'Endurance': "4",
              'Hypertrophy': "3",
              'Strength': "3",
            },
            periodStartRepCount: {
              'Pre-Training': "1",
              'Endurance': "12",
              'Hypertrophy': "12",
              'Strength': "5",
            },
            periodFinishRepCount: {
              'Pre-Training': "12",
              'Endurance': "18",
              'Hypertrophy': "8",
              'Strength': "5",
            },
            periodMoments: {
              'Pre-Training': [],
              'Endurance': [],
              'Hypertrophy': [],
              'Strength': [],
            },
            eventsArray: [
              {
                'eventName': null,
                'eventDayStamp': null,
              },
            ],
            tasksArray: [
              {
                'taskName': null,
                'startDayOffsets': null,
                'routineQuestionGroupName': null,
                'routineQuestionGroupId': null,
              },
            ],
            routineQuestions: {},
            routineQuestionGroups: {
              'group0': {
                'questionGroupId': null,
                'questionGroupName': null,
                'eventAttributeQuestions': [],
                'eventFollowUpQuestions': []
              },
            },
            routineUserDefinedAttributes: {},
            userDefinedAttributeNameHolder: null,
            preDefinedNextSteps: [],
            cessationScheduleStartDay: ['0','3', '6', '9'],     //Used only for the cessation routine
            cessationScheduleTimesPerDay: ['9', '8', '7', '6'],
            timesPerDay: null,
            intentMeanStatement: null,  //intentMeanStatement provied by system  (this.category)
            endStatement: null,
            valueStatement: null,
            actionMeanStatement: "following this routine",
            actionAfterActionStatement: null,   //becomes a second next step in minder, also added to category or parentCategory node
            notificationsBool: false,
            wakeUpTime: null,
            whatToRoutineAction: {
              actionDuration: 0,
              actionIconBlack: null,
              actionIconGrey: null,
              actionId: null,
              actionUom: null,
              agendaItemTitle: null,
              actionLevel: null,
              actionLoadType: null,
              intensityType: null,
              intentMeanCategory: null,
              isAerobic: false,
              isAnaerobic: false,
              isIsometric: false,
              isLoadable: false,
              restDuration: 0,
              repCountOverride: 0,
              setCountOverride: 0,
              volumeType: null,
            },
            whatToRoutineStatement: null,
            routineTitle: null,
            userSelectedRoutineType: null,
            routineTags: []
          }
          

        this.$emit('overlay-off')
        
      },
      async handleAnswer(stepId) {

        if(stepId == 'INTRO') {
          this.goToStep(this.currentStep+1)
          return


        }else if(stepId == 'ROUTINE_TYPE') {
          if (this.$refs['ROUTINE_TYPE_input'].validate()) {
            this.goToStep(this.currentStep+1)
          }else {
            /* eslint-disable no-console */
            console.log('validation error')
          }
          return
        
        
        }else if(stepId == 'WHAT_TO_ROUTINE') {
          //if (this.$refs['WHAT_TO_ROUTINE_STATEMENT_input'].validate()) {
            this.goToStep(this.currentStep+1)
          //}else {
            //console.log('validation error')
          //}
          return

        }else if(stepId == 'TIMES_PER_DAY') {
          if (this.$refs['TIMES_PER_DAY_input'].validate()) {


            this.goToStep(this.currentStep+1)
          }
          return
        
        }else if(stepId == 'ADD_EVENTS') {
          var error = false
          this.ANSWERS[this.currentFlowAnswers].eventsArray.forEach((event,index) => {
            if(
              !this.ANSWERS[this.currentFlowAnswers].eventsArray[index].eventName ||
              !this.ANSWERS[this.currentFlowAnswers].eventsArray[index].eventDayStamp
            ) {
              //console.log('validation error')
              error = true
            }
          }) 
          if(error) {
            return
          }else {
            this.goToStep(this.currentStep+1)
          }
          


        }else if(stepId == 'ADD_QUESTION_GROUP') {
          
          
          //if(error) {
          //  return
          //}else {
            this.goToStep(this.currentStep+1)
          //}

        }else if(stepId == 'ADD_PERIODS') {
          
          
          //if(error) {
          //  return
          //}else {
            this.goToStep(this.currentStep+1)
          //}
          

        }else if(stepId == 'USER_DEFINED_ATTRIBUTES') {
          
          
          //if(error) {
          //  return
          //}else {
            this.goToStep(this.currentStep+1)
          //}
          
        }else if(stepId == 'PREDEFINED_NEXT_STEPS') {
          
          
          //if(error) {
          //  return
          //}else {
            this.goToStep(this.currentStep+1)
          //}
        }else if(stepId == 'ADD_MOMENTS_TO_PERIODS') {
          
          
          //if(error) {
          //  return
          //}else {
            this.goToStep(this.currentStep+1)
          //}

        }else if(stepId == 'ADD_FOLLOW_ON_TASKS') {
          var error2 = false
          this.ANSWERS[this.currentFlowAnswers].tasksArray.forEach((task,index) => {
            if(
              !this.ANSWERS[this.currentFlowAnswers].tasksArray[index].taskName ||
              !this.ANSWERS[this.currentFlowAnswers].tasksArray[index].startDayOffsets
            ) {
              //console.log('validation error')
              error2 = true
            }
          }) 
          if(error2) {
            return
          }else {
            this.goToStep(this.currentStep+1)
          }


        }else if(stepId == 'PLAN_CESSATION_SCHEDULE') {

          if((this.thereIsAStartDayError == {}) && (this.thereIsATimesPerDayError == {})) {
            this.goToStep(this.currentStep+1)
          }else{

            var keys1 = Object.keys(this.thereIsAStartDayError)
            var keys2 = Object.keys(this.thereIsATimesPerDayError)
            var error1Exists = false
            var error2Exists = false
            keys1.forEach(key => {
              if(this.thereIsAStartDayError[key]) {
                error1Exists = true
              }
            })
            keys2.forEach(key => {
              if(this.thereIsATimesPerDayError[key]) {
                error2Exists = true
              }
            })
            if(!error1Exists && !error2Exists) {
              this.goToStep(this.currentStep+1)
            }
          }


          return


        }else if(stepId == 'SELECT_PERIODIZATION') {
           if (
              this.ANSWERS[this.currentFlowAnswers].periodization['Pre-Training'] ||
              this.ANSWERS[this.currentFlowAnswers].periodization['Endurance'] ||
              this.ANSWERS[this.currentFlowAnswers].periodization['Hypertrophy'] ||
              this.ANSWERS[this.currentFlowAnswers].periodization['Strength']
            ) {


              for (let period in this.ANSWERS[this.currentFlowAnswers].periodization) {
                if(this.ANSWERS[this.currentFlowAnswers].periodization[period]) {

                  var routineMomentTemplate = this.createRoutineMoment()

                  if(this.ANSWERS[this.currentFlowAnswers].periodMoments[period].length == 0) {    //This logic prevents duplicate initial moments from being added to days whenever a user clicks 'Back' to previous question
                    this.ANSWERS[this.currentFlowAnswers].periodMoments[period].push(routineMomentTemplate)
                  }

                } else {
                  if(this.ANSWERS[this.currentFlowAnswers].periodMoments[period].length > 0) {    //This logic prevents duplicate initial moments from being added to days whenever a user clicks 'Back' to previous question
                    this.ANSWERS[this.currentFlowAnswers].periodMoments[period].length = 0
                  }
                }
              }

              this.goToStep(this.currentStep+1)

            }
            return

        
        }else if(stepId == 'DAYS_OF_WEEK') {
          if (
              this.ANSWERS[this.currentFlowAnswers].daysOfWeek['Monday'] ||
              this.ANSWERS[this.currentFlowAnswers].daysOfWeek['Tuesday'] ||
              this.ANSWERS[this.currentFlowAnswers].daysOfWeek['Wednesday'] ||
              this.ANSWERS[this.currentFlowAnswers].daysOfWeek['Thursday'] ||
              this.ANSWERS[this.currentFlowAnswers].daysOfWeek['Friday'] ||
              this.ANSWERS[this.currentFlowAnswers].daysOfWeek['Saturday'] ||
              this.ANSWERS[this.currentFlowAnswers].daysOfWeek['Sunday']
            ) {
              
              //Need to initialize the routine object
              //For each selected day of week, create a routineMoment
              if((this.routineType == "exercise") || (this.routineType == 'work sessions')) {
                for (let day in this.ANSWERS[this.currentFlowAnswers].daysOfWeek) {
                  if(this.ANSWERS[this.currentFlowAnswers].daysOfWeek[day]) {
                    var dow
                    if(day == 'Monday'){dow = 1}    //numbering based on ISO standard
                    if(day == 'Tuesday'){dow = 2}
                    if(day == 'Wednesday'){dow = 3}
                    if(day == 'Thursday'){dow = 4}
                    if(day == 'Friday'){dow = 5}
                    if(day == 'Saturday'){dow = 6}
                    if(day == 'Sunday'){dow = 7}

                    var aRoutineMoment = {       //referred to as routineMoments
                      'dayOfWeek': dow,
                      'momentImage': null,
                      'momentSteps': [],
                      'momentTitle': this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.agendaItemTitle,
                      'actionAttributes': this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction
                    }

                    if(this.ANSWERS[this.currentFlowAnswers].routineMoments[day].length == 0) {    //This logic prevents duplicate initial moments from being added to days whenever a user clicks 'Back' to previous question
                      this.ANSWERS[this.currentFlowAnswers].routineMoments[day].push(aRoutineMoment)
                    
                      Vue.set(this.ANSWERS[this.currentFlowAnswers].timeOfDay[day],0, 'afternoon')
                      Vue.set(this.ANSWERS[this.currentFlowAnswers].setCount[day],0,'2')
                      Vue.set(this.ANSWERS[this.currentFlowAnswers].actionStepUom[day],0,this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.actionUom)
                      
                    }
                    

                  } else {
                    if(this.ANSWERS[this.currentFlowAnswers].routineMoments[day].length > 0) {    //This logic prevents duplicate initial moments from being added to days whenever a user clicks 'Back' to previous question
                      this.ANSWERS[this.currentFlowAnswers].routineMoments[day].length = 0
                      this.ANSWERS[this.currentFlowAnswers].timeOfDay[day].length = 0
                      this.ANSWERS[this.currentFlowAnswers].setCount[day].length = 0
                      this.ANSWERS[this.currentFlowAnswers].actionStepUom[day].length = 0
                    }
                  }
                }



              }else if(this.routineType == "exercise2") {
 
                for (const period in this.ANSWERS[this.currentFlowAnswers].periodization) {
                  if(this.ANSWERS[this.currentFlowAnswers].periodization[period]) {

                    var daysOfWeekArray = []
                    for (let day in this.ANSWERS[this.currentFlowAnswers].daysOfWeek) {
                      if(this.ANSWERS[this.currentFlowAnswers].daysOfWeek[day]) {
                        daysOfWeekArray.push(day)
                      }
                    }

                    this.ANSWERS[this.currentFlowAnswers].periodMoments[period].forEach(mom => {
                      mom.daysOfWeek = daysOfWeekArray
                    })
                    
                  } 
                }

              }else if(this.routineType == "list draw") {


                //will need to adhere to the generic routine structure

                
              }
              

              this.goToStep(this.currentStep+1)


            }
            return

        }else if(stepId == 'PLAN_ROUTINE') {
          //if (
               //this.$refs['PLAN_ROUTINE_input'].validate()
            //) {
              this.goToStep(this.currentStep+1)
            //}else {
              //console.log('validation error')
            //}
            return
        }else if(stepId == 'ADD_EVENT') {
          //if (
               //this.$refs['PLAN_ROUTINE_input'].validate()
            //) {
              this.goToStep(this.currentStep+1)
            //}else {
              //console.log('validation error')
            //}
            return
        }else if(stepId == 'ADD_PREP_TASK') {
          //if (
               //this.$refs['PLAN_ROUTINE_input'].validate()
            //) {
              this.goToStep(this.currentStep+1)
            //}else {
              //console.log('validation error')
            //}
            return
        }else if(stepId == 'ADD_LISTINGS') {
          //if (
               //this.$refs['PLAN_ROUTINE_input'].validate()
            //) {
              this.goToStep(this.currentStep+1)
            //}else {
              //console.log('validation error')
            //}
            return
        }else if(stepId == 'PLAN_WORK_SESSIONS') {
          //if (
               //this.$refs['PLAN_ROUTINE_input'].validate()
            //) {
              this.goToStep(this.currentStep+1)
            //}else {
              //console.log('validation error')
            //}
            return
        }else if(stepId == 'PLAN_EXERCISE_ROUTINE') {
          //if (
               //this.$refs['PLAN_ROUTINE_input'].validate()
            //) {
              this.goToStep(this.currentStep+1)
            //}else {
              //console.log('validation error')
            //}
            return


        }else if(stepId == 'SETUP_NOTIFICATIONS') {
          //if (
               //this.$refs['PLAN_ROUTINE_input'].validate()
            //) {
              this.goToStep(this.currentStep+1)
            //}else {
              //console.log('validation error')
            //}
            return
        }else if(stepId == 'SELECT_COOKING_DAYS') {
          if (
              this.ANSWERS[this.currentFlowAnswers].cookingDays['Monday'] ||
              this.ANSWERS[this.currentFlowAnswers].cookingDays['Tuesday'] ||
              this.ANSWERS[this.currentFlowAnswers].cookingDays['Wednesday'] ||
              this.ANSWERS[this.currentFlowAnswers].cookingDays['Thursday'] ||
              this.ANSWERS[this.currentFlowAnswers].cookingDays['Friday'] ||
              this.ANSWERS[this.currentFlowAnswers].cookingDays['Saturday'] ||
              this.ANSWERS[this.currentFlowAnswers].cookingDays['Sunday']
            ) {
              
                  

                  var aRoutineMoment3 = {       //referred to as routineMoments
                    'dayOfWeek': null,
                    'momentImage': null,
                    'momentSteps': [],
                    'momentTitle': "Cook food",
                    'actionAttributes': null      //may need to revist
                  }
                  if(this.ANSWERS[this.currentFlowAnswers].routineCookingMoments.length == 0) {    //This logic prevents duplicate initial moments from being added to days whenever a user clicks 'Back' to previous question
                    this.ANSWERS[this.currentFlowAnswers].routineCookingMoments.push(aRoutineMoment3)
                    //Vue.set(this.ANSWERS[this.currentFlowAnswers].timeOfDay[day],0, 'afternoon')
                  }

                

              this.goToStep(this.currentStep+1)
            }
            return
        }else if(stepId == 'SELECT_MENU') {
              this.goToStep(this.currentStep+1)
            return
        }else if( (stepId == 'OUTRO') || 
                  (stepId == 'OUTRO_EXERCISE') ||
                  (stepId == 'OUTRO_LIST_DRAW') ||
                  (stepId == 'OUTRO_EVENT_PREP') ||
                  (stepId == 'OUTRO_CESSATION') ||
                  (stepId == 'OUTRO_EVENT_FOLLOWUP')
                ) {


          var routineTemplateMomentIds = []
          var theSchedule = null
          var routineAgendaItemOccurranceScheduleText = null
          var routineMoments = []
          var routineMenuId = null
          var routineCookingDays = []
          var routinePeriods = null
          var routinePeriodAttributes = {}
          var itemsPerWeek = null
          var newEventsArray = null
          var newTasksArray = null
          var routineQuestions = null
          var routineQuestionGroups = null
          var eventAttributes = {}
          var isProgram = false
          var periodHumanActions = {}
          var routineImageBlack = null
          var routineImageGrey = null
          var intentMeanCategory = null
          var recommendedDaysOfWeek = []
          
          

          if (this.routineType == "exercise2") {  
            

            //exercise2 is a program-class routine
            isProgram = true

            for (let period in this.ANSWERS[this.currentFlowAnswers].periodization) {
              if(this.ANSWERS[this.currentFlowAnswers].periodization[period]) {

                periodHumanActions[period] = []

                this.ANSWERS[this.currentFlowAnswers].periodMoments[period].forEach((moment) => {

                  moment.period = period

                  periodHumanActions[period].push(moment.actionAttributes.agendaItemTitle)
                  


                  //moment.momentSteps

                  //associate a routineTemplateMomentId to each selected day of week, and add to moment
                  var routineTemplateMomentIds = {}
                  moment.daysOfWeek.forEach((day) => {
                    routineTemplateMomentIds[day] = uuidv4()
                  })
                  moment.routineTemplateMomentIds = routineTemplateMomentIds

                  routineMoments.push(moment)

                })
                routinePeriodAttributes[period] = {}
                routinePeriodAttributes[period].periodWeeks = this.ANSWERS[this.currentFlowAnswers].periodWeeks[period]
                routinePeriodAttributes[period].periodStartSetCount = this.ANSWERS[this.currentFlowAnswers].periodStartSetCount[period]
                routinePeriodAttributes[period].periodFinishSetCount = this.ANSWERS[this.currentFlowAnswers].periodFinishSetCount[period]
                routinePeriodAttributes[period].periodStartRepCount = this.ANSWERS[this.currentFlowAnswers].periodStartRepCount[period]
                routinePeriodAttributes[period].periodFinishRepCount = this.ANSWERS[this.currentFlowAnswers].periodFinishRepCount[period]

              }
            }

            routinePeriods = this.ANSWERS[this.currentFlowAnswers].periodization

            //In support of daysOfWeek override feature
            for (let day in this.ANSWERS[this.currentFlowAnswers].daysOfWeek) {
              if(this.ANSWERS[this.currentFlowAnswers].daysOfWeek[day]) {
                recommendedDaysOfWeek.push(day)
              }
            }
            

            

          }else if (this.routineType == "exercise") {    
            
            //Use the exercises images for the routine images
            routineImageBlack = this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.actionIconBlack
            routineImageGrey = this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.actionIconGrey


            //Use the intent Mean Category from the human action
            intentMeanCategory = this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.intentMeanCategory
            


            for (let day in this.ANSWERS[this.currentFlowAnswers].daysOfWeek) {
              if(this.ANSWERS[this.currentFlowAnswers].daysOfWeek[day]) {
                var momentsArray = this.ANSWERS[this.currentFlowAnswers].routineMoments[day]
                var timeOfDayArray = this.ANSWERS[this.currentFlowAnswers].timeOfDay[day]
                var setCountArray = this.ANSWERS[this.currentFlowAnswers].setCount[day]

                momentsArray.forEach((moment,index) => {
                  moment.timeOfDay = timeOfDayArray[index]
                  for(var i = 0; i < setCountArray[index]; i++) {
                    var actionObj = {
                      'stepAttributes': null,
                      'stepDuration': null,
                      'stepId': null,
                      'stepImage': null,
                      'stepTitle': this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.agendaItemTitle,
                      'stepType': 'action',
                      'stepValue': null,
                      'stepValueUom': this.ANSWERS[this.currentFlowAnswers].actionStepUom[day][index],
                      
                    }
                    var restObj = {
                      'stepAttributes': null,
                      'stepDuration': 180,
                      'stepId': null,
                      'stepImage': null,
                      'stepTitle': "Rest",
                      'stepType': 'rest',
                      'stepValue': 180,
                      'stepValueUom': 'seconds'
                    }
                    moment.momentSteps.push(actionObj)

                    if(!(i == (setCountArray[index]-1))) {  //omit the rest step if last action step
                      moment.momentSteps.push(restObj)
                    }
                  }


                  //give this routineMoment a unique id
                  var uuid = uuidv4()
                  moment.routineTemplateMomentId = uuid

                  //add actionAttributes to moment
                  moment.actionAttributes = this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction
                  routineMoments.push(moment)
                })
              }
            }

            itemsPerWeek = routineMoments.length


            //In support of daysOfWeek override feature
            for (let day in this.ANSWERS[this.currentFlowAnswers].daysOfWeek) {
              if(this.ANSWERS[this.currentFlowAnswers].daysOfWeek[day]) {
                recommendedDaysOfWeek.push(day)
              }
            }

          }else if (this.routineType == "everyday habit") {

            //figure out the routineMoments
            var routineMoment = {       //referred to as routineMoments
              'dayOfWeek': null,    //not needed for everyday habit routines
              'momentImage': null,
              'momentSteps': [{
                'stepAttributes': null,
                'stepDuration': 300,
                'stepId': null,
                'stepImage': null,
                'stepTitle': this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.agendaItemTitle,
                'stepType': 'action',
                'stepValue': null,
                'stepValueUom': null
              }],
              'momentTitle': this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.agendaItemTitle,
              'routineTemplateMomentId': null,
              'actionAttributes': this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction
            }


            //console.log("routineMoment", routineMoment)

            routineMoments.push(routineMoment)
            for (var i = 0; i < this.ANSWERS[this.currentFlowAnswers].timesPerDay; i++) {
              routineTemplateMomentIds.push(uuidv4())    //used for everyday habit routines - one id per number of instances of this moment
            }
            itemsPerWeek = this.ANSWERS[this.currentFlowAnswers].timesPerDay * 7

                      
          }else if (this.routineType == "work sessions") {

            for (let day in this.ANSWERS[this.currentFlowAnswers].daysOfWeek) {
              if(this.ANSWERS[this.currentFlowAnswers].daysOfWeek[day]) {
                var momentsArray2 = this.ANSWERS[this.currentFlowAnswers].routineMoments[day]
                var timeOfDayArray2 = this.ANSWERS[this.currentFlowAnswers].timeOfDay[day]
                //var setCountArray = this.ANSWERS[this.currentFlowAnswers].setCount[day]


                //Will need to expand this


                momentsArray2.forEach((moment,index) => {
                  moment.timeOfDay = timeOfDayArray2[index]


                  //give this routineMoment a unique id
                  var uuid = uuidv4()
                  moment.routineTemplateMomentId = uuid

                  //add actionAttributes to moment
                  moment.actionAttributes = this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction
                    
                  routineMoments.push(moment)
                })
              }
            }

          }else if (this.routineType == "event follow-up") {


            //figure out the routineMoments
            var routineMoment2 = {       //referred to as routineMoments
              'dayOfWeek': null,    //not needed for event-based routines
              'momentImage': null,
              'momentSteps': [{     //a placeholder momentStep - they get figured out at agendaReport creation time
                'stepAttributes': null,
                'stepDuration': 300,
                'stepId': null,
                'stepImage': null,
                'stepTitle': null,
                'stepType': null,
                'stepValue': null,
                'stepValueUom': null,
              }],
              'momentTitle': this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.agendaItemTitle,
              'routineTemplateMomentId': null,
              'actionAttributes': {
                'actionIconBlack': this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.actionIconBlack,
                'actionIconGrey': this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.actionIconGrey,
                'actionId': this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.actionId,
                'agendaItemTitle': this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.agendaItemTitle,
                // leaving out volumeType & intensityType since they do not apply for event follow-up
              },
            }
            
            routineQuestionGroups = this.ANSWERS[this.currentFlowAnswers].routineQuestionGroups

            routineMoments.push(routineMoment2)

          

            //create the eventAttributes object by going through any questions and extracting the answerVariableName properties
            
            if(this.ANSWERS[this.currentFlowAnswers].routineQuestionGroups) {
              Object.keys(this.ANSWERS[this.currentFlowAnswers].routineQuestionGroups).forEach((key) => {
                this.ANSWERS[this.currentFlowAnswers].routineQuestionGroups[key].eventAttributeQuestions.forEach(question => {

                  //if a question has a dependency
                  if(question.questionType === "eventAttributeQuestion") {
                    eventAttributes[question.eventVariableName] = {
                        'eventVariableName': question.eventVariableName,
                        'eventVariableValue': null,
                        'attributeDependencies': [],
                        
                      
                    }
                  }

                })
              })
            }


            // assign routineTemplateMomentIds
            // assign an id to each event and each task
            newEventsArray = []
            this.ANSWERS[this.currentFlowAnswers].eventsArray.forEach((event) => {
              // assign an id to each event
              event.eventTime = moment(event.eventDayStamp, "YYYY-MM-DD").valueOf()
              event.routineTemplateMomentEventId = uuidv4()
              event.eventAttributes = eventAttributes    // each event gets the same attributes object because each event has the same tasks with same questions
              newEventsArray.push(event)
            })

            newTasksArray = []
            this.ANSWERS[this.currentFlowAnswers].tasksArray.forEach((task) => {
              // assign an id to each task
              task.routineTemplateMomentTaskId = uuidv4()
              newTasksArray.push(task)
            })


            



          }else if (this.routineType == "cessation") {
            //figure out the routineMoments //similar to everyday habit
            //figure out the routineMoments
            var routineMoment3 = {       //referred to as routineMoments
              'dayOfWeek': null,    //not needed for everyday habit routines
              'momentImage': null,
              'momentSteps': [{
                'stepAttributes': null,
                'stepDuration': null,
                'stepId': null,
                'stepImage': null,
                'stepTitle': this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.agendaItemTitle,
                'stepType': 'action',
                'stepValue': null,
                'stepValueUom': null
              }],
              'momentTitle': this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction.agendaItemTitle,
              'routineTemplateMomentId': null,
              'actionAttributes': this.ANSWERS[this.currentFlowAnswers].whatToRoutineAction
            }
            
            routineMoments.push(routineMoment3)

            //Need to get upto 15 since 15 is the max moments allowed in a day for cessation
            for (var j = 0; j < 15; j++) {
              routineTemplateMomentIds.push(uuidv4())    //used for everyday habit & cessation routines 
            }

            
            theSchedule = []
            this.ANSWERS[this.currentFlowAnswers].cessationScheduleStartDay.forEach((day, index) => {
              theSchedule.push(day +`-`+ this.ANSWERS[this.currentFlowAnswers].cessationScheduleTimesPerDay[index])
            })
            //reverse the schedule so that the "day >= start day" logic works while determining number of times per day
            theSchedule.reverse()


            //The following is used within the RoutineSearch component, within result details.  Also used for the evolve summary pages
            var len = this.ANSWERS[this.currentFlowAnswers].cessationScheduleTimesPerDay.length
            var fromTimes = this.ANSWERS[this.currentFlowAnswers].cessationScheduleTimesPerDay[0]
            var toTimes = this.ANSWERS[this.currentFlowAnswers].cessationScheduleTimesPerDay[len - 1]
            var len2 = this.ANSWERS[this.currentFlowAnswers].cessationScheduleStartDay.length
            var numOfWeeks = this.ANSWERS[this.currentFlowAnswers].cessationScheduleStartDay[len2 - 1]
            var aAn = ((numOfWeeks == "8") || (numOfWeeks == "11")) ? "an" : "a"
            routineAgendaItemOccurranceScheduleText = `From ${fromTimes} times per day to ${toTimes} per day over ${aAn} ${numOfWeeks}-week period.`

            
            

          }else if (this.routineType == "generic") {

            ////generic is a program-class routine
            isProgram = true

            for(let period in this.ANSWERS[this.currentFlowAnswers].v2_periodMoments) {
              
              periodHumanActions[period] = []
        
              this.ANSWERS[this.currentFlowAnswers].v2_periodMoments[period].forEach((moment) => {
                moment.period = period
                periodHumanActions[period].push(moment.actionAttributes.agendaItemTitle)

                var routineTemplateMomentIds = {}
                moment.daysOfWeek.forEach((day) => {
                  routineTemplateMomentIds[day] = uuidv4()
                })
                moment.routineTemplatesMomentIds = routineTemplateMomentIds

                routineMoments.push(moment)

              })
            }
            //routinePeriods
            routinePeriods = this.ANSWERS[this.currentFlowAnswers].v2_routinePeriodsObject

          }else if (this.routineType == "cooking") {
            var thisRoutineTemplateMomentIds = {}
            for (let day in this.ANSWERS[this.currentFlowAnswers].cookingDays) {
              if(this.ANSWERS[this.currentFlowAnswers].cookingDays[day]) {
                routineCookingDays.push(day)
                //give this routineMoment a unique id
                
              }
              //Assigning the same routineTemplateMomentId to each day of week for this routine.
              thisRoutineTemplateMomentIds[day] = uuidv4()
            }
            routineTemplateMomentIds = thisRoutineTemplateMomentIds
            var momentsArray3 = this.ANSWERS[this.currentFlowAnswers].routineCookingMoments
                //var timeOfDayArray3 = this.ANSWERS[this.currentFlowAnswers].timeOfDay[day]
            momentsArray3.forEach((moment,index) => {
              moment.timeOfDay = "evening"

              

              //add actionAttributes to moment
              moment.menuId= this.ANSWERS[this.currentFlowAnswers].menuId
              routineMoments.push(moment)
              
            })
            routineMenuId = this.ANSWERS[this.currentFlowAnswers].menuId
            
          }else if (this.routineType == "list draw") {

            ////generic is a program-class routine

            /*
            isProgram = true

            for(let period in this.ANSWERS[this.currentFlowAnswers].v2_periodMoments) {
              
              periodHumanActions[period] = []
        
              this.ANSWERS[this.currentFlowAnswers].v2_periodMoments[period].forEach((moment) => {
                moment.period = period
                periodHumanActions[period].push(moment.actionAttributes.agendaItemTitle)

                var routineTemplateMomentIds = {}
                moment.daysOfWeek.forEach((day) => {
                  routineTemplateMomentIds[day] = uuidv4()
                })
                moment.routineTemplatesMomentIds = routineTemplateMomentIds

                routineMoments.push(moment)

              })
            }
            //routinePeriods
            routinePeriods = this.ANSWERS[this.currentFlowAnswers].v2_routinePeriodsObject

            */

          }else if (this.routineType == "event prep") {

            ////generic is a program-class routine
            /*
            isProgram = true

            for(let period in this.ANSWERS[this.currentFlowAnswers].v2_periodMoments) {
              
              periodHumanActions[period] = []
        
              this.ANSWERS[this.currentFlowAnswers].v2_periodMoments[period].forEach((moment) => {
                moment.period = period
                periodHumanActions[period].push(moment.actionAttributes.agendaItemTitle)

                var routineTemplateMomentIds = {}
                moment.daysOfWeek.forEach((day) => {
                  routineTemplateMomentIds[day] = uuidv4()
                })
                moment.routineTemplatesMomentIds = routineTemplateMomentIds

                routineMoments.push(moment)

              })
            }
            //routinePeriods
            routinePeriods = this.ANSWERS[this.currentFlowAnswers].v2_routinePeriodsObject

            */

          }


          var routineTemplateObj = {
            'dateCreated': new Date(),
            'groupMemberships': {},
            'iconImageGrey': routineImageGrey,  //exercise routine provides this now, other routines leave it null and images are added through admin
            'iconImageBlack': routineImageBlack,
            'intentMeanCategory': (intentMeanCategory) ? intentMeanCategory : null,
            'isProgram': (isProgram) ? true : false,
            'minderId': null,   //just a pass through variable; not an official property of a routine.  Routine objects are associated to intent mean categories, not to minders
            'nextStepList': (this.ANSWERS.ROUTINE_FLOW_ANSWERS.preDefinedNextSteps.length > 0) ? this.ANSWERS.ROUTINE_FLOW_ANSWERS.preDefinedNextSteps : null,
            'recommendedDaysOfWeek': (recommendedDaysOfWeek.length > 0) ? recommendedDaysOfWeek : null,
            'routineAgendaItemsPerWeek': (itemsPerWeek) ? itemsPerWeek : null,
            'routineAgendaItemOccurranceSchedule': (theSchedule) ? theSchedule : null,
            'routineAgendaItemOccurranceScheduleText': (routineAgendaItemOccurranceScheduleText) ? routineAgendaItemOccurranceScheduleText : null,
            'routineEvents': (newEventsArray) ? newEventsArray : null,
            'routineQuestions': (routineQuestions) ? routineQuestions : null,
            'routineQuestionGroups': (routineQuestionGroups) ? routineQuestionGroups : null,
            'routineNumberOfWeeks': 1,      //this might get removed as it does not do anything and is not consistent across routine types
            'routinePeriods': (routinePeriods) ? routinePeriods : null,                       //NEW
            'routinePeriodAttributes': (routinePeriodAttributes != {}) ? routinePeriodAttributes : null,           //NEW
            'routinePeriodHumanActions' : (periodHumanActions != {}) ? periodHumanActions : null,                    //NEW
            'routineStatus': 'active',
            'routineTasks': (newTasksArray) ? newTasksArray : null,
            'routineTemplateId': null,
            'routineImage': null,
            'routineCookingDays': (routineCookingDays.length > 0) ? routineCookingDays : null,   //Used in cooking routine
            'routineMenuId': routineMenuId,   //Used in cooking routine
            'routineMoments': routineMoments,     //All routines must have at least one moment object in here
            'routineTitle': (this.ANSWERS.ROUTINE_FLOW_ANSWERS.routineTitle) ? this.ANSWERS.ROUTINE_FLOW_ANSWERS.routineTitle : null,
            'routineType': this.routineType,
            'routineTimesPerDay': (this.ANSWERS.ROUTINE_FLOW_ANSWERS.timesPerDay) ? parseInt(this.ANSWERS.ROUTINE_FLOW_ANSWERS.timesPerDay) : null,
            'routineTemplateMomentIds': routineTemplateMomentIds,
            'routineUserDefinedAttributes': (this.ANSWERS.ROUTINE_FLOW_ANSWERS.routineUserDefinedAttributes != {}) ? this.ANSWERS.ROUTINE_FLOW_ANSWERS.routineUserDefinedAttributes : null,
            'tags': this.ANSWERS.ROUTINE_FLOW_ANSWERS.routineTags,
          }




          await this.createRoutineTemplate({'routineTemplateObj': routineTemplateObj})
          this.$router.push('/admin')



        }
      },


    
    },
    beforeCreate () {
      
      if(!this.$store.state.minder) this.$store.registerModule('minder', minderModule)
      if(!this.$store.state.experience) this.$store.registerModule('experience', experienceModule)
      if(!this.$store.state.image) this.$store.registerModule('image', imageModule)
      if(!this.$store.state.signin) this.$store.registerModule('signin', signinModule)
      if(!this.$store.state.settings) this.$store.registerModule('settings', settingsModule) 
      if(!this.$store.state.routine) this.$store.registerModule('routine', routineModule) 

    },
    created () {
      this.flowInitialized = false;
      if(this.initView) {
        this.currentView = this.initView
      }


    
    },
    mounted () {
    },
    updated () {
      this.setShowSearch(false)
    }
  }
  
</script>

<style scoped>
.wrapper {
  /*max-width: 1300px;*/
  margin: 0 auto;
  padding: 0;
}
a {
  color: #777;
}

.routineOverlayAdmin {
  position: absolute; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.97); /* Black background with opacity */
  z-index: 1100; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  vertical-align: top;
  font-size: 1.4em;
  text-align: left;
  overflow-y: hidden;
}
.overlayTitle {
  display: inline-block;
  color: white;
  font-weight: 300;
  font-size: .8em;
}
#logo {
  position: absolute;
  z-index: 1001;
  left: 8px;
  bottom: 28px;
}
#logo > img {
  height: 64px;
}
.category-img {
  display: none;
  background: rgba(34, 34, 34, 1);
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 30px left;
  object-position: 30px left;
  width: 100%;
  max-width: 450px;
  max-height: 250px;
  margin: 0 auto;
  
}

.stepHolder {
  font-size: .65em;
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 50px;
  width: 95%;
}

.titleHolder {
  position: relative;
  color: rgb(230, 230, 230);
  font-weight: 400;
  background-color: #222;
  text-align: center;
  overflow: hidden;
  height: 70px;
}
.titleHolder2 {
  width: 100%;
  position: absolute;
  font-size: .85em;
  top: 13px;
  left: 50%;
  transform: translateX(-50%);
}
.titleIcon {
  position: absolute;
  color: rgb(77, 77, 77);
  font-size: 1.4em;
  top: 7px;
  left: 15px;
}

/*Styles for Step Cards and elements*/
.stepCard {
  position: relative;
  background-color: white;
  padding: 10px;
  min-height: 200px;
  margin-top: 10px;
}
.intro {
  background-color: transparent;
  color: lightgray;
  font-size: 1.4em;
  padding-top: 5px;
}

.stimulusQuestion {
  font-size: .9em;
}
.column {
  vertical-align: top;
  display: inline-block;
  width: 100%;
}
.switchColumn {
  width: 15%;
  min-width: 40px;
  background-color: transparent;
  display:inline-block;
  text-align: right;
}
.switchLabel {
  width: 80%;
  background-color: transparent;
  display: inline-block;
  font-size: 1.3em;
  padding-left: 16px;
}
.stepButtonHolder {
  text-align: right;
  padding: 4px;
}
.stepTextField {
  margin: 0 auto;
  margin-top: 20px;
}
.lessMarginTop {
  margin-top: 20px;
}
.lessMinHeight {
  min-height: 20px;
}
.redFont {
  color: red;
}
.v-input--selection-controls {
  margin-top: 0px;
}
.notificationSwitchHolder {
  margin: 0 auto;
  width: 90%;
  background-color: transparent;
}
/* ADD_PERIODS */
.addPeriods_periodName {
  background-color: transparent;
  padding: 10px;
}
.addPeriods_periodDesc {
  background-color: transparent;
  padding: 10px;
}
.addPeriods_periodDayOfWeek {
  background-color: transparent;
  padding: 2px;
  display: inline-block;
}
.addPeriods_periodNumWeeks {
  background-color: transparent;
  padding: 10px;
}
.addPeriods_periodNumSets {
  background-color: transparent;
  padding: 10px;
}
.addPeriods_addPeriodIcon {
  font-size: 2em;
  color: rgb(129, 129, 129);
  background-color: transparent;
  display: inline-block;
  cursor: pointer;
  padding: 15px;
}




/* ADD_EVENTS */
.addEvents_PhaseTitle {
  width: 100%;
  background-color: transparent;
  color: rgb(156, 156, 156);
  font-size: 1.5em;
  padding-bottom: 2px;
  border-bottom: 1px solid gray;
}


.addEvents_detailsHolder {
  width: 100%;
  background-color: rgb(235, 235, 235);
  font-size: 1.4em;
  border: solid 1px lightgrey;
  border-radius: 6px;
  position: relative;
  margin-bottom: 30px;
}



.addEventQuestionIcon {
  position: absolute;
  bottom: 4px;
  right: 20px;
  height: 30px;
  width: 150px;
  background-color: transparent;
  text-align: center;
  padding-top: 5px;
  font-size: .7em;
}
.addTaskQuestionIcon {
  position: absolute;
  bottom: 4px;
  right: 190px;
  height: 30px;
  width: 190px;
  background-color: transparent;
  text-align: center;
  padding-top: 5px;
  font-size: .7em;
}
.addEvents_controlsHolder {
  width: 100%;
  background-color: transparent;
  margin: 0 auto;
  max-width: 400px;
}
.addEvents_inputEvent {
  background-color: transparent;
}
.addEvents_selectDate {
  background-color: transparent;
  padding: 10px;
}
.addEvents_addEventIcon {
  font-size: 2em;
  color: rgb(129, 129, 129);
  background-color: transparent;
  display: inline-block;
  cursor: pointer;
  padding: 15px;
}
.questionObjHolder {
  padding: 15px;
  margin-bottom: 30px;
  background-color: rgb(253, 249, 235);
  border-radius: 7px;
}


/* PLAN_ROUTINE */
.planRoutine_dayOfWeekHolder {
  width: 100%;
  background-color: transparent;
  margin-bottom: 85px;
  position: relative;
}
.reviewRoutine_dayOfWeekHolder {
  
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 45px;
}
.planRoutine_dayOfWeekTitle {
  width: 100%;
  background-color: transparent;
  color: rgb(156, 156, 156);
  font-size: 1.4em;
  padding-bottom: 2px;
  border-bottom: 1px solid gray;
}
.planRoutine_momentHolder {
  background-color: rgb(231, 231, 231);
  margin-top: 7px;
  padding: 30px;
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 7px;
}
.planRoutine_momentHolder2 {
  background-color: rgb(206, 206, 206);
  margin-top: 7px;
  padding: 30px;
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 7px;
}
.planRoutine_momentTitle {
  font-size: 1.6em;
  font-weight: 700;
}
.reviewRoutine_momentTitle {
  font-size: 1.6em;
  font-weight: 500;
}
.planRoutine_addMomentIcon {
  padding: 20px;
  font-size: 1.3em;
  color: rgb(175, 175, 175);
}
.planRoutine_detailsHolder {
  width: 100%;
  background-color: transparent;
  font-size: 1.5em;
  padding: 7px;
}
.planRoutine_controlsHolder {
  width: 100%;
  background-color: transparent;
  margin: 0 auto;
  padding: 3px;
  max-width: 400px;
}
.planRoutine_selectTimeOfDay {
  background-color: transparent;
  padding: 10px;
}
.planRoutine_selectSetCount {
  background-color: transparent;
  padding: 10px;
}
.planRoutine_intensityType {
  background-color: transparent;
  padding: 10px;
}
.planRoutine_volumeType {
  background-color: transparent;
  padding: 10px;
}

.contentContainer {
  position: relative;
  max-width: 900px;
  margin: 0 auto;
  min-height: 200px;
  padding-bottom: 100px;
}



/* PLAN_CESSATION_SCHEDULE*/
.planCessationRoutine_selectStartDay {
  background-color: transparent;
  padding: 10px;
}
.planCessationRoutine_selectTimesPerDay {
  background-color: transparent;
  padding: 10px;
}
.planCessationRoutine_addPhaseIcon {
  font-size: 2em;
  color: rgb(175, 175, 175);
}
.planCessationRoutine_PhaseTitle {
  width: 100%;
  background-color: transparent;
  color: rgb(156, 156, 156);
  font-size: 1.5em;
  padding-bottom: 2px;
  border-bottom: 1px solid gray;
}
.error {
  color: white;
  display: none;
}
.resetSchedule {
  position: absolute;
  bottom: 0;
  right: 0;
}



/*  Dot Holder   */
.dotsHolder {
  position: absolute;
  left: 50%;
  bottom: 3px;
  transform: translateX(-50%);
  display: block;
  width: 100%;
  background-color: transparent;
  text-align: center;
}
.dots {
  display: inline-block;
  color: lightgrey;
  padding: 9px;
  font-size: .5em;
  
  vertical-align: top;
}
.currentDot {
  color: rgb(63, 106, 170);
}
.greyOutroDot {
  color: lightgrey;
}
.greenOutroDot {
  color: green;
  font-size: 1.5em;
}
.greyArrow {
  color: lightgrey;
}

@media screen and (min-width: 360px) {
  .stepCard {
    padding: 18px;
    min-height: 210px;
  }
  .dotsHolder {
    bottom: -4px;
  }
  .titleHolder {
    font-size: .95em;
  }
  .titleIcon {
    font-size: 1.5em;
  }
  .stepTextField {
    
    margin-top: 20px;
  }
  .intro {
    font-size: 1.6em
  }
}

@media screen and (min-width: 400px) {
  .titleHolder {
    font-size: .95em;
    padding-right: 50px;
    padding-left: 50px;
  }
  .titleIcon {
    font-size: 1.7em;
  }
  .notificationSwitchHolder {
    width: 70%;
  }
  .intro {
    font-size: 1.75em
  }
  .stepCard {
    padding: 20px;
    min-height: 250px;
  }
  
}

@media screen and (min-width: 500px) {
  .titleHolder {
    font-size: 1em;
    padding-right: 50px;
    padding-left: 50px;
  }
  .titleIcon {
    font-size: 1.5em;
  }
  .notificationSwitchHolder {
    width: 60%;
  }
  
}

@media screen and (min-width: 750px) {
  .titleHolder {
    font-size: 1em;
  }
  .titleIcon {
    font-size: 1.5em;
  }
 .intro {
    font-size: 1.85em
  }
  .stepHolder {
    max-width: 800px;
  }
  .reviewRoutine_dayOfWeekHolder {
    max-width: 700px;
  }
  .addEvents_controlsHolder {
    max-width: 700px;
  }
  .column {
    width: 80%;
  }
}
</style>