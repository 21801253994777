import db from '@/db'
import firebase from '@/firebase'


  
  const state = {
    
    
  }
  
  const getters = {
    //userMoments: ({userMoments}) => userMoments,
    
  }
  
  const mutations = {
   
    
    
  }
  
  const actions = {

    async getHeroImage (_, payload) {
      //payload.category, payload.categorySlug
      return new Promise((resolve, reject) => {
        (async () => {
          var meanCatRef = await db.collection('taxonomies').doc('v1_2020_taxonomy').collection('intent_mean_parent_categories').doc(payload.intentMeanCategory);
          var imageSrc = null
          var imageLink = null
          var imageCredit = ''
          try {
              
              //get credit from database
              await meanCatRef.get().then(doc => {
                if(doc.exists) {
                  //console.log("intent mean cat exists, getting credit")
                  imageCredit = doc.data().content.imageCredit
                  imageLink = doc.data().content.imageLink
                  //could also grab an image src

                }else{
                  /* eslint-disable no-console */
                  console.log("No intent mean category document; image credit not found")
                }
              })
              
              var storageRef = firebase.storage().ref();
              var spaceRef = storageRef.child('images/parentCategoryHeroImages/HERO_1_'+payload.intentMeanCategorySlug+'.png')
              imageSrc = await spaceRef.getDownloadURL()
              
          } catch(e) {
            if(e.code == "storage/object-not-found") {
              var spaceRef2 = storageRef.child('images/categoryHeroImages/HERO_1_default.png')
              imageSrc = await spaceRef2.getDownloadURL()
              imageCredit = 'Photo by Mark Doda on Unsplash'
              imageLink = 'https://unsplash.com/photos/tS9hJOnmKK8'
            }else{
              reject()
            }
          }

          var heroObj = {
            src: imageSrc,
            credit: imageCredit,
            link: imageLink,
          }
          //console.log("heroObj: "+JSON.stringify(heroObj))
          resolve(heroObj)
        })()
      })
    },

    async getCategoryImage (_, payload) {
      return new Promise((resolve, reject) => {
        (async () => {
          var meanCatRef = await db.collection('taxonomies').doc('v1_2020_taxonomy').collection('intent_mean_parent_categories').doc(payload.parentCategory);
          var imageSrc = null
          var imageCredit = ''
          var imageLink = null
          try {
              
              //get credit from database
              await meanCatRef.get().then(doc => {
                if(doc.exists) {
                  //console.log("intent mean cat exists, getting credit")
                  imageCredit = doc.data().content.imageCredit
                  imageLink = doc.data().content.imageLink
                  //console.log("imageCredit from db: "+ imageCredit)

                }
              })
              
              var storageRef = firebase.storage().ref();
              var spaceRef = storageRef.child('images/categoryImages/CATEGORY_'+payload.parentCategorySlug+'.png')
              imageSrc = await spaceRef.getDownloadURL()
              
          } catch(e) {
            if(e.code == "storage/object-not-found") {
              var spaceRef2 = storageRef.child('images/categoryHeroImages/HERO_1_default.png')
              imageSrc = await spaceRef2.getDownloadURL()
              imageCredit = 'Photo by Mark Doda on Unsplash'
              imageLink = 'https://unsplash.com/photos/tS9hJOnmKK8'
            }else{
              reject()
            }
          }

          var categoryObj = {
            src: imageSrc,
            credit: imageCredit,
            link: imageLink,
          }
          //console.log("heroObj: "+JSON.stringify(heroObj))
          resolve(categoryObj)
        })()
      })
    },
    
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }